import { Alert, Row, Col } from "antd"
import { SiteAPI } from "../../../api"
import { UserContext } from "../../../api/user"
import { useEffect, useState, useContext} from "react"

/*
{
   "healthy" : true,
   "dts_online" : true,
   "current" : "Kirby A 5P",
   "diskspace" : 54293,
   "ref_temp" : 20.36,
   "focus" : {
      "measurement" : 0,
      "minutes" : 0,
      "mode" : "normal",
      "mode_num" : 0,
      "well" : ""
   },
   "uptime" : 17624167.09
   "enabled" : [
      "Kirby RE A 2P",
      "Kirby RE A 3P",
      "Kirby RE A 4P",
      "Kirby A 5P",
      "Kirby A 6P",
      "Kirby A 7P",
      "Kirby A 4PI",
      "Kirby A 5PI"
   ],
   "error" : "",
   "info" : {
      "build" : 22307,
      "channels" : 16,
      "dts_vers" : "4.7.24",
      "opts" : 0,
      "range" : 4000,
      "serial" : 47501169,
      "version" : 1003
   },
   "timestamp" : "2022-11-21T13:56:08.189159521-07:00",
}
*/

export const PdecStatus = ({state}) => {
    if (!state) return null

    let error;
    let level = state.healthy ? "success" : "error";

    if (!state.healthy && state.error == "") {
        error = "No update from controller"
    } 

    const lastupdate = new Date(state.timestamp);
    const updateminutes = Math.floor((new Date() - lastupdate)/1000/60);

    if (updateminutes > 3) {
        error = `No update in ${updateminutes} minutes`; 
        level = "warning";
    } 

    const description = state ? <Row>
       <Col lg={{span: 4}}>
        { state.dts_online ? "DTS Online" : "DTS Offline"}<br />
        { "Current Temp: " + state.ref_temp + "°C"}<br />
        { "Focus Mode  : " + state.focus.mode}<br />
        { state.focus.mode_num > 0 ? <>
        {"Focus Well  : " + state.focus.well}<br />
        {"Minutes remain: " + state.focus.minutes}<br />
        </>
        : null } 
        {"Free Space  : " + Math.round(state.diskspace/100)/10 + " GB"}<br />
        {"Current Uptime: " + Math.round(state.uptime/36/24)/100.0 + " days"}<br />
       </Col> 
       <Col lg={{span: 4}}>
        {"Serial: "   + state.info.serial}<br />
        {"Channels: " + state.info.channels}<br />
        {"Build: "    + state.info.build} <br />
        {"Version: "  + state.info.version}<br />
        {"Range: "    + state.info.range + "m"}<br />
        {"Firmware: " + state.info.dts_vers}<br />
       </Col>
       <Col lg={{span: 6}}>
        Measurements Enabled <br />
        { state.enabled?.map(w => <div key={w}><span key={w} style={{color: state.current == w ? "green" : "black"}}>{w}</span><br /></div>) }
       </Col>
    </Row> : null


    return <>
            <Alert 
                message={ error ? error : "PDEC is healthy"} 
                type={level} 
                description={description} 
                /> 
        </>
}

const WrappedPdecStatus = ({pad}) => {
   const { getSiteInfo } = SiteAPI(pad);

   const [state, setState] = useState(null);

   useEffect(() => {
      getSiteInfo.sendRequest();
      const interval = setInterval(() => {
         getSiteInfo.sendRequest();
      }, 60e3);
      return () => {
         clearInterval(interval);
         getSiteInfo.cancel();
      }
   }, []);


   useEffect(() => {
      if (getSiteInfo.data?.state) {
         setState(getSiteInfo.data.state);
      }
   }, [getSiteInfo.data]);

   return <div>
         <h1>{pad}</h1>
         <PdecStatus state={state} />
      </div>
}

export const AllPdecStatus = ({pads}) => {
   const user = useContext(UserContext);

   return user?.pads.map(p => <WrappedPdecStatus pad={p} key={p} />)
}
