import { hexToRGBA } from "../utils/hexToRGBA";


//the following functions are used to plot the schema sections on the x-axis by creating Xlines and adding them to the xLines array

export const plotSection = (sections, layerIdx, xLines, setXLines) => {
    // Dynamically generate xLines based on sections with sectionType as 'section'
    const generatedXLines = sections
        .filter(section => section.sectionType === 'section')
        .map(section => ({
            key: `${section.start}-${section.end}`, // Create a unique key based on start and end
            x: section.start, 
            xLabel: `${section.label} - ${section.start}-${section.end} mKB`, 
            colour: hexToRGBA(section.color, 0.2), // Use the section's color
            data: [{
                key: `${section.start}-${section.end}`, // Use the same key as xLines
                xVal: section.start,
                yVal: section.end
            }],
            pin: true,
            visible: true,
            width: section.end - section.start // Set width based on start and end difference
        }));

    // Check if the generated sections are already in xLines
    const isGeneratedXLinesPresent = xLines.some(line => generatedXLines.map(item => item.key).includes(line.key));

    if (isGeneratedXLinesPresent) {
        // Remove generated sections from xLines
        const filteredXLines = xLines.filter(line => !generatedXLines.map(item => item.key).includes(line.key));
        setXLines(filteredXLines);
    } else {
        // Add generated sections to xLines
        setXLines(prevXLines => [...prevXLines, ...generatedXLines].sort((a, b) => a.x - b.x));
    }
};

export const plotPOI = (sections, layerIdx, xLines, setXLines, setToggledLayers) => {
    // Dynamically generate xLines based on sections with sectionType as 'point'
    const generatedXLines = sections
        .filter(section => section.sectionType === 'point')
        .map(section => ({
            key: section.start, 
            x: section.start,     
            xLabel: `${section.label} - ${section.start} mKB`, 
            // colour: "#1f4529",
            colour: section.color,
            data: [{
                key: section.start, // Use the same key as xLines
                xVal: section.start,
                yVal: section.start
            }],
            pin: true,
            visible: true,
            width: 3
        }));

    // Check if the generated points are already in xLines
    const isGeneratedXLinesPresent = xLines.some(line => generatedXLines.map(item => item.key).includes(line.key));

    if (isGeneratedXLinesPresent) {
        // Remove generated points from xLines
        const filteredXLines = xLines.filter(line => !generatedXLines.map(item => item.key).includes(line.key));
        setXLines(filteredXLines);
    } else {
        // Add generated points to xLines
        setXLines(prevXLines => [...prevXLines, ...generatedXLines].sort((a, b) => a.x - b.x));
    }

    //set the toggledLayers state to change the color of the icon
    setToggledLayers(prev => ({
        ...prev,
        [layerIdx]: !prev[layerIdx]
    }));
};