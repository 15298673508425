import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { DatePicker, Space, Button, Tooltip, Spin } from "antd";

import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    LeftOutlined,
    RightOutlined,
    FastBackwardOutlined,
    FastForwardOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";

import { WellContext } from "../../../api/well";
import { dateFormat } from "../../../utils/date";

import "./selector.css";

const { RangePicker } = DatePicker;

const TraceSelector = ({
    traceSelected,
    range,
    setRange,
    selectedLineTrace,
    showDatePicker,
    activeTab,
    isLoading,
    setIsLoading,
    children
}) => {
    const wellAPI = useContext(WellContext);

    const { queryTimestamps, fetchTrace } = wellAPI;

    const [closestDataPoint, setclosestDataPoint] = useState(null);
    const [index, setIndex] = useState(0);
    const [shiftType, setshiftType] = useState();

    const rangeTime = (range) => {
        const hour = 3600000;
        const day = hour * 24;
        switch (range) {
            case "12mo":
                return day * 30 * 12;
                break;
            case "8mo":
                return day * 30 * 8;
                break;
            case "6mo":
                return day * 30 * 6;
                break;
            case "3mo":
                return day * 30 * 3;
                break;
            case "2mo":
                return day * 30 * 2;
                break;
            case "1mo":
                return day * 30;
                break;
            case "3we":
                return day * 21;
                break;
            case "2we":
                return day * 14;
                break;
            case "1we":
                return day * 7;
                break;
            case "5da":
                return day * 5;
                break;
            case "3da":
                return day * 3;
                break;
            case "2da":
                return day * 2;
                break;
            case "1da":
                return day;
                break;
            default:
                console.log(
                    "Invalid date range, date range requested: " + range
                );
                break;
        }
    };

    useEffect(() => {
        closestDataPoint &&
            traceSelected &&
            traceSelected(closestDataPoint.date);
    }, [closestDataPoint]);

    useEffect(() => {
        if (!selectedLineTrace) return;
        setclosestDataPoint(
            closestDate(selectedLineTrace, queryTimestamps.data)
        );

        // setDatePickerTrace(dayjs(selectedLineTrace.toISOString));
    }, [selectedLineTrace]);

    // Used to "play" through the traces 1 at a time every 1/2 second
    useEffect(() => {
        const tick = () => {
            // console.log(index, queryTimestamps.data.length)
            if (index <= queryTimestamps.data?.length) {
                setIndex((i) => i + 1);
            }
        };
        const id = setInterval(tick, 500);
        return () => clearInterval(id);
    }, [queryTimestamps.data]);

    // If the user has reached a limit of the traces array, stops the user from stepping past the points where data exists. Repeats every second.
    useEffect(() => {
        if (!queryTimestamps?.data) {
            return;
        }
        // if ( queryTimestamps?.data[0] == closestDataPoint?.date || queryTimestamps?.data?.slice(-1) == closestDataPoint?.date ) {
        //     console.log( queryTimestamps, closestDataPoint, "we've hit a timewall, call the TimeCops!" );
        // }
        if (
            shiftType === "playBackward" &&
            queryTimestamps?.data[0] === closestDataPoint?.date
        ) {
            console.log(
                "Already at earliest datetime for time range specified, extend range to view earlier traces"
            );
            setshiftType(null);
        } else if (
            shiftType === "playForward" &&
            queryTimestamps?.data?.slice(-1) === closestDataPoint?.date
        ) {
            console.log(
                "Already at latest datetime for time range specified, extend range to view later traces"
            );
            setshiftType(null);
        } else {
            currentTrace();
        }
    }, [index, queryTimestamps.data]);

    // Start at the most recent timestamp
    useEffect(() => {
        if (!queryTimestamps?.data || !closestDataPoint) {
            return;
        } else if (
            queryTimestamps?.data?.length &&
            Object.keys(closestDataPoint).length === 0
        )
            setclosestDataPoint({
                index: queryTimestamps.data.length - 1,
                date: queryTimestamps.data[queryTimestamps.data.length - 1],
                dateParsed: Date.parse(
                    queryTimestamps.data[queryTimestamps.data.length - 1]
                ),
            });
    }, [queryTimestamps.data, fetchTrace.data]);

    useEffect(() => {
        if (fetchTrace.data?.length) {
            if (
                Date.parse(fetchTrace.data[0].timestamp) !==
                closestDataPoint?.dateParsed
            ) {
                setclosestDataPoint(
                    closestDate(
                        fetchTrace.data[0].timestamp,
                        queryTimestamps.data
                    )
                );
            }
        }
    }, [fetchTrace.data]);

    // When new range is selected, set the current trace to the most recent to avoid index sync issues
    useEffect(() => {
        // console.log("RANGE CHANGED: "+range)
        const now = new Date();
        setclosestDataPoint(closestDate(now, queryTimestamps.data));
    }, [queryTimestamps.data?.includes(closestDataPoint?.date)]);

    // Accepts a date and an array of dates, returns the closest point in the array to that given date
    function closestDate(dateTimePicked, dateArray) {
        // console.log({dateTimePicked}, {dateArray})
        let selectedDate = Date.parse(dateTimePicked);
        let dateDifference_ms = 99999999999999;
        let nextClosestPoint = {};

        // setshiftType(null);

        if (!dateArray?.length) {
            nextClosestPoint = {
                index: 0,
                date: wellAPI.fetchLatest.data?.timestamp,
                dateParsed: Date.parse(wellAPI.fetchLatest.data?.timestamp),
            };
        }

        for (let i = 0; i < dateArray?.length; i++) {
            if (
                Math.abs(selectedDate - Date.parse(dateArray[i])) <=
                dateDifference_ms
            ) {
                nextClosestPoint = {
                    index: i,
                    date: dateArray[i],
                    dateParsed: Date.parse(dateArray[i]),
                };
                dateDifference_ms = Math.abs(
                    selectedDate - Date.parse(dateArray[i])
                );
            } else {
                break;
            }
        }

        return nextClosestPoint;
    }

    // used to set the current timestamp for the trace being viewed when "playing" backwards or forwards
    function currentTrace() {
        let prevClosestPoint = closestDataPoint;
        setIndex(0);
        switch (shiftType) {
            case "playBackward":
                // console.log("playBackward");
                if (prevClosestPoint.index - index < 0) {
                    setshiftType(null);
                    return;
                }
                setclosestDataPoint({
                    index: prevClosestPoint.index - index,
                    date: queryTimestamps.data[prevClosestPoint.index - index],
                    dateParsed: Date.parse(
                        queryTimestamps.data[prevClosestPoint.index - index]
                    ),
                });
                break;
            case "playForward":
                // console.log("playForward");
                if (
                    prevClosestPoint.index + index >
                    queryTimestamps.data.length
                ) {
                    setshiftType(null);
                    return;
                }
                setclosestDataPoint({
                    index: prevClosestPoint.index + index,
                    date: queryTimestamps.data[
                        prevClosestPoint.index + index - 1
                    ],
                    dateParsed: Date.parse(
                        queryTimestamps.data[prevClosestPoint.index + index - 1]
                    ),
                });
                break;
        }
    }

    // We could probably break out this whole datepicker section into its own component

    const [datePickerTrace, setDatePickerTrace] = useState(
        dayjs(closestDataPoint?.date)
    );

    useEffect(() => {
        setDatePickerTrace(dayjs(closestDataPoint?.date));
    }, [closestDataPoint]);

  const onOk = (c) => {
        // console.log("ok", c, range)

        if (c.isAfter(range[0]) && c.isBefore(range[1])) {
            const closest = closestDate(c.toISOString(), queryTimestamps.data);
            setDatePickerTrace(dayjs(closest?.date));
            setclosestDataPoint(closest);
        } else {
            setRange([c.startOf("day"), c.endOf("day")]);
            queryTimestamps
                .sendRequest({
                    start: c.startOf("day").toISOString(),
                    end: c.endOf("day").toISOString(),
                })
                .then((r) => {
                    console.log("async", queryTimestamps, c.startOf("day"));
                });
        }
  }

    // Creates the datePicker element to allow the user to select a date within the currently specified range
    // function datePicker() {
    const datePicker = useCallback(() => {
        const onChange = (date, dateString) => {
            console.log("new date selected: " + dateString);
            if (date == null) {
                return <p>No Date Selected</p>;
            } else {
                // setclosestDataPoint(closestDate(date.toISOString(), queryTimestamps.data));
                return (
                    <p>
                        {/* {date._d == null ? "" : closestDate(date._d, queryTimestamps.data)} */}
                        {closestDataPoint?.date}
                    </p>
                );
            }
        };
        // console.log({datePickerTrace})
        return (
            <DatePicker
                showTime
                onOk={onOk}
                value={datePickerTrace}
                format={"YYYY-MM-DDTHH:mm:ssZ"}
                allowClear={false}
            />
        );
    }, [queryTimestamps.data, datePickerTrace]);

    // Used to disable dates from being selected that are outside of the currently selected range
    // function disabledDate(current) {
    //     return (
    //         (current && current.valueOf() > Date.now()) ||
    //         (current &&
    //             current.valueOf() < Date.now() - rangeTime(range) - 1000000) // had a bug where the last first day of the range was sometimes not available, added an extra buffer (10000000), will fix later
    //     );
    // }

    // Used to do single trace shifts on user click
    function onClickShift(timeShift) {
        let prevClosestPoint = closestDataPoint;
        let shiftedDate = "";
        setshiftType(timeShift);
        switch (timeShift) {
            case "prevTrace":
                setshiftType(timeShift);
                shiftedDate = queryTimestamps.data[prevClosestPoint.index - 1];
                setclosestDataPoint({
                    index: prevClosestPoint.index - 1,
                    date: shiftedDate,
                    dateParsed: Date.parse(shiftedDate),
                });
                break;
            case "nextTrace":
                setshiftType(timeShift);
                shiftedDate = queryTimestamps.data[prevClosestPoint.index + 1];
                setclosestDataPoint({
                    index: prevClosestPoint.index + 1,
                    date: shiftedDate,
                    dateParsed: Date.parse(shiftedDate),
                });
                break;
        }
    }

    // play backwards and if clicked again, stop
    function playBackward() {
        if (shiftType === "playBackward") {
            setshiftType(null);
        } else {
            setshiftType("playBackward");
        }
    }

    // play forwards and if clicked again, stop
    function playForward() {
        if (shiftType === "playForward") {
            setshiftType(null);
        } else {
            setshiftType("playForward");
        }
    }

    //To stop the play forward, play backward to stop when the tab is changed. (activetab prop changed)
    //To address the other tabs flickering when the play forward was left on.
    useEffect(() => {
        // Function to stop the playing process
        const stopPlaying = () => {
            setshiftType(null); // This is based on your previous logic that setting shiftType to null stops the play
        };
      
        return () => {
            stopPlaying();
        };
      }, [activeTab]);

    const rangePresets = [
        {
            label: "Last 1 Day",
            value: [dayjs().add(-1, "d"), dayjs()],
        },
        {
            label: "Last 3 Days",
            value: [dayjs().add(-3, "d"), dayjs()],
        },
        {
            label: "Last 7 Days",
            value: [dayjs().add(-7, "d"), dayjs()],
        },
        {
            label: "Last 14 Days",
            value: [dayjs().add(-14, "d"), dayjs()],
        },
        {
            label: "Last 30 Days",
            value: [dayjs().add(-30, "d"), dayjs()],
        },
        {
            label: "Last 90 Days",
            value: [dayjs().add(-90, "d"), dayjs()],
        },
    ];

  const onRangeChange = (range) => {
    // console.log("range", range)
    setIsLoading(true);
    setRange(range)
  }

  // A debounce function to prevent the user from spam clicking the back and forward buttons (asynchronous loading causes glitchy behavior)
    function debounce(func, wait) {
        let timeout;
    
        return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
    
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        };
    }
    
    // Wrap your onClick handlers with the debounce function - 300ms is the wait time (not very noticeable to the user)
    const debouncedOnClickShiftPrev = useMemo(() => debounce(() => onClickShift("prevTrace"), 300), [onClickShift]);
    const debouncedOnClickShiftNext = useMemo(() => debounce(() => onClickShift("nextTrace"), 300), [onClickShift]);

    const backButtons = useMemo(
        () =>
            queryTimestamps.data?.length
                ? [
                      {
                          title: "Skip to Start of Range",
                          onClick: () => {
                              setclosestDataPoint(
                                  closestDate(
                                      queryTimestamps.data[0],
                                      queryTimestamps.data
                                  )
                              );
                              traceSelected(queryTimestamps.data[0]);
                          },
                          icon: <FastBackwardOutlined />,
                          disabled:
                              closestDataPoint && closestDataPoint.index === 0,
                      },
                      {
                          title: "Play backwards",
                          onClick: playBackward,
                          icon: <DoubleLeftOutlined />,
                          disabled:
                              closestDataPoint && closestDataPoint.index === 0,
                          className:
                              shiftType === "playBackward" ? "selected" : null,
                      },
                      {
                          title: "Step back one trace",
                        //   onClick: () => onClickShift("prevTrace"),
                          onClick: debouncedOnClickShiftPrev,
                          icon: <LeftOutlined />,
                          disabled:
                              closestDataPoint && closestDataPoint.index === 0,
                      },
                  ]
                : [],
        [closestDataPoint, queryTimestamps.data, shiftType]
    );

    const forwardButtons = useMemo(
        () =>
            queryTimestamps.data?.length
                ? [
                      {
                          title: "Step forward one trace",
                        //   onClick: () => onClickShift("nextTrace"),
                          onClick: debouncedOnClickShiftNext,
                          icon: <RightOutlined />,
                          disabled:
                              closestDataPoint &&
                              closestDataPoint.index ===
                                  queryTimestamps.data.length - 1,
                      },
                      {
                          title: "Play forwards",
                          onClick: playForward,
                          icon: <DoubleRightOutlined />,
                          disabled:
                              closestDataPoint &&
                              closestDataPoint.index ===
                                  queryTimestamps.data.length - 1,
                          className:
                              shiftType === "playForward" ? "selected" : null,
                      },
                      {
                          title: "Skip to End of Range",
                          onClick: () => {
                              setclosestDataPoint(
                                  closestDate(
                                      queryTimestamps.data[
                                          queryTimestamps.data.length - 1
                                      ],
                                      queryTimestamps.data
                                  )
                              );
                              traceSelected(
                                  queryTimestamps.data[
                                      queryTimestamps.data.length - 1
                                  ]
                              );
                          },
                          icon: <FastForwardOutlined />,
                          disabled:
                              closestDataPoint &&
                              closestDataPoint.index ===
                                  queryTimestamps.data.length - 1,
                      },
                  ]
                : [],
        [closestDataPoint, queryTimestamps.data, shiftType]
    );

    const genButtons = (b) => {
        return (
            <Tooltip title={b.title} key={b.title}>
                <Button
                    onClick={b.onClick}
                    disabled={b.disabled}
                    icon={b.icon}
                    className={b.className}
                />
            </Tooltip>
        );
    };
    // console.log({shiftType})
    return (
        <div className="Xcentered range-selector">
            {isLoading && <Spin />}
            <RangePicker
                showTime
                value={range}
                presets={rangePresets}
                format={"YYYY-MM-DDTHH:mm:ssZ"}
                onChange={onRangeChange}
                allowClear={false}
            />
            <>
                {showDatePicker === false ? 
                    null : 
                    <div>
                        {backButtons.map(genButtons)}
                        {datePicker(closestDataPoint?.date)}
                        {forwardButtons.map(genButtons)}
                    </div>
                }
            </>
            {children}
        </div>
    );

    // Admittedly a pretty gnarly looking return statement, will clean up later.
    return (
        <div className="Xcentered">
            {!queryTimestamps.data ? (
                <p>error: waiting for data to load...</p>
            ) : (
                <Space>
                    {datePicker()}
                    <p>
                        {dateFormat(
                            queryTimestamps.data?.length &&
                                queryTimestamps.data[0]
                        )}
                    </p>
                    <Tooltip title={"Play Backward"}>
                        <Button
                            onClick={() =>
                                onClickShift(
                                    shiftType == "playBackward"
                                        ? null
                                        : "playBackward"
                                )
                            }
                            disabled={
                                closestDataPoint == null ||
                                closestDataPoint.index == 0
                            }
                            className={
                                shiftType == "playBackward" ? "selected" : null
                            }
                        >
                            <DoubleLeftOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Step back one trace"}>
                        <Button
                            onClick={() => onClickShift("prevTrace")}
                            disabled={
                                closestDataPoint == null ||
                                closestDataPoint.index == 0
                            }
                        >
                            <LeftOutlined />
                        </Button>
                    </Tooltip>
                    <p>
                        {closestDataPoint == null
                            ? "No Date Selected"
                            : dateFormat(closestDataPoint.date)}
                    </p>
                    <Tooltip title={"Step forward one trace"}>
                        <Button
                            onClick={() => onClickShift("nextTrace")}
                            disabled={
                                closestDataPoint == null ||
                                closestDataPoint.index ==
                                    queryTimestamps.data.length - 1
                            }
                        >
                            <RightOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Play Forward"}>
                        <Button
                            onClick={() =>
                                onClickShift(
                                    shiftType == "playForward"
                                        ? null
                                        : "playForward"
                                )
                            }
                            disabled={
                                closestDataPoint == null ||
                                closestDataPoint.index ==
                                    queryTimestamps.data.length - 1
                            }
                            className={
                                shiftType == "playForward" ? "selected" : null
                            }
                        >
                            <DoubleRightOutlined />
                        </Button>
                    </Tooltip>
                    <p>
                        {queryTimestamps.data?.length &&
                            dateFormat(
                                queryTimestamps.data[
                                    queryTimestamps.data.length - 1
                                ]
                            )}
                    </p>
                </Space>
            )}
        </div>
    );
};

export { TraceSelector };
