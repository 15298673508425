import { useRef, useState, useLayoutEffect } from 'react';

export const useResizer = () => {
    const [ width, setWidth ] = useState(0);
    const [ height, setHeight ] = useState(0);

    const sizeRef = useRef();

    useLayoutEffect(() => {
        const element = sizeRef.current;

        const resizeObserver = new ResizeObserver((entries) => {
            if (!Array.isArray(entries) || !entries.length) {
              return;
            }

            const entry = entries[0];

            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
        });

        if ( element ) {
          resizeObserver.observe(element);
          return () => resizeObserver.unobserve(element);
        };
    }, []);

    return [ width, height, sizeRef ]
}