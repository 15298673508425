import React from "react";
import "./index.css";
import { Affix, Menu, Layout } from "antd";

import { Link } from 'react-router-dom';

const { SubMenu } = Menu;
const { Sider } = Layout;

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: localStorage.getItem("collapsedMenu")==="true",
      item:null
    };
  }
  
  /*
	componentWillUnmount(){
		this.unlisten();
	}

	componentDidMount() {
		const path = this.props.location.pathname;
		const item = path !== "/" ? path.substr(1) : this.props.menuItems[0].key || null;
		this.setState({item:item});

		this.unlisten = this.props.history.listen((location, action) => {
		const path = location.pathname;
		const item = path !== "/" ? path.substr(1) : this.props.menuItems[0].key || null;
		this.setState({item:item});
		});
	  }
    */

  onCollapse = collapsed => {
    localStorage.setItem("collapsedMenu", collapsed);
    
    this.setState({ collapsed });
  };

  renderMenuItem = item => {
    const { key, icon, value } = item;
    return (
      <Menu.Item key={key} className="custom-menu-item">
        <Link to={`/${key}`}>{icon} <span>{value}</span></Link>
      </Menu.Item>
    );
  };

  renderMenuItems = () => {
    const items = this.props.menuItems || [];
    return items.map(item => {
      if (!item.subItems || item.subItems.length === 0) {
        return this.renderMenuItem(item);
      } else {
        return (
          <SubMenu
            key={item.key}
            className={"custom-menu-submenu"}
            title={
              <span>
                {item.icon}
                <span className="custom-menu-submenu-title">{item.value}</span>
              </span>
            }
            popupClassName={this.state.collapsed ? 'collapsed-submenu' : ''}
          >
            <div className={
                    this.state.collapsed
                    ? "custom-collapsed-submenu-wrapper"
                    : ""
                  }
            >
              {item.subItems.map(subItem => this.renderMenuItem(subItem))}
            </div>
          </SubMenu>
        );
      }
    });
  };

  render() {
    const logo=this.props.logo||null;
    const expandedLogo=this.props.expandedLogo||null
		return (
			<Sider
			  collapsible
				collapsed={this.state.collapsed}
				onCollapse={this.onCollapse}
        className={`custom-sider ${this.state.collapsed ? "custom-sider-collapsed" : ""}`}
        // style={{
        //   overflow: "auto",
        //   height: "100vh",
        //   position: "fixed"
        // }}
			>
				{
					this.props.collapsed ?
						logo&&<img src={logo} alt="logo" className="side-menu-logo"/> :
						expandedLogo&&<img src={expandedLogo} alt="logo" className="side-menu-logo"/>
				}
        <Affix>  
          <Menu theme="dark" selectedKeys={[this.state.item]}  mode="inline" className="custom-menu-vertical custom-menu-inline">
            {this.renderMenuItems()}
          </Menu>
        </Affix>  
			</Sider>
		)
	}
}

export default SideMenu; 