import { useParams, Link } from "react-router-dom";
import { SiteAPI } from "../../../api"
import { useContext, useEffect, useState, useMemo } from "react";

import { Typography, Collapse, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons"

import WellApi, { WellContext } from "../../../api/well";

import { PdecStatus } from "../../../components/Status/PdecStatus";

import { DepthTempChart } from "../../../components/Charts/DepthTempChart";
import { DepthLossChart } from "../../../components/Charts/DepthLossChart";

import {useDocumentTitle} from "../../../CustomHooks/useDocumentTitle";

import { ZoneTempChart } from "../../../components/Charts/ZoneTempChart";

import convertLatest from "../../../components/Charts/convertLatest";

import { UserContext } from "../../../api/user";

import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";

import "./site.css";

const { Title }  = Typography;
const { Panel } = Collapse;
 
const margins = {
  left: 50,
  right: 60,
  top: 20,
  bottom: 30
}

const marginsLoss = {
  left: 55,
  right: 60,
  top: 20,
  bottom: 30
}

const WellSummary = ({ site, well, latest }) => {
  const wellApi = WellApi(site, well);

  const [depthTraces, setDepthTraces] = useState([]);
  const [zoneTraces, setZoneTraces] = useState([]);
  const [lossTraces, setLossTraces] = useState([]);

  const [xLim, setXLim] = useState();
  const [yLim, setYLim] = useState();
  const [dateLim, setDateLim] = useState();
  const [depthLim, setDepthLim] = useState();
  const [lossLim, setLossLim] = useState();

  const [tcs, setTcs] = useState();

  useEffect(() => {
    wellApi.fetchInfo.sendRequest()
    wellApi.fetchZone.sendRequest({range: "1we"})

    return () => {
      wellApi.fetchInfo.cancel()
      wellApi.fetchZone.cancel()
    }
  }, [])

  useEffect(() => {
    if (!xLim && latest) {
      setXLim([0, latest.Span + latest.Start])
    }

    if (!yLim && latest) {
      setYLim([0, 300])
    }
  }, [latest, xLim, yLim])

  useEffect(() => {
    /*
    Still using the DTSRecord from api, all other traces that are requested are of the simpler form
    based on dtype style, so try to convert to something that works 

    The dead give-away is the captitalized items in the json object
    */

    /*
    Populate the traces with appropriate data
    */

    setDepthTraces(existing => {
      if (!existing.map(e => e.timestamp).includes(latest.Timestamp)) {

        return [ convertLatest(latest)]
      }
    })
  }, [latest])

  useEffect(() => {
    /* 
    Populate TCs, stripping out unused ones
    */

    const tcMap = wellApi.fetchInfo.data?.tcs;

    if (tcMap && latest) {
      let arr = [];

      for (let i in tcMap) {
        const tc = tcMap[i];

        if (tc.displayed) {
          arr.push(latest.TC[i])
        }
      }

      setTcs(existing => {
        if (existing?.key !== latest.Timestamp) {
          return { 
            key: latest.Timestamp,
            data: arr
          }
        }

        return existing
      })

    } 

    if (latest) {
      //create the loss trace arrays here
      const { Loss, Start, Span, Timestamp } = latest;
      const xData = Array.from({ length: Loss.length }, (_, i) => Start + i);
      const yData = Loss;
      const trace = {
          key: Timestamp,
          colour: 'red',
          pin: false,
          visible: true,
          xData: xData,
          yData: yData,
      };

      setDepthLim([Math.min(...xData), Math.max(...xData)]);
      setLossLim([Math.min(...yData), Math.max(...yData)]);
      setLossTraces([trace]);
      
  }
  }, [ latest, wellApi.fetchInfo.data ])

  useEffect(() => {
    if (wellApi.fetchZone.data) {
      const data = wellApi.fetchZone.data;

      setZoneTraces(
        wellApi.fetchInfo.data?.zones.reduce((acc, zone, i) => {
          if (zone.displayed) {
            acc.push({
              key: zone.name,
              xData: data[0],
              yData: data[1][i].map(p => p.mx),
              colour: '#a87532',
            });
          }
          return acc;
        }, [])
      );

    }
  }, [wellApi.fetchZone.data, wellApi.fetchInfo.data]);


  const onPanelChange = (key) => {
    // You can use this function to trigger a resize or force update on the charts
    // console.log('Panel changed:', key);
  };

  return (
    <WellContext.Provider value={wellApi}>
      <Collapse defaultActiveKey="latest" onChange={onPanelChange}>
        <Panel header={`${well} Latest`} key="latest">
          <div className="latestChart" style={{ height: '400px', width: '100%' }}>
            <DepthTempChart
              margins={margins}
              traces={depthTraces}
              xLim={xLim}
              yLim={yLim}
              tcs={tcs}
              title={`${well} - ${adjustToBrowserTime(latest.Timestamp)}`}
            />
          </div>
        </Panel>
        <Panel header={`${well} Fiber Health`} key="zone">
          <div className="zoneChart" style={{ height: '400px', width: '100%', transform: 'translateX(-50px)' }}>
            <DepthLossChart
              margins={marginsLoss}
              traces={lossTraces}
              xLim={depthLim}
              yLim={lossLim}
              title={latest.Timestamp ? `${well} - ${adjustToBrowserTime(latest.Timestamp)}` : 'No Data'}
            />
          </div>
        </Panel>
      </Collapse>
    </WellContext.Provider>
  );
};


//new way to display wells, by having the 'inactive wells' at the bottom of the list

const SiteDetailed = (props) => {
  let params = useParams();

  const siteAPI = SiteAPI(params.sitename);
  const { getSiteInfo } = siteAPI;
  
  const user = useContext(UserContext);

  useEffect(() => {
    getSiteInfo.sendRequest();
    const i = setInterval(getSiteInfo.sendRequest, 60 * 1000);
    return () => {
      clearInterval(i);
      getSiteInfo.cancel();
    };
  }, [params.sitename]);

  // added a sorted wells to have the active wells at the top of the list, and the inactive wells at the bottom
  const now = useMemo(() => new Date(), []);
  const oneDayAgo = useMemo(() => new Date(now.getTime() - 24 * 60 * 60 * 1000), [now]);

  const sortedWells = useMemo(() => {
    if (!getSiteInfo.data?.wells) return [];
    
    const activeWells = [];
    const inactiveWells = [];

    getSiteInfo.data.wells.forEach(well => {
      const timestamp = new Date(well.latest.Timestamp);
      if (timestamp >= oneDayAgo) {
        activeWells.push(well);
      } else {
        inactiveWells.push(well);
      }
    });

    return [...activeWells, ...inactiveWells];
  }, [getSiteInfo.data, oneDayAgo]);

  return (
    <>
      <Title level={3}>
        {getSiteInfo.data?.name} {user.admin && <Link to="edit"><EditOutlined /></Link>}
      </Title>
      <PdecStatus state={getSiteInfo.data?.state} />
      <Divider />
      {sortedWells.map(w => {
        if (w.displayed) {
          return (
            <div key={w.name}>
              <Link to={`/site/${params.sitename}/${w.name}`}>{w.name}</Link>
              <WellSummary site={getSiteInfo.data?.name} well={w.name} latest={w?.latest} />
              <Divider />
            </div>
          );
        }
        return null;
      })}
    </>
  );
  /*
      <div key={w.name} className="zoneSiteCharts">
        <Zone site={params.sitename} well={w.name} />
      </div>
  useEffect(() => {
    if (!status) return;
    const zone_cols = [...Array(10).keys()].map(i => (
      {
        title: `Z${i + 1} Max`,
        dataIndex: 'Latest',
        render: z => z ? z.Zone[i].Max.toFixed(1) : 0,
      }
    ))
    const tc_cols = [...Array(10).keys()].map(i => (
      {
        title: `FO/TC${i + 1} diff`,
        dataIndex: 'Latest',
        render: z => z ? (z.TC[i].FOTemp - z.TC[i].TCTemp).toFixed(1) : 0
      }
    ))

    console.log(status)
    setColumns([
      {
        title: 'Name',
        dataIndex: 'Name',
        // render: linkRender 
        render: (key) => <Link to={site + "/well/" + key}>{key}</Link>
      },
      {
        title: 'Last',
        dataIndex: 'Latest',
        render: l => {
          return l.Timestamp 
        }
      },
      ...zone_cols,
      ...tc_cols,
      {
        title: 'Loss',
        dataIndex: 'Latest',
        render: l => l.Loss ? Math.min(...l.Loss.filter(l => l > -20)).toFixed(2) : 0 
  
      },
      {
        title: 'Max',
        dataIndex: 'Latest',
        render: l => l.Temp ? Math.max(...l.Temp).toFixed(2) : -273.15
      },
    ])
  }, [status])
  */
}

export default SiteDetailed;
