import React, { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { WellContext } from "../../../api/well";

import { SelectedList } from "../Selectors/SelectedList";
import { ChartSummary } from "../Tooltip/Summary";
import { DepthTempChart } from "../DepthTempChart";
import { DepthSelector } from "../Selectors";
import { useTempSelector, useCursorData } from "../Hooks";

import { Row, Col, Slider, InputNumber, Spin } from "antd";
import convertTrace from "../convert";
import { depthLimits } from "../../../utils/depth";
import { WellAPI } from "../../../api";
import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";

const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "300px 1fr 150px",
      gridTemplateRows: "50px 1fr 150px",
      height: "90vh",
    },
    leftColumn: {
      gridColumn: "1 / 2",
      gridRow: "1 / 4",
      backgroundColor: "#e6e6e6",
      textShadow: "0 0 0px rgba(255,255,255, 1)",
      fontWeight: "bold"
    },
    middleColumnTop: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
    },
    chart: {
      gridColumn: "2 / 3",
      gridRow: "2 / 3",
      backgroundColor: "grey",
    },
    depthTrack: {
      gridColumn: "2 / 3",
      gridRow: "3 / 4",
      backgroundColor: "lightgrey",
    },
    tempTrack: {
      gridColumn: "3 / 4",
      gridRow: "2 / 3",
      backgroundColor: "lightgrey",
    },
};
      
const margins = {
    top: 20,
    bottom: 30,
    left: 60,
    right: 60
}

const LiveView = () => {
    const { fetchTrace, queryTimestamps, fetchZone } = useContext(WellContext);
    const wellAPI = useContext(WellContext);

    const fetch = fetchTrace.copy();
    const query = queryTimestamps.copy();
    const zones = fetchZone.copy();

    const [ traces, setTraces ] = useState([]);

    const [ qty, setQty ] = useState(10);

    const [ displayQty, setDisplayQty ] = useState(10);

    const [ loading, setLoading ] = useState(false)

    const [ xLines, setXLines ] = useState([]);

    const [ tempLim, setTempLim, TempSelector ] = useTempSelector( traces, margins );

    const [ cursorData, setCursorData ] = useCursorData(traces);

    const [ depthLim, setDepthLim ] = useState();

    const [ depthSelectorTempLims, setdepthSelectorTempLims ] = useState([])

    const [ debounceTimeout, setDebounceTimeout ] = useState(null);
    
    useEffect(() => {
        if (query.data) {
            if(traces.length == 0) return;
            zones.sendRequest({start: traces[0].key, end: traces[traces.length-1].key})
            return () => {
                zones.cancel();
            }
        }
    }, [query.data])

    useEffect(() => {
        if(traces.length == 0) return;
        if (traces) {
            setLoading(true)
            query.sendRequest({start: traces[0].key, end: traces[traces.length-1].key})
            return () => {
                setLoading(false)
                query.cancel()
            }
        }
        setLoading(false)
    }, [ ])

    useEffect(() => {
        if (traces.length == 0) return;
        let latestTrace = traces[traces.length-1]
        if (!depthLim) {
            setDepthLim([latestTrace.xData[0], latestTrace.xData[latestTrace.xData.length-1]])
        } 
    },[traces.length])

    useEffect(() => {
      const f = () => {
        query.sendRequest({latest: qty})
      }
      const i = setInterval(f, 15*1000)
      f()

      return () => {
        clearInterval(i)
        query.cancel()
      }
    }, [qty])

    useEffect(() => {
        if (query.data) {
            setLoading(true)
            const times = traces.map(t => t.key)

            const newTraces = query.data
                .filter(t => !times.includes(t))

            if (newTraces.length || qty != traces.length) {
                fetch.sendRequest({dtype:"temp", timestamp: query.data})
            }
        }
        setLoading(false)
    }, [query.data, qty])

    // this effect is used to set the color of the last trace to a darker color in order to differentiate it from the other traces
    useEffect(() => {
        if (fetch.data) {
            setLoading(true)
            const traces = fetch.data.map(t => convertTrace(t))

            for (let i = 0; i < traces.length; i++) {
                const alpha = (i + 1) / traces.length * 0.8 + 0.2;
    
                // Check if the trace is the last one
                if (i === traces.length - 1) {
                    // Set a darker shade of red for the last trace
                    traces[i].colour = `rgba(142, 40, 19, ${alpha})`; // Dark red color
                    traces[i].width = 1.0;
                } else {
                    traces[i].colour = `rgba(254, ${200 - 200 * (i + 1) / traces.length}, 0, ${alpha}`;
                    traces[i].width = 1.5;
                }
    
                // traces[i].width = 1.0;
            }
    
            // traces[traces.length - 1].width = 0.7;
            setTraces(traces);
            setLoading(false);
        }
    }, [fetch.data])

    
    const onSliderChange = useCallback((newValue) => {
        setDisplayQty(newValue); // Update display quantity immediately
    
        // Clear the previous timeout, if any
        if (debounceTimeout) clearTimeout(debounceTimeout);
    
        // Set a new timeout for the actual operation (e.g., fetch call)
        const newTimeout = setTimeout(() => {
          setQty(newValue); // Update the actual quantity after the debounce period
        }, 400); // Adjust the delay as needed
    
        // Store the new timeout in state
        setDebounceTimeout(newTimeout);
      }, [debounceTimeout]);

    // Set the templim to always always have all traces fully in view within TempVDepth depth section
    useEffect(() => {
        if(!traces) return;
        if(traces.length==0) return;
        if (!depthLim) return;
        let yIndexArr = [];
        for (let i = 0; i < traces.length; i++) {
            let xDataArr = traces[i]["xData"];
            let yDataArr = traces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(depthLim[0]));
            let endIndex = (xDataArr.indexOf(Math.round(depthLim[1]))==-1) ? ((traces[0]?.xData?.length-1)) : (xDataArr.indexOf(Math.round(depthLim[1])));
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
    
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
        
        if (minValue < -20) minValue = -20;
        
        // console.log(`Minimum value: ${minValue}`);
        // console.log(`Maximum value: ${maxValue}`);
        
        const EPSILON = 0.5; // Adjust based on the acceptable level of precision

        const areNumbersCloseEnough = (a, b) => Math.abs(a - b) < EPSILON;

        const newTempLim = [minValue, maxValue]; 

        // console.log(`Current tempLim: ${tempLim}, New tempLim: ${newTempLim}`);
        // console.log(`Should update: `,
        //     !tempLim || 
        //     !areNumbersCloseEnough(tempLim[0], newTempLim[0]) || 
        //     !areNumbersCloseEnough(tempLim[1], newTempLim[1])
        // );

        //if the templims are within 0.5 of the new templims, don't update
        if (!tempLim || 
            !areNumbersCloseEnough(tempLim[0], newTempLim[0]) || 
            !areNumbersCloseEnough(tempLim[1], newTempLim[1])) {
            
            setTempLim(newTempLim);
        } 
        if (depthSelectorTempLims.length == 0){
            setdepthSelectorTempLims([minValue, maxValue])
        }

    }, [depthLim, traces, fetch.data]);  

    const selectedZone = useMemo(() => {
        if (!zones.data) return;
        let timestamps = zones.data[0]
        let zoneInfo = []

        for (let z = 0; z<=zones.data[1].length; z++) {
            if (!zones.data[1][z]) continue;
            zoneInfo.push(zones.data[1][z][zones.data[1][z].length-1])
        }
        
        let zonebarData = {
            time: timestamps[timestamps.length-1],
            zones: zoneInfo
        }
        return zonebarData
    }, [zones.data, traces]);

    const depthExtents = useMemo(()=>{
        const depthExtents = depthLimits(traces[traces.length-1]);
        return depthExtents
    },[traces])

    const onDepthBrush = useCallback((depthRange) => {
        // only update if different
        setDepthLim(existing => { 
            for (let i in existing) {
                if (existing[i] != depthRange[i]) {
                    return depthRange
                }
            }
            return existing
        })
    }, []);

    return <div style={styles.container}>
          <div style={styles.leftColumn}>
             <SelectedList 
                 selected={traces} 
                 updateTrace={a => setTraces([...a])}
             />
             <ChartSummary 
                data={cursorData}
                xData={xLines}
                updateXData={a => setXLines([...a])} 
              />
          </div>
          <div style={styles.middleColumnTop}>
            <Row>
                <Col span={2}>
                    Number of Traces: 
                </Col>
                <Col span={10}>
                    <Slider min={1} max={30} value={displayQty} onChange={onSliderChange} />
                </Col>
                <Col>
                  <InputNumber min={1} max={30} value={displayQty} onChange={onSliderChange} />
                  {loading && <Spin />}
                </Col>
            </Row>
          </div>
          <div style={styles.chart}>
            <DepthTempChart 
                margins={margins}
                title={traces?.length ? adjustToBrowserTime(traces[traces.length - 1].key) : "No Data"}
                traces={traces}
                xLim={depthLim}
                yLim={tempLim}
                setCursorData={setCursorData}
                xLines={xLines}
                setXLines={setXLines}
            />
          </div>
          <div style={styles.depthTrack}>
            <DepthSelector
                key={depthSelectorTempLims}
                margins={margins}
                current={traces}
                onBrush={onDepthBrush}
                depthExtents={depthExtents}
                xLim={depthLim}
                yLim={depthSelectorTempLims.length == 0 ? tempLim : depthSelectorTempLims}
                zoneData={selectedZone}
            />
          </div>
          <div style={styles.tempTrack}>
            { TempSelector }
          </div>
        </div>
}

export default LiveView;