import axios from "axios";
import React from "react";
import { useContext, useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { MailOutlined } from "@ant-design/icons";

import { UserContext } from "../../api/user";
import { useDocumentTitle } from "../../CustomHooks/useDocumentTitle";
import { Card } from "antd";

const { Meta } = Card;

const SupportPage = () => {
    useDocumentTitle("Support");
    const [isFormSubmissionSuccess, setisFormSubmissionSuccess] =
        useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const closeModal = () => {
        setIsModalVisible(false);
        if (isFormSubmissionSuccess) {
            form.resetFields(); // Reset form fields when modal is closed
        }
    };

    return (
        <Card>
            <Meta title="Bug Report Form" />
            {isModalVisible}{" "}
            {
                <>
                    {isFormSubmissionSuccess ? (
                        <Modal
                            title="Thank you for your submission!"
                            open={isModalVisible}
                            onOk={closeModal}
                            onCancel={closeModal}
                        >
                            <p>
                                Thank you for your bug report! Our support team
                                has received your submission. If you have any
                                other questions or concerns, please contact
                                support at{" "}
                                <a href="mailto:support@petrospec.com">
                                    support@petrospec.com
                                </a>
                                .
                            </p>
                        </Modal>
                    ) : (
                        <Modal
                            title="Submission Unsuccessful"
                            open={isModalVisible}
                            onOk={closeModal}
                            onCancel={closeModal}
                        >
                            <p>
                                Bug report submission was unsuccessful, please
                                try again. If the issue persists, please contact
                                support at{" "}
                                <a href="mailto:support@petrospec.com">
                                    support@petrospec.com
                                </a>
                                .
                            </p>
                        </Modal>
                    )}
                </>
            }{" "}
            {
                <BugReportForm
                    form={form}
                    setisFormSubmissionSuccess={setisFormSubmissionSuccess}
                    setIsModalVisible={setIsModalVisible}
                />
            }
            <>
                <p>
                    Not here to report a bug? All other PetroViz related
                    inquiries can be sent to our support team at{" "}
                    <a href="mailto:support@petrospec.com">
                        support@petrospec.com
                    </a>
                </p>
            </>
        </Card>
    );
};

const fieldLabels = {
    email: "Email",
    timestamp: "Timestamp",
    stepsToReproduce: "Steps to Reproduce",
    browserAndVersion: "Browser",
    operatingSystem: "Operating System",
    deviceAndResolution: "Device and Screen Resolution",
    urlOrPage: "URL or Page Description",
    expectedBehavior: "Expected Behavior",
    actualBehavior: "Actual Behavior",
    errorMessages: "Error Messages or Logs",
    additionalContext: "Additional Context",
};

const formatFormData = (formData) => {
    let formattedData = "<h1>PetroViz User Bug Report</h1>";

    Object.keys(formData).forEach((key) => {
        formattedData += `<b>${fieldLabels[key]}:</b><br/>${formData[key]}<br/><br/>`;
    });

    return formattedData;
};

const BugReportForm = ({
    form,
    setisFormSubmissionSuccess,
    setIsModalVisible,
}) => {
    const user = useContext(UserContext);

    let axios = require("axios");

    const onFinish = (values) => {
        const userTelemetry = {
            email: user.email,
            timestamp: new Date(),
        };

        const formData = { ...userTelemetry, ...values };

        let formattedData = formatFormData(formData);

        axios
            .post(
                "https://cnrlpdis.petrospec.ca/notify/send-email",
                formattedData,
                {
                    headers: {
                        "Content-Type": "text/html",
                    },
                }
            )
            .then((response) => {
                console.log("Success:", response.data);
                setIsModalVisible(true);
                setisFormSubmissionSuccess(true);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsModalVisible(true);
                setisFormSubmissionSuccess(false);
            });
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
                label="Steps to Reproduce"
                name="stepsToReproduce"
                rules={[
                    {
                        required: true,
                        message: "Please enter the steps to reproduce.",
                    },
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item
                label="Browser"
                name="browser"
                rules={[
                    {
                        required: true,
                        message: "Please enter the browser name.",
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Operating System"
                name="operatingSystem"
                rules={[
                    {
                        required: true,
                        message: "Please enter the operating system name.",
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Device and Screen Resolution"
                name="deviceAndResolution"
                rules={[
                    {
                        required: true,
                        message:
                            "Please enter the device and screen resolution.",
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="URL or Page Description"
                name="urlOrPage"
                rules={[
                    {
                        required: true,
                        message: "Please enter the URL or page description.",
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Expected Behavior"
                name="expectedBehavior"
                rules={[
                    {
                        required: true,
                        message: "Please enter the expected behavior.",
                    },
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item
                label="Actual Behavior"
                name="actualBehavior"
                rules={[
                    {
                        required: true,
                        message: "Please enter the actual behavior.",
                    },
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item label="Error Messages or Logs" name="errorMessages">
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item label="Additional Context" name="additionalContext">
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={<MailOutlined />}
                >
                    Submit Bug Report
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SupportPage;
