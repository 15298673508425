import React, { useState, useEffect, useContext, useMemo } from 'react';
import "./style.css";
import HSBar from "react-horizontal-stacked-bar-chart";
import convertTrace  from "../Charts/convert"
import { depthLimits } from "../../utils/depth";
import { Button, Input, Select, InputNumber, Modal, message } from 'antd';
import { HexColorPicker } from "react-colorful";
import { WellContext } from "../../api/well";
import { UserContext } from '../../api/user';
import { useDBWellApi } from "../../api/DBWellApi";

import { isValidInput } from '../../utils/isValidInput';


const { Option } = Select;

const styles = {
    labels: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'absolute', 
        top: 20, 
        left: 0, 
        right: 0, 
        bottom: 0
    },
    inputWidth: {
        width: '160px'
    },
    container: {
        width: '100%',
        marginTop: '20px',
        position: 'relative'
    },
    minMaxDepthLabels: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        position: 'relative',
        fontWeight: 'bold'
    },
    sectionLabels: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
        position: 'relative'
    },
    sectionEditorContainer: {
        marginTop: '30px'
    },
    individualSectionEditor: {
        marginBottom: '15px'
    },
    colorPickerContainer: {
        display: 'flex', 
        alignItems: 'start'
    },
    hexCodeDisplay: {
        marginLeft: '10px',
        fontWeight: 'bold',
        fontSize: '16px',
        alignSelf: 'flex-start' // Aligns the hex code to the top
    },
    optionStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px', // Example padding, adjust as needed
        borderRadius: '5px', // Example border radius
    },
    colorCircle: {
        width: '20px',
        height: '20px',
        marginRight: '10px',
        borderRadius: '50%'
    },
};

const presetColors = [
    { label: "Red", value: "#d93327" },
    { label: "Gre", value: "#60bf08" },
    { label: "Blu", value: "#0b78e6" },
    { label: "Yel", value: "#f5f120" },
    { label: "Ora", value: "#f5a320" },
    { label: "Pur", value: "#b359f0" },
    { label: "Cya", value: "#6bdbd9" },
    { label: "Ma",  value: "#ff4aff" },
    { label: "Gra", value: "#bdbdbd" }
];


// Function to find color label by hex value
const getColorLabelByHex = (hex) => {
    const colorOption = presetColors.find(c => c.value === hex);
    return colorOption ? colorOption.label : 'Choose Color'; // Or return hex for custom color
};

/* The section where user can select a section/point of interest to add to the well schema tab*/

const SectionInputForm = ({ onAddSection, minDepth, maxDepth, layerName, updateTabs }) => {

    // State variables to store input values
    const [ depth, setDepth ] = useState(""); // Used for Point of Interest
    const [ start, setStart ] = useState("");
    const [ end, setEnd ] = useState("");
    const [ label, setLabel ] = useState("");
    const [ color, setColor ] = useState("#FFFFFF");
    const [ hexInput, setHexInput ] = useState(color);
    const [ sectionType, setSectionType ] = useState("Section");
    const [ description, setDescription ] = useState("");
    const [ pickerVisible, setPickerVisible ] = useState(false);
    
    // Use wellAPI and user from the context, and custom hook
    const wellAPI = useContext(WellContext);
    const user = useContext(UserContext);
    const { addNewDOI } = useDBWellApi(); // Using custom hook here

    //Change the color of the section based on user choice
    useEffect(() => {
        if (sectionType === "Section") {
            setColor("#FFFFFF");
        } else if (sectionType === "Point of Interest") {
            setColor("#000000");
        }
    }, [sectionType]);

    useEffect(() => {
        setHexInput(color);
    }, [color]);

    const handleHexInputChange = (e) => {
        const newColor = e.target.value;
        setHexInput(newColor);
        if (/^#[0-9A-F]{6}$/i.test(newColor)) {
            setColor(newColor);
        }
    };

    const handleSectionTypeChange = (value) => {
        setSectionType(value);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (!isValidInput(layerName, sectionType, depth, label, minDepth, maxDepth, start, end)) return;

        const floatStart = sectionType === "Point of Interest" ? parseFloat(depth) : parseFloat(start);
        const floatEnd = sectionType === "Point of Interest" ? parseFloat(depth) : parseFloat(end);

        const newSection = {
            start: floatStart,
            end: floatEnd,
            label,
            color,
            minDepth,
            maxDepth,
            description,
            sectionType: sectionType === "Point of Interest" ? "point" : "section",
            layerName,
            storedInDB: false,
            doiVisible: true,
        };

        const doiID = await handleDBSubmit(newSection);
        if (doiID) {
            newSection.doiID = doiID;
            onAddSection(newSection);
        }
    
        // Reset the form fields
        setStart("");
        setEnd("");
        setLabel("");
        setDepth("");
        sectionType === "Section"? setColor("#FFFFFF") : setColor("#000000");
        setDescription("");
    };

    const handleDBSubmit = async (section) => {
        if (!section.storedInDB) {
            const doiDTO = {
                // ... constructing the data object to send to the backend
                WellID: wellAPI.fetchInfo.data.wellID,
                DoiType: section.sectionType,
                DoiStart: section.start,
                DoiEnd: section.sectionType === 'point' ? null : section.end,
                DoiColor: section.color,
                DoiTimestamp: section.creationDate,
                DoiDescription: section.description,
                DoiLabel: section.label,
                DoiVisible: true,
                DoiLayerLabel: section.layerName,
                Email: user.email,
            };
                
            try {
                // ... sending request to add section
                const response = await addNewDOI({
                    site: wellAPI.siteName,
                    well: wellAPI.wellName,
                    newDOIObject: doiDTO
                });
                message.success('Section added successfully');
                updateTabs(section.layerName);
                return response.data.doiID; // Return the doiID

            } catch (error) {
                console.log({error});
                message.error('Error adding Section');
                return null; // Return null if there's an error
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <span style={{ fontWeight: 'bold' }}>Add a new section/POI below:</span>
            <div className="form-row">
                <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    Type:
                    <Select value={sectionType} onChange={handleSectionTypeChange} style={{ marginLeft: '10px' }}>
                        <Option value="Section">Section</Option>
                        <Option value="Point of Interest">Point of Interest</Option>
                    </Select>
                </label>
    
                {sectionType === "Point of Interest" ? (
                    <>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            Depth:
                            <Input type="number" value={depth} onChange={e => setDepth(e.target.value)} style={{ marginLeft: '10px', ...styles.inputWidth }} />
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            Label:
                            <Input type="text" value={label} onChange={e => setLabel(e.target.value)} style={{ marginLeft: '10px', ...styles.inputWidth }} />
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            Description:
                            <Input type="text" value={description} onChange={e => setDescription(e.target.value)} style={{ marginLeft: '10px', width: '200px' }} />
                        </label>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            Color: 
                            <div className="outer-color-div" style={{ marginLeft: '10px' }}>
                                <Select
                                    value={getColorLabelByHex(color)}
                                    style={{ width: '120px', marginRight: '10px' }}
                                    dropdownMatchSelectWidth={false}
                                    onChange={(hex) => setColor(hex)}
                                    dropdownRender={menu => (<React.Fragment>{menu}</React.Fragment>)}
                                >
                                    {presetColors.map((colorOption) => (
                                        <Option key={colorOption.value} value={colorOption.value}>
                                            <div style={styles.optionStyle}>
                                                <div
                                                    style={{ 
                                                        ...styles.colorCircle,
                                                        backgroundColor: colorOption.value,
                                                    }}
                                                ></div>
                                                {colorOption.label}
                                            </div>
                                        </Option>
                                    ))}
                                </Select>
                                <div 
                                    style={{ background: color }} 
                                    className="color-preview"
                                    onClick={() => setPickerVisible(!pickerVisible)}
                                ></div>
                                {pickerVisible && (
                                    <div className="color-picker-container" style={styles.colorPickerContainer}>
                                        <div className="color-picker-modal">
                                            <HexColorPicker color={color} onChange={(c) => setColor(c)} style={{ width: '200px' }} />
                                        </div>
                                    </div>
                                )}
                                <Input
                                    type="text"
                                    value={hexInput}
                                    onChange={handleHexInputChange}
                                    style={{ width: '100px', marginLeft: '10px' }}
                                />
                            </div>
                        </label>
                    </>
                ) : (
                    <>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            Start depth:
                            <Input type="number" value={start} onChange={e => setStart(e.target.value)} style={{ marginLeft: '10px', ...styles.inputWidth }} />
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            End depth:
                            <Input type="number" value={end} onChange={e => setEnd(e.target.value)} style={{ marginLeft: '10px', ...styles.inputWidth }} />
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            Label:
                            <Input type="text" value={label} onChange={e => setLabel(e.target.value)} style={{ marginLeft: '10px', ...styles.inputWidth }} />
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            Description:
                            <Input type="text" value={description} onChange={e => setDescription(e.target.value)} style={{ marginLeft: '10px', ...styles.inputWidth }} />
                        </label>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            Color: 
                            <div className="outer-color-div" style={{ marginLeft: '10px' }}>
                                <Select
                                    value={getColorLabelByHex(color)}
                                    style={{ width: '120px', marginRight: '10px' }}
                                    dropdownMatchSelectWidth={false}
                                    onChange={(hex) => setColor(hex)}
                                    dropdownRender={menu => (<React.Fragment>{menu}</React.Fragment>)}
                                >
                                    {presetColors.map((colorOption) => (
                                        <Option key={colorOption.value} value={colorOption.value}>
                                            <div style={styles.optionStyle}>
                                                <div
                                                    style={{ 
                                                        ...styles.colorCircle,
                                                        backgroundColor: colorOption.value,
                                                    }}
                                                ></div>
                                                {colorOption.label}
                                            </div>
                                        </Option>
                                    ))}
                                </Select>
                                <div 
                                    style={{ background: color }} 
                                    className="color-preview"
                                    onClick={() => setPickerVisible(!pickerVisible)}
                                ></div>
                                {pickerVisible && (
                                    <div className="color-picker-container" style={styles.colorPickerContainer}>
                                        <div className="color-picker-modal">
                                            <HexColorPicker color={color} onChange={(c) => setColor(c)} style={{ width: '200px' }} />
                                        </div>
                                    </div>
                                )}
                                <Input
                                    type="text"
                                    value={hexInput}
                                    onChange={handleHexInputChange}
                                    style={{ width: '100px', marginLeft: '10px' }}
                                />
                            </div>
                        </label>
                    </>
                )}
                
                <Button className="add-button" type="primary" htmlType="submit">Add Section</Button>
            </div>
        </form>
    );
};


const SectionEditor = ({ section, onUpdate, onDelete, user }) => {

    //Initialize state variables with values from the 'section' prop
    const [start, setStart] = useState(section.start);
    const [end, setEnd] = useState(section.end);
    const [label, setLabel] = useState(section.label);
    const [color, setColor] = useState(section.color);
    const [hexInput, setHexInput] = useState(section.color);
    const [sectionType, setSectionType] = useState(section.sectionType);
    const [depth, setDepth] = useState(section.start);
    const [editedBy, setEditedBy] = useState(section.editedBy || "");
    const [editDate, setEditDate] = useState(section.editDate || "");
    const [description, setDescription] = useState(section.description || "");
    const [doiID, setDoiID] = useState(section.doiID || null);
    const [ pickerVisible, setPickerVisible ] = useState(false);
    const { editDOI, deleteDOI } = useDBWellApi(); // Using custom hook here
    const wellAPI = useContext(WellContext);

    // useEffect to update local state variables when the 'section' prop changes
    useEffect(() => {
        setStart(section.start);
        setEnd(section.end);
        setLabel(section.label);
        setColor(section.color);
        setHexInput(section.color);
        setSectionType(section.sectionType)
        setDepth(section.start);
        setDescription(section.description || "");
    }, [section]);

    useEffect(() => {
        setHexInput(color);
    }, [color]);

    const handleHexInputChange = (e) => {
        const newColor = e.target.value;
        setHexInput(newColor);
        if (/^#[0-9A-F]{6}$/i.test(newColor)) {
            setColor(newColor);
        }
    };

    //Function to update the particular section in the DB
    const handleDBSectionUpdate = async () => {
        const updatedDOIDTO = {
            WellID: wellAPI.fetchInfo.data.wellID,  
            DoiStart: sectionType === 'point' ? parseFloat(depth) : parseFloat(start), // Parse to float
            DoiEnd: sectionType === 'point' ? null : parseFloat(end), // Use local state variable
            DoiColor: color, // Use local state variable
            DoiDescription: description, // Use local state variable
            DoiLabel: label, // Use local state variable
            DoiLayerLabel: section.layerName
        };
    
        try {
            const response = await editDOI({
                site: wellAPI.siteName,
                well: wellAPI.wellName,
                doiID: doiID,
                updatedDOIObject: updatedDOIDTO
            });
            message.success('Section updated successfully');
            return true;
        } catch (error) {
            console.log({error});
            message.error('Error updating Section');
            return false;
        }
    };
    
    //Function to delete the particular section in the DB
    const handleDelete = async () => {

        try {
            const response = await deleteDOI({
                site: wellAPI.siteName,
                well: wellAPI.wellName,
                doiID: doiID
            });
            message.success('Section deleted successfully');
            return true;
        } catch (error) {
            console.log({ error });
            message.error('Error deleting Section');
            return false;
        }
    };

    // Handle form submission for updating a section locally and calls handleDBSectionUpdate to update the section in the DB
    const handleSubmit = async e => {
        e.preventDefault();
    
        const floatStart = sectionType === "point" ? parseFloat(depth) : parseFloat(start);
        const floatEnd = sectionType === "point" ? null : parseFloat(end);
    
        const newSectionDetails = {
            start: floatStart,
            end: floatEnd,
            label,
            color,
            description,
            sectionType
        };
        if (await handleDBSectionUpdate()) {

            // If successfully updated in the DB, then update in the component
            const updatedSection = { ...section, ...newSectionDetails, editedBy: user.email, editDate: new Date().toLocaleString() };
            onUpdate(updatedSection);
        }
    };

    //Modal for deleting a section
    const showDeleteConfirm = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this section?',
            content: '',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                const deletionSuccess = await handleDelete();  // Call handleDelete and store its result
                if (deletionSuccess) {  // Only call onDelete if handleDelete was successful
                    onDelete();
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    //render the form, points vs sections have different fields
    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <>
                <label style={{marginTop: '22px', paddingRight: '5px'}}>
                    {sectionType === "point" ? "Point of Interest:" : "Section:"}
                </label>
            </>
            {sectionType === "point" ? (
                <>
                    <label>
                        Depth:
                        <Input type="number" value={depth} onChange={e => setDepth(e.target.value)} />
                    </label>
                    <label>
                        Label:
                        <Input type="text" value={label} onChange={e => setLabel(e.target.value)} />
                    </label>
                    <label>
                        Description:
                        <Input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                    </label>
                    <label style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', marginTop: '20px'  }}>
                        Color: 
                        <div className="outer-color-div" style={{ marginLeft: '10px'}}>
                            <Select
                                value={getColorLabelByHex(color)}
                                style={{ width: '120px', marginRight: '10px' }}
                                dropdownMatchSelectWidth={false}
                                onChange={(hex) => setColor(hex)}
                                dropdownRender={menu => (<React.Fragment>{menu}</React.Fragment>)}
                            >
                                {presetColors.map((colorOption) => (
                                    <Option key={colorOption.value} value={colorOption.value}>
                                        <div style={styles.optionStyle}>
                                            <div
                                                style={{ 
                                                    ...styles.colorCircle,
                                                    backgroundColor: colorOption.value,
                                                }}
                                            ></div>
                                            {colorOption.label}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                            <div 
                                style={{ background: color }} 
                                className="color-preview"
                                onClick={() => setPickerVisible(!pickerVisible)}
                            ></div>
                            {pickerVisible && (
                                <div className="color-picker-container" style={styles.colorPickerContainer}>
                                    <div className="color-picker-modal">
                                        <HexColorPicker color={color} onChange={(c) => setColor(c)} style={{ width: '200px' }} />
                                    </div>
                                </div>
                            )}
                            <Input
                                type="text"
                                value={hexInput}
                                onChange={handleHexInputChange}
                                style={{ width: '100px', marginLeft: '10px' }}
                            />
                        </div>
                    </label>
                </>
            ) : (
                <>
                    <label>
                        Start depth:
                        <Input type="number" value={start} onChange={e => setStart(e.target.value)} />
                    </label>
                    <label>
                        End depth:
                        <Input type="number" value={end} onChange={e => setEnd(e.target.value)} />
                    </label>
                    <label>
                        Label:
                        <Input type="text" value={label} onChange={e => setLabel(e.target.value)} />
                    </label>
                    <label>
                        Description:
                        <Input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                    </label>
                    <label style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', marginTop: '20px'  }}>
                        Color: 
                        <div className="outer-color-div" style={{ marginLeft: '10px'}}>
                            <Select
                                value={getColorLabelByHex(color)}
                                style={{ width: '120px', marginRight: '10px' }}
                                dropdownMatchSelectWidth={false}
                                onChange={(hex) => setColor(hex)}
                                dropdownRender={menu => (<React.Fragment>{menu}</React.Fragment>)}
                            >
                                {presetColors.map((colorOption) => (
                                    <Option key={colorOption.value} value={colorOption.value}>
                                        <div style={styles.optionStyle}>
                                            <div
                                                style={{ 
                                                    ...styles.colorCircle,
                                                    backgroundColor: colorOption.value,
                                                }}
                                            ></div>
                                            {colorOption.label}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                            <div 
                                style={{ background: color }} 
                                className="color-preview"
                                onClick={() => setPickerVisible(!pickerVisible)}
                            ></div>
                            {pickerVisible && (
                                <div className="color-picker-container" style={styles.colorPickerContainer}>
                                    <div className="color-picker-modal">
                                        <HexColorPicker color={color} onChange={(c) => setColor(c)} style={{ width: '200px' }} />
                                    </div>
                                </div>
                            )}
                            <Input
                                type="text"
                                value={hexInput}
                                onChange={handleHexInputChange}
                                style={{ width: '100px', marginLeft: '10px' }}
                            />
                        </div>
                    </label>
                </>
            )}
            <Button className="update-button" type="primary" htmlType="submit" >Update Section</Button>
            <Button className="update-button" type="danger" onClick={showDeleteConfirm}>Delete Section</Button>
            <p style={{ marginLeft: '10px', fontSize: '0.8em' }}>
                {editedBy && editDate ? 
                    (`Edited by ${editedBy} on ${new Date(editDate).toLocaleString()}`)
                    : 
                    (`Created by ${section.createdBy} on ${new Date(section.creationDate).toLocaleString()}`)
                }
            </p>
        </form>
    );
};

// This is the main component which combines the above two components to render the well schematic in each tab
function WellSchematicLayout({ 
    initialSections = [], 
    layerName,
    onUpdate,
    updateTabs,
    onAddNewSection,
 }) {

    // Constants
    const DEFAULT_MIN_DEPTH = -200;
    const DEFAULT_MAX_DEPTH = 1300;
    const MIN_DEPTH_DIFFERENCE = 15;  // Minimum difference between labels. Adjust as needed.
    const MIN_SECTION_DIFFERENCE = 30;  // Minimum Section width for rendering end label. Adjust as needed.

    const wellAPI = useContext(WellContext);

    const [ currentTrace, setCurrentTrace ] = useState();
    const [ depthLim, setDepthLim ] = useState();

    const [ sections, setSections] = useState(initialSections);
    const [ minDepth, setMinDepth] = useState(DEFAULT_MIN_DEPTH);
    const [ maxDepth, setMaxDepth] = useState(DEFAULT_MAX_DEPTH);

    const user = useContext(UserContext);

    // update parent component with section
    useEffect(() => {
        onAddNewSection(layerName, sections);
    }, [sections]);
    
    //delete section with doiID from sections
    const handleDeleteSection = doiID => {
        setSections(prevSections => prevSections.filter(section => section.doiID !== doiID));
    };
    

    // Load the latest trace and update currentTrace to start, provide info about the well.
    useEffect(() => {
        wellAPI.fetchLatest.data && setCurrentTrace(convertTrace(wellAPI.fetchLatest.data));
    }, [wellAPI.fetchLatest.data])

    // Update depthLimits and depthExtents
    useEffect(() => {
        if (currentTrace) {
            let newDl = depthLimits(currentTrace)

            if (!depthLim) {
                setDepthLim(newDl)
                setMinDepth(newDl[0])
                setMaxDepth(newDl[1])
            } 
        }
    }, [currentTrace])

    //add section to sections if the api response is ok
    const handleAddSection = section => {
        section.createdBy = user.email; // Add the user's name
        section.creationDate = new Date(); // Add the current date and time
        section.layerName = layerName; // Add the tab's name
        section.storedInDB = false; // Add the storedInDB flag
        setSections(prevSections => [...prevSections, section].sort((a, b) => a.start - b.start));
    }

    // This function is used to determine if the color is dark or light to determine the text color
    function isColorDark(hexColor) {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance < 0.5;
    }

    // Render the section labels - updated to stagger the labels and prevent overlap, and to render the border for the point
    const renderSectionLabels = () => {
        return sections.map((section, index) => {
            const leftStartPos = ((section.start - minDepth) / (maxDepth - minDepth)) * 100;
            const sectionWidth = ((section.end - section.start) / (maxDepth - minDepth)) * 100;
            const textColor = isColorDark(section.color) ? 'white' : 'black';

            //calculate the depth differences between sections for label overlap
            const depthDifference = section.end - section.start;
    
            // Determine vertical alignment for labels
            const verticalAlignments = ['top', 'middle', 'bottom'];
            const verticalPos = verticalAlignments[index % verticalAlignments.length];
            let topPosition;
            let transform;
    
            switch (verticalPos) {
                case 'top':
                    topPosition = '0%';
                    transform = 'translateY(0%)';
                    break;
                case 'middle':
                    topPosition = '50%';
                    transform = 'translateY(-50%)';
                    break;
                case 'bottom':
                    topPosition = '95%';
                    transform = 'translateY(-100%)';
                    break;
                default:
                    topPosition = '50%';
                    transform = 'translateY(-50%)';
            }
    
            return (
                <React.Fragment key={index}>
                    {/* Render the border for the section */}
                    {section.sectionType === "section" && (
                        <div
                            style={{
                                position: 'absolute',
                                left: `${leftStartPos}%`,
                                width: `${sectionWidth}%`,
                                height: '100%',
                                borderLeft: '1px dashed black',
                                borderRight: '1px dashed black',
                            }}
                        ></div>
                    )}
            
                    {/* Render the border for the point */}
                    {section.sectionType === "point" && (
                        <div
                            style={{
                                position: 'absolute',
                                left: `${leftStartPos}%`,
                                height: '100%',
                                borderLeft: `2px solid ${section.color}`,
                            }}
                        ></div>
                    )}
                    
                    {/* Render the label only for the section */}
                    {section.sectionType === "section" && (
                        <span
                            style={{
                                position: 'absolute',
                                left: `${leftStartPos}%`,
                                width: `${sectionWidth}%`,
                                top: topPosition,
                                transform: transform,
                                textAlign: 'center',
                                color: textColor,
                            }}
                        >
                            {section.label}
                        </span>
                    )}
                </React.Fragment>
            );
            
            
        });
    };
    
    
    const handleUpdateSection = (doiID, updatedSection) => {
        setSections(prevSections => prevSections.map(section => {
            if (section.doiID === doiID) {
                return updatedSection;
            }
            return section;
        }));
    };
    
    //calculates the data for HSBar based on the sections
    const calculateData = () => {
        const depthArraySize = maxDepth - minDepth; // This assumes depth values are integers.
        const depthArray = new Array(depthArraySize).fill('grey');
    
        const normalizeDepth = (depth) => depth - minDepth; // Adjusted normalization since the depthArraySize is already dynamic.
    
        sections.forEach(section => {
            if (section.sectionType === "point") {
                depthArray[normalizeDepth(section.start)] = "POI";
            } else {
                for (let i = normalizeDepth(section.start); i < normalizeDepth(section.end); i++) {
                    depthArray[i] = section.color;
                }
            }
        });
    
        let currentColor = depthArray[0]; // Starting from the beginning of the depthArray.
        let currentStart = 0; // Adjusted starting point.
    
        const result = [];
    
        for (let i = 1; i <= depthArraySize; i++) {
            if (depthArray[i] !== currentColor || i === depthArraySize) {
                let isUserSection = sections.some(sec => sec.start === currentStart + minDepth); // Adjusted to get the actual depth
                let sectionLabel = '';
                if (isUserSection) {
                    sectionLabel = sections.find(sec => sec.start === currentStart + minDepth).label; // Adjusted to get the actual depth
                }
                result.push({
                    value: i - currentStart,
                    color: currentColor,
                    label: sectionLabel,
                    isUserSection
                });
                currentColor = depthArray[i];
                currentStart = i;
            }
        }
        return result;
    };

    //useMemo is used to prevent the data from being recalculated on every render unnecessarily
    const calculatedData = useMemo(() => calculateData(), [sections, minDepth, maxDepth]);
    
    // Calculate depth differences between sections, return absolute value in case of overlapping sections
    const depthDifferences = sections.map((section, index, array) => {
        if (index === 0) return null;
        // Ensure sections are sorted by start depth - return absolute value in case of overlapping sections
        const sortedSections = [...array].sort((a, b) => a.start - b.start);
        const difference = sortedSections[index].start - sortedSections[index - 1].end;
        return Math.abs(difference);
    });

    return (
            <div>
                <SectionInputForm 
                    onAddSection={handleAddSection} 
                    onUpdate={onUpdate} 
                    minDepth={minDepth} 
                    maxDepth={maxDepth} 
                    layerName={layerName}
                    updateTabs={updateTabs}
                 />
                <div style={styles.container}>
                    <div style={styles.minMaxDepthLabels}>
                        <span>{minDepth}m</span>
                        <span>{maxDepth}m</span>
                    </div>
                    <HSBar className="hsbar-bar"
                        data={calculatedData}
                        total={maxDepth}
                        height={100}
                        labelWidth={80}
                    />
                    <div style={styles.labels}>
                        {renderSectionLabels()}
                    </div>
                </div>
                <div style={styles.sectionLabels}>
                    {sections
                        .sort((a, b) => a.start - b.start) // Sort by start depth
                        .map((section, index) => {
                            const leftStartPos = `${((section.start - minDepth) / (maxDepth - minDepth)) * 100}%`;
                            const leftEndPos = `${((section.end - minDepth - 20) / (maxDepth - minDepth)) * 100}%`; //moved the label to the left by 20

                            // If the previous section's end depth is too close to the current section's start depth, skip the start label
                            const shouldSkipStartLabel = (section.start - minDepth < MIN_DEPTH_DIFFERENCE) || (depthDifferences[index] !== null && depthDifferences[index] < MIN_DEPTH_DIFFERENCE);
                            
                            // Calculate if the end label should be skipped
                            const shouldSkipEndLabel = (section.end - section.start < MIN_SECTION_DIFFERENCE)

                            return (
                                <React.Fragment key={index}>
                                    {!shouldSkipStartLabel && (
                                        <span style={{ position: 'absolute', left: leftStartPos }}>
                                            {section.start}m
                                        </span>
                                    )}
                                    {section.sectionType !== "point" && !shouldSkipEndLabel &&(
                                        <span style={{ position: 'absolute', left: leftEndPos }}>
                                            {section.end}m
                                        </span>
                                    )}
                                </React.Fragment>
                            )
                        })}
                </div>
                <div style={styles.sectionEditorContainer}>
                {sections.sort((a, b) => a.doiID - b.doiID).map((section, index) => (
                        <div key={index} style={styles.individualSectionEditor}>
                            <SectionEditor 
                                section={section} 
                                onUpdate={(updatedSection) => handleUpdateSection(section.doiID, updatedSection)} 
                                onDelete={() => handleDeleteSection(section.doiID)}
                                minDepth={minDepth} 
                                maxDepth={maxDepth}
                                user={user}
                            />
                        </div>
                    ))}
                </div>
            </div>
    )
}

export default WellSchematicLayout;