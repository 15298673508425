import { useContext, useEffect } from "react";
import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";

export const TCPoints = ({tcs}) => {
    const { canvas, renderFrame } = useContext(CanvasContext);
    const { margins, width, height, xScale, yScale, traces } = useContext(ChartContext);

    useEffect(() => {
        if (!canvas || !tcs || !yScale || !xScale || Object.keys(tcs).length === 0) return;
        const ctx = canvas.getContext("2d");

        ctx.save();

        // This is where we start drawing the TC x's
        ctx.strokeStyle = "black";
        ctx.lineWidth = 3;

        // Set up a clipping rectangle

        const l = margins.left; 
        const t = margins.top;
        const w = width - margins.left - margins.right; 
        const h = height - margins.bottom - margins.top;

        ctx.beginPath();
        ctx.rect(l, t, w, h);
        ctx.clip();

        for (let i=0; i<tcs?.data?.length; i++) {
            // Each TC's latest depth/temp.
            // Stripping out useless TCs
            if ((tcs?.data[i]?.Depth <= 0) || (tcs?.data[i]?.Depth > 4000)) {
                continue
            }
            const xVal = xScale(tcs?.data[i]?.Depth);
            const yVal = yScale(tcs?.data[i]?.TCTemp);

            // Has to be done this way, or the duplicate tcs issue pops up
            ctx.beginPath()
            ctx.moveTo(xVal - 6, yVal - 6)
            ctx.lineTo(xVal + 6, yVal + 6)
            ctx.moveTo(xVal + 6, yVal - 6)
            ctx.lineTo(xVal - 6, yVal + 6)
            ctx.stroke()
        }

        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;

        // Set up a clipping rectangle

        ctx.beginPath();
        ctx.rect(l, t, w, h);
        ctx.clip();

        for (let i=0; i<tcs?.data?.length; i++) {
            // Each TC's latest depth/temp.
            // Stripping out useless TCs
            if ((tcs?.data[i]?.Depth <= 0) || (tcs?.data[i]?.Depth > 4000)) {
                continue
            }
            const xVal = xScale(tcs?.data[i]?.Depth);
            const yVal = yScale(tcs?.data[i]?.TCTemp);

            // Has to be done this way, or the duplicate tcs issue pops up
            ctx.beginPath()
            ctx.moveTo(xVal - 5, yVal - 5)
            ctx.lineTo(xVal + 5, yVal + 5)
            ctx.moveTo(xVal + 5, yVal - 5)
            ctx.lineTo(xVal - 5, yVal + 5)
            ctx.stroke()
        }
        // This is where the TC x's drawing ends

        ctx.restore();
    }, [canvas, margins, width, height, xScale, yScale, tcs, traces, renderFrame]);
}