import { Button } from 'antd';

import "./selector.css";

const TimeRangeSelector = ({isLoading, range, setRange, recordLength=0}) => {
    const ranges = [
        "1we",
        "5da", "3da", "2da", 
        "1da"
    ]

    // const ranges = [
    //     "12mo", "8mo", "6mo",
    //     "3mo", "2mo", "1mo",
    //     "3we", "2we", "1we",
    //     "5da", "3da", "2da", 
    //     "1da"
    // ]

    // Color range picker buttons based on current range value
    function buttonStylerange(r) {
        if (isLoading) {
            switch(r === range) {
            case true:
                return 'button-range-active-loading'
            case false:
                return 'button-range-inactive-loading'
            }
        }
        else {
            switch(r === range) {
                case true:
                    return 'button-range-active-loaded'
                case false:
                    return 'button-range-inactive-loaded'
                }
        }
    }

    return <div className="Xcentered">
            {ranges.map(r => <Button key={r} className={buttonStylerange(r)} disabled={isLoading} onClick={() => setRange(r)}>{r}</Button>)}
        </div>
}

export default TimeRangeSelector