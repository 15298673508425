import { useState, useEffect } from 'react';

export const useCursorData = (traces) => {

    const [ cursorData, setCursorData ] = useState([]);

    useEffect(() => {
        if (traces.length) {
            const data = traces.map(t => {
                const x = t.x;
                const y = t.y;
                const key = t.key;
                const colour = t.colour;
            })
        }
    }, [traces])

    const cursorUpdate = data => {
        if (!data) return;

        // groupby x
        const reduced = data.reduce((acc, cur) => {
            (acc[cur.x] = acc[cur.x] || []).push(
                {
                    key: cur.key, 
                    y: cur.yVal,
                    yLabel: cur.y, 
                    colour: cur.colour, 
                    visible: cur.visible
                })
            return acc
        }, {})

        const arr = Object.keys(reduced).map(k => ({ 
            key: k,
            data: reduced[k]
        }))

        setCursorData(arr)
    }
    
    return [cursorData, cursorUpdate]
}