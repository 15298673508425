import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Form, Col, Row, Input } from "antd";
import { UserContext } from "../../api/user";
import { useEffect, useState, useContext } from "react";
import { EditOutlined, DoubleRightOutlined } from "@ant-design/icons";

// import "antd/dist/antd.css";
import { Select } from "antd";
import "./ModalForm.css";

const { Option } = Select;

function ModalForm({ users }) {
  const [visible, setvisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedUser, setselectedUser] = useState();
  const [selectedUserEmail, setselectedUserEmail] = useState();
  const [modalType, setmodalType] = useState("");
  const [admin, setadmin] = useState(null);

  const user = useContext(UserContext);

  // Get all available wells by getting admin pads (only admins can view this page)

  const handleCancel = () => {
    setvisible(false);
    setselectedUser(null);
    setselectedUserEmail(null);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    if (Object.keys(values).length === 0) {
      console.log("No request made, empty user data object submitted");
    }
    else {
      let PUTData = { data: values };
      let POSTData = { data: values };

      modalType === "edit" ? console.log({ PUTData }) : console.log({ POSTData });

      setvisible(false);
      setselectedUser(null);
      setselectedUserEmail(null);
      form.resetFields();
    }
  };

  const adminCheck = (adminBool) => {
    setadmin(adminBool.target.checked);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onPadSelectionChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const onUserChange = (value) => {
    setselectedUserEmail(value.value);
  };

  const onUserSearch = (value) => {
    console.log("search:", value);
  };

  function isAdmin() {
    if (admin == null) {
      return selectedUser?.data?.admin;
    } else {
      return admin;
    }
  }

  function showModal(modalType) {
    setmodalType(modalType);
    setvisible(true);
  }

  // Sets the state object to make user data accessable for the [selectedUserEmail]
  useEffect(() => {
    setselectedUser(null);
    setadmin(null);
    for (let userIdx = 0; userIdx <= users?.length; userIdx++) {
      if (users[userIdx]?.data.email === selectedUserEmail) {
        setselectedUser(users[userIdx]);
      }
    }
  }, [selectedUserEmail, users]);

  // Gives the current permissions for the selectedUser as a point of comparison when editing permissions
  function currentUserConfig() {
    return (
      <Row gutter={50}>
        <Col span={50}>
          <div className="flex-container" style={{ whiteSpace: "nowrap" }}>
            <div className="flex-child" style={{ border: "2px solid grey" }}>
              <h2>CURRENT CONFIGURATION</h2>
              <h3>Admin: {selectedUser?.data?.admin.toString()}</h3>
              <h3>Email: {selectedUser?.data?.email}</h3>
              <h3>Name: {selectedUser?.data?.name}</h3>
              <h3>Current Pad Access:</h3>
              <ul>
                {selectedUser?.data?.pads.map((p) => (
                  <li key={p}>{p}</li>
                ))}
              </ul>
            </div>
            <div className="flex-child-arrow">
              <DoubleRightOutlined style={{ fontsize: "96px" }} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  // Allows the admin to search/select a user from a dropdown, and pull up the relevant permissions for that user
  function UserSelect() {
    form.resetFields();
    form.setFieldsValue({ admin: isAdmin() });
    form.setFieldsValue({ email: selectedUser?.data?.email });
    form.setFieldsValue({ name: selectedUser?.data?.name });
    form.setFieldsValue(
      isAdmin()
        ? { pads: user?.pads }
        : { pads: selectedUser?.data?.pads }
    );
    return (
      <>
        <Select
          showSearch
          placeholder={
            selectedUser == null ? "Select a person" : selectedUserEmail
          }
          optionFilterProp="children"
          onChange={onUserChange}
          onSearch={onUserSearch}
          labelInValue
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {users?.map((u) => {
            return <Option value={u.data?.email}>{u.data?.email}</Option>;
          })}
        </Select>
      </>
    );
  }

  // Allows the admin to make changes to the selectedUser permissions. Currently, there isn't an API to change this on the backend, this is just a placeholder for now
  function userEdit() {
    form.resetFields();
    form.setFieldsValue({ admin: isAdmin() });
    form.setFieldsValue(
      isAdmin()
        ? { pads: user?.pads }
        : { pads: selectedUser?.data?.pads }
    );
    return (
      <div>
        <Form
          style={{ border: "2px solid #4dff4d" }}
          form={form}
          onFinish={handleSubmit}
          onCancel={handleCancel}
          onFinishFailed={onFinishFailed}
        >
          <h2>NEW CONFIGURATION</h2>
          <Form.Item
            label="Admin"
            name="admin"
            valuePropName="checked"
            style={{ marginBottom: "0px" }}
          >
            <Checkbox onChange={adminCheck}></Checkbox>
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid Email!",
              },
            ]}
            style={{ marginBottom: "0px" }}
          >
            <Input disabled={modalType === "edit"} size={"small"} style={{width: 250}}></Input>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, type: "string", message: "Please input a Name!" }]}
            style={{ marginBottom: "0px" }}
          >
            <Input disabled={modalType === "edit"} size={"small"}></Input>
          </Form.Item>
          <h3>New Pad Access:</h3>
          <Form.Item name="pads">
            <Checkbox.Group onChange={onPadSelectionChange}>
              {user?.pads.map((p) => {
                return (
                  <Row>
                    <Checkbox
                      value={p}
                      defaultChecked={
                        isAdmin() ? true : selectedUser?.data?.pads.includes(p)
                      }
                    >
                      {p}
                    </Checkbox>
                  </Row>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => showModal("add")}
        icon={<PlusOutlined />}
      >
        Add
      </Button>
      <Button
        type="primary"
        onClick={() => showModal("edit")}
        icon={<EditOutlined />}
      >
        Edit
      </Button>
      {modalType === "edit" ? (
        <Modal
          className="flex-container"
          visible={visible}
          onOk={form.submit}
          onCancel={handleCancel}
          destroyOnClose={true}
          width={900}
          closable={false}
          forceRender={true}
          okText={"Submit"}
        >
          <div>
            <UserSelect />
          </div>
          <div className="rowC">
            {selectedUser == null ? "" : currentUserConfig()}
            {selectedUser == null ? "" : userEdit()}
          </div>
        </Modal>
      ) : (
        <Modal
          className="flex-container"
          visible={visible}
          onOk={form.submit}
          onCancel={handleCancel}
          destroyOnClose={true}
          width={900}
          closable={false}
          forceRender={true}
          okText={"Submit"}
        >
          <div className="rowC">{userEdit()}</div>
        </Modal>
      )}
    </>
  );
}

export default ModalForm;
