import "./index.css";

import { useEffect, useState, useMemo, useContext } from "react";

import { Tabs, Spin, Menu, Dropdown, Tooltip } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useParams, Link } from "react-router-dom";
import {
    EditOutlined,
    ReloadOutlined,
    DownOutlined,
    ArrowLeftOutlined,
    UpOutlined,
} from "@ant-design/icons";

import { TCOverview } from "../../../components/DtsRecord";

import { WellAPI } from "../../../api";
import { SiteAPI } from "../../../api";

import { WellContext } from "../../../api/well";

import { zoomIdentity } from "d3";

import { useDocumentTitle } from "../../../CustomHooks/useDocumentTitle";

import { LossVDepthLayout } from "../../../components/Charts/Layouts/LossVDepth";

import { TempVDepthLayout } from "../../../components/Charts/Layouts";
import { ZoneVTimeLayout } from "../../../components/Charts/Layouts/ZoneVTime";
import LiveView from "../../../components/Charts/Layouts/LiveView";


import { UserContext } from "../../../api/user";
import TFOView from "../../../components/Charts/Layouts/TFOView";
import Notes from "../../../components/Notes/Notes";

import { Waterfall } from "../../../components/Waterfall/Waterfall2";
import WellSchema from "../../../components/WellSchematic/WellSchema";

const { TabPane } = Tabs;

const WellDetail = (props) => {
    let params = useParams();
    const getActiveTab = () => localStorage.getItem('activeTab') || 'liveview';

    const wellAPI = WellAPI(params.sitename, params.wellname);
    const { fetchLatest, fetchInfo, fetchZone, reloadData } = wellAPI;

    const [range, setRange] = useState("1we");

    const [timeExtents, setTimeExtents] = useState([
        new Date(Date.now() - 7 * 86400 * 1000),
        new Date(),
    ]);

    const [timeZoom, setTimeZoom] = useState(zoomIdentity);

    const [documentTitle, setDocumentTitle] = useDocumentTitle(
        params.sitename + "/" + params.wellname
    );

    const [selectedWell, setSelectedWell] = useState(params.wellname);

    const [activeTab, setActiveTab] = useState(getActiveTab());

    const [wellSchema, setWellSchema] = useState([]);

    const user = useContext(UserContext);

    // useEffect(() => {
    //     console.log("activeTab", activeTab);
    // }, [activeTab]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchInfo.sendRequest();
        return fetchInfo.cancel;
    }, []);

    useEffect(() => {
      // check SiteStatus for update interval
      fetchLatest.sendRequest()
      return fetchLatest.cancel
    }, [])

    // Getting stuff together for the well dropdown menu
    const siteAPI = SiteAPI(params.sitename);
    const { getSiteInfo } = siteAPI;

    useEffect(() => {
        getSiteInfo.sendRequest();
        return () => {
            getSiteInfo.cancel();
        };
    }, [params.sitename]);


    // callback function for updating the well schema from a child component
    const updateWellSchema = (newSchemaArray) => {
        setWellSchema(newSchemaArray);
    };


    const brushData = useMemo(() => {
        if (fetchZone.data === undefined || fetchInfo.data === undefined)
            return {};

        const [timestamps, zonedata] = fetchZone.data;

        setTimeExtents([timestamps[0], timestamps[timestamps.length - 1]]);

        return {
            x: timestamps.map((t) => new Date(t)),
            y: zonedata
                .reduce((prev, curr, i) => {
                    if (!prev.length) {
                        prev = curr.map((c) => c.mx);
                    } else if (fetchInfo.data.zones[i].displayed) {
                        curr.forEach((p, j) => {
                            if (p.mx > prev[j]) {
                                prev[j] = p.mx;
                            }
                        });
                    }
                    return prev;
                }, [])
                .filter((p) => p > 0),
        };
    }, [fetchZone.data, fetchInfo.data]);

    const refresh = () => reloadData.sendRequest({ range });

    //used for the 'Well Layers' edit button (bottom right)
    const switchToSchemaTab = () => {
        setActiveTab("schema");
    }
    
    const handleTabChange = (key) => {
        // Check if the new active tab is 'schema'
        if (key === 'schema') {
            // If 'schema' is selected, we save 'liveview' in localStorage instead
            localStorage.setItem('activeTab', 'liveview');
        } else {
            // For all other tabs, save the actual key in localStorage
            localStorage.setItem('activeTab', key);
        }
        // Set the active tab state to the new key regardless
        setActiveTab(key);
    };

    //effect to retrieve the active tab from local storage
    useEffect(() => {
        const storedTab = getActiveTab();
        if (activeTab !== storedTab) {
            setActiveTab(storedTab);
        }
    }, [params.wellname]);

    //effect to remove the active tab from local storage when the component unmounts - user goes back to home page
    useEffect(() => {
        return () => {
            localStorage.removeItem('activeTab');
        };
    }, []);
    
    const CustomBackButton = ({ onClick }) => (
        <Tooltip title={params.sitename}>
            <ArrowLeftOutlined onClick={onClick} />
        </Tooltip>
    );

    return (
        <>
            <WellContext.Provider value={wellAPI}>
                <PageHeader
                    onBack={() =>
                        (window.location = `/site/${params.sitename}`)
                    }
                    backIcon={
                        <CustomBackButton
                            onClick={() =>
                                (window.location = `/site/${props.params.sitename}`)
                            }
                        />
                    }
                    title={
                        <>
                            <div className="header-container">
                                <div id="petro-container">
                                    <div className="petro-dropdown">
                                        <WellDropdown
                                            params={params}
                                            getSiteInfo={getSiteInfo}
                                            defaultSelected={selectedWell}
                                        />
                                    </div>
                                </div>

                                { user.admin && <Tooltip title={"Edit " + params.wellname.toUpperCase()}>
                                    <Link to={`edit`}>
                                        <EditOutlined />
                                    </Link>
                                </Tooltip>
                                }
                                <Tooltip
                                    title={
                                        "Reload " +
                                        params.wellname.toUpperCase() +
                                        " Data Into Cache"
                                    }
                                >
                                    <ReloadOutlined
                                        onClick={refresh}
                                        style={{ paddingLeft: "5px" }}
                                    />
                                </Tooltip>
                            </div>
                        </>
                    }
                />
                <Tooltip title="Click the Reload button to see the number of cached records.">
                    Cached: {reloadData.data ? reloadData.data.length : "N/A"} records
                </Tooltip> <br />
                {reloadData.loading ? (
                    <>
                        Server processing in background....{" "}
                        <Spin indicator="server" />
                    </>
                ) : null}
            <div className="chart-container">
                <Tabs defaultActiveKey="liveview" activeKey={activeTab} onChange={handleTabChange}>
                    {/* <Tabs defaultActiveKey={"health"} destroyInactiveTabPane={true}> */}
                    <TabPane tab="Live View" key="liveview">
                        <LiveView />
                    </TabPane>
                    <TabPane tab="Trace View" key="traceview">
                        <TempVDepthLayout 
                            range={range} 
                            onEditClick={switchToSchemaTab} 
                            wellSchema={wellSchema}
                            activeTab={activeTab}
                            />
                    </TabPane>
                    <TabPane tab="Zone Overview" key="zone">
                        <ZoneVTimeLayout />
                    </TabPane>
                    <TabPane tab="TC Overview" key="tcview">
                        <TCOverview />
                    </TabPane>
                    <TabPane tab='TFO' key='tfo'>
                        <TFOView 
                            onEditClick={switchToSchemaTab} 
                            wellSchema={wellSchema}
                            activeTab={activeTab}
                        />
                    </TabPane>
                    {/* <TabPane tab='Waterfall' key='waterfall'>
                        <Waterfall timeRange={timeExtents} />
                    </TabPane> */}
                    <TabPane tab="Health Status" key="health">
                        <LossVDepthLayout 
                            range={range}
                            activeTab={activeTab} />
                    </TabPane>
                    <TabPane tab="Well Notes" key="notes">
                        <Notes/>
                    </TabPane>
                    <TabPane tab="Well Schema" key="schema">
                        <WellSchema onSchemaUpdate={updateWellSchema} />
                    </TabPane>
                </Tabs>
            
            </div>
            </WellContext.Provider>
        </>
    );
};

const WellDropdown = ({ params, getSiteInfo, defaultSelected }) => {
    const [selected, setSelected] = useState(defaultSelected);
    const [open, setOpen] = useState(false);
    const wells = getSiteInfo?.data?.wells;
    if (!getSiteInfo?.data?.wells) return null;
    const handleMenuClick = (e) => {
        const selectedWell = e.key;
        setSelected(selectedWell);
        setOpen(false);
        window.location.href = `/site/${params.sitename}/${selectedWell}`;
    };

    //added a filter to prevent dropdown from showing wells that are not 'real'- they get added when typed in the URL
    const menu = (
        <div className="custom-dropdown-menu">
            <Menu onClick={handleMenuClick} selectedKeys={[selected]}>
                {wells.filter(well => well.displayed).map((well) => (
                        <Menu.Item
                            style={{
                                backgroundColor:
                                    well.name === selected ? "#796a3e" : "",
                                    color: 'var(--sideMenuActiveText)'
                            }}
                            key={well.name}
                        >
                            {well.name}
                        </Menu.Item>
                ))}
            </Menu>
        </div>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            open={open}
            onOpenChange={(open) => setOpen(open)}
            placement="bottomLeft"
            // getPopupContainer={(trigger) => trigger.parentNode}
            getPopupContainer={() => document.body}
        >
            <a
                className="ant-dropdown-link"
                onClick={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                }}
            >
                {selected.toUpperCase()} {open ? <UpOutlined /> : <DownOutlined />}
            </a>
        </Dropdown>
    );
};

export default WellDetail;
