import { Button, Spin } from 'antd';
import { csvParseRows } from 'd3';
import { useContext, useEffect, useState } from 'react';
import { WellContext } from '../../api/well';
import { FileExcelOutlined } from '@ant-design/icons';
import './CSVDownload.css'

/*
Example data stucture of what CSVDownload is expecting:
blob = {
    "timestamp": "2022-11-24T16:13:26Z",
    "start": -282,
    "step": 1,
    "span": 1866,
    "data": [
        23.94,
        23.92,
        23.89,
        23.89,
        23.9,
        23.92,
        23.93,
        23.91,
        etc...
    ]
}
*/
export default function CSVDownload({
    type,
    timestamp,
    viewer, 
    blob,
    csvDataLoaded
}) {
    let wellAPI = useContext(WellContext);

    // This is used to make each entry in blob have the same number elements, appends "" to arrays shorter than max to maintain column structure
    const prepDataForCSVArray = (obj) => {
        let curMax = 0;
        let objCpy = obj
        for (const key in objCpy) {
            if (objCpy[key]?.length > curMax) {
                curMax = objCpy[key]?.length;
            }
        }
        for (const key in objCpy) {
            if (objCpy[key]?.length < curMax) {
                let arrayFill = Array(curMax-objCpy[key]?.length).fill("")
                objCpy[key]=objCpy[key].concat(arrayFill);
            }
        }
        return objCpy;
    }

    function formatDateToMT(utcDateString) {
        // Parse the UTC date string to a Date object
        const utcDate = new Date(utcDateString);
        
        // Determine if the date falls within DST for Mountain Time 
        // DaylightSaveTime starts on the second Sunday in March and ends on the first Sunday in November
        const dstStart = new Date(utcDate.getFullYear(), 2, 14 - (new Date(utcDate.getFullYear(), 2, 1).getDay() || 7)); // March
        const dstEnd = new Date(utcDate.getFullYear(), 10, 7 - (new Date(utcDate.getFullYear(), 10, 1).getDay() || 7)); // November
    
        // Adjust for DST if necessary
        let mtOffsetHours = utcDate >= dstStart && utcDate < dstEnd ? 6 : 7; // MT is UTC-6 during DST, else UTC-7
    
        // Convert to MT by subtracting the offset
        const mtDate = new Date(utcDate.getTime() - mtOffsetHours * 60 * 60 * 1000);
    
        // Format and return the date string with an indication of MT, considering DST
        const formattedDate = mtDate.toISOString().replace('Z', mtOffsetHours === 6 ? '_MT-DST' : '_MT');
        return formattedDate;
    }
    

    // Where the magic(?) happens. ultimately takes the prop blob, creates an array of arrays for each key/value pair in blob, creates column based data structure for CSV
        const getCSVArray = () => {
            if (type === "trace" || type === "loss"){
                // console.log("Got into getCSVArray()")
                if (!blob) {
                    console.log("no blob!")
                }
                else {
                    // console.log("yes there is a blob")
                    const tempHeaders = Object?.keys(blob);
                    // console.log("tempHeaders: ", tempHeaders);
                    let finalArray = [];
                    
                    // Regular expression to match UTC datetime format
                    const utcRegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)$/;

                    for (let row=0; row<tempHeaders.length; row++) {
                        let header = tempHeaders[row];
                        
                        // Check if header contains a UTC format timestamp
                        const match = header.match(utcRegex);
                        if (match) {
                            // Extract the timestamp part and convert it
                            const utcTimestamp = match[0];
                            const convertedTimestamp = formatDateToMT(utcTimestamp);
                            // Replace the original UTC timestamp in the header with the converted MT timestamp
                            header = header.replace(utcTimestamp, convertedTimestamp);
                        }
                        
                        let tempArray = [header];
                        if (Array.isArray(blob[tempHeaders[row]])) {
                            for (let dataIdx = 0; dataIdx < blob[tempHeaders[row]].length; dataIdx++) {
                                tempArray.push(blob[tempHeaders[row]][dataIdx]);
                            }
                            finalArray.push(tempArray);
                        }
                        else {
                            if (tempHeaders[row] === "colour"){
                                continue;
                            }
                            else {
                                tempArray.push(blob[tempHeaders[row]]);
                                finalArray.push(tempArray);
                            }
                        }
                    }
                    prepDataForCSVArray(finalArray);

                    // Need to transpose the data. without doing this, will have row organized data vs column organized.
                    function transpose(matrix) {
                        return matrix.reduce((prev, next) => next.map((item, i) =>
                          (prev[i] || []).concat(next[i])
                        ), []);
                      }
                    return transpose(finalArray);
                }
            } else if (type === "tfo") {
                if (!blob || blob.length === 0) {
                    console.log("no blob for tfo!");
                    return [];
                }
                // Prepare headers (timestamps)
                let headers = ["Depth", ...blob.map(trace => formatDateToMT(trace.key))];
                let finalArray = [headers];
            
                // Assuming all traces have the same xData length
                let depthValues = blob[0].xData;
                for (let i = 0; i < depthValues.length; i++) {
                    let row = [depthValues[i]];
                    for (let j = 0; j < blob.length; j++) {
                        let yDataValue = blob[j].yData[i] || "";  // Fallback for missing data
                        row.push(yDataValue);
                    }
                    finalArray.push(row);
                }
                return finalArray; // No need to transpose for tfo
            }
        }

        // Convert to local time from UTC, figured prod engineers would find this easier to read
        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
            }
  
        function formatDate(timestamp) {
            if (!timestamp) return;
            return (
                [
                timestamp.getFullYear(),
                padTo2Digits(timestamp.getMonth() + 1),
                padTo2Digits(timestamp.getDate()),
                ].join('-') +
                '_' +
                [
                padTo2Digits(timestamp.getHours()),
                padTo2Digits(timestamp.getMinutes()),
                padTo2Digits(timestamp.getSeconds()),
                ].join('-') +
                '_' +
                Intl.DateTimeFormat().resolvedOptions().timeZone.replace('/','-')
            );
        }

    // Creates the CSV file that is downloaded
    const CSVMaker = () => {        
        let data = getCSVArray();
        let csvContent = "data:text/csv;charset=utf-8," + data.join("\n");
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        switch(type) {
            case "trace":
                link.setAttribute("download", wellAPI?.wellName+"_"+type+"_"+viewer+"_"+formatDate(new Date(timestamp))+".csv");
                break
            case "loss":
                link.setAttribute("download", wellAPI?.wellName+"_"+type+"_"+viewer+"_"+formatDate(new Date(timestamp))+".csv");
                break
            case "tfo":
                link.setAttribute("download", wellAPI?.wellName+"_"+type+"_"+viewer+"_"+formatDate(new Date(timestamp))+".csv");
                break
            default:
                link.setAttribute("download", "download.csv")
                break
        }
        document.body.appendChild(link);
        link.click();
    }
    // return <Button className='DownloadButton' onClick={()=> {CSVMaker()}}><FileExcelOutlined style={{fontSize:"15px"}}/>CSV Download</Button>

    return (
        <Button 
          className='DownloadButton' 
          onClick={CSVMaker} 
          disabled={!csvDataLoaded}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {csvDataLoaded ? (
            <FileExcelOutlined style={{ fontSize: "15px", color: 'white' }} />
          ) : (
            <>
              <Spin size="small" style={{ marginRight: 8 }} />
              <FileExcelOutlined style={{ fontSize: "15px", color: 'red' }} />
            </>
          )}
          CSV Download
        </Button>
      )
}
    

