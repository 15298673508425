import { useContext, useEffect } from "react"
import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";
import { timeFormat } from "d3";

const drawXAxis = (
    context,
    scale,
    height,
    width,
    margins,
    tickFormat,
    tickSize = 6,
    fontSize = 12,
    ticks = 5,
    strokeStyle = "#2F2F2F",
    lineWidth = 1,
  ) => {
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;
  
    context.fillStyle = "#000000";
  
    context.textAlign = "center";
  
    context.beginPath();
  
    context.moveTo(margins.left, height - margins.bottom);
    context.lineTo(width - margins.right, height - margins.bottom);
  
    const fmt = tickFormat || scale.tickFormat();
  
    const xExtent = scale.domain();
    const isDate = xExtent[0] instanceof Date;
    const xTicks = [xExtent[0]].concat(scale.ticks(ticks), [xExtent[1]]);
    
    const dateFormatter = timeFormat("%Y/%m/%d %H:%M");

    const getAverageTickWidth = (ticks, fontSize, tickFormat, isDate) => {
        const tickTexts = ticks.map((tick) => (isDate ? dateFormatter(tick) : tickFormat(tick)));
        const tickWidths = tickTexts.map((text) => getTextWidth(text, fontSize));
        return tickWidths.reduce((a, b) => a + b, 0) / tickWidths.length;
      };

    const calculateThreshold = (ticks, fontSize, tickFormat, isDate, padding = 10) => {
        const avgTickWidth = getAverageTickWidth(ticks, fontSize, tickFormat, isDate);
        return avgTickWidth + padding;
    };

    const threshold = calculateThreshold(xTicks, fontSize, fmt, isDate);

    for (let tick of xTicks) {
        const tickPosition = scale(tick);
        const tickAsDate = isDate ? new Date(tick) : tick;
        const isExtentTick = tickAsDate.valueOf() === xExtent[0].valueOf() || tickAsDate.valueOf() === xExtent[1].valueOf();
        const isCloseToLeftExtent = Math.abs(tickPosition - scale(xExtent[0])) < threshold;
        const isCloseToRightExtent = Math.abs(tickPosition - scale(xExtent[1])) < threshold;
    
        const shouldDrawText = isExtentTick || (!isCloseToLeftExtent && !isCloseToRightExtent);    
  
        context.moveTo(tickPosition, height - margins.bottom + tickSize);
        context.lineTo(tickPosition, margins.top);
  
      if (shouldDrawText) {
        context.font = `${fontSize}px sans-serif`;
        if (isDate) {
          context.fillText(
            dateFormatter(tick),
            tickPosition,
            height - margins.bottom + tickSize + fontSize
          );
        } else {
          context.fillText(
            fmt(Math.round(tick)),
            tickPosition,
            height - margins.bottom + tickSize + fontSize
          );
        }
      }
    }
  
    context.stroke();
  };
  
function getTextWidth(text, fontSize, fontStyle = "sans-serif") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px ${fontStyle}`;
    const metrics = context.measureText(text);
    return metrics.width;
}

export const XAxis = () => { 
    const { xScale, height, width, margins, xFormat, title } = useContext(ChartContext);
    const { canvas, renderFrame } = useContext(CanvasContext);
    const drawBackgroundAndBorder = (
        context,
        width,
        height,
        outerBackgroundColor,
        innerBackgroundColor,
        borderColor
      ) => {
        // Draw the outer background
        context.fillStyle = outerBackgroundColor;
        context.fillRect(0, margins.top, width, height - margins.top);
      
        // Draw the inner background
        context.fillStyle = innerBackgroundColor;
        context.fillRect(
          margins.left,
          margins.top,
          width - margins.left - margins.right,
          height - margins.top - margins.bottom
        );
      
        // Draw the border
        context.strokeStyle = borderColor;
        context.strokeRect(margins.left, margins.top, width - margins.left - margins.right, height - margins.top - margins.bottom);
      };


    useEffect(() => {
    if (!xScale || !canvas) return;
    const ctx = canvas.getContext("2d");

    // Cleanup function to clear the canvas context before re-rendering
    const cleanup = () => {
        ctx.clearRect(0, margins.top, width, height-margins.top);
    };
    
    // Cleanup function to clear the x-axis area before re-rendering
    // const cleanup = () => {
    //     const xAxisAreaHeight = margins.bottom;
    //     const xAxisAreaWidth = width - margins.left - margins.right;
    //     ctx.clearRect(margins.left, height - margins.bottom, xAxisAreaWidth, xAxisAreaHeight);
    // };

    cleanup();
    
    drawBackgroundAndBorder(
        ctx,
        width,
        height,
        "#f2f2f2", // Outer background color
        "#ebebeb", // Inner background color
        "black"    // Border color
    );
    drawXAxis(ctx, xScale, height, width, margins, xFormat);

    }, [renderFrame]);

    return null 
}