// This function will adjust the hours that are displayed in titles to current browser time
// To be used with the a timestamp string in format "2023-07-27T20:12:00Z"
// Components on the "Zone Overview" tab will return z1, z2, etc instead of a date, the regex 
// will validate the date. 



const adjustToBrowserTime = (utcTimestamp) => {
    // console.log(utcTimestamp);
  
    // Regex to match the expected date/time format "YYYY-MM-DDTHH:MM:SSZ"
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    // Check if utcTimestamp matches the expected format
    if (!regex.test(utcTimestamp)) {
        // If utcTimestamp is not in the expected format, return it as is
        return utcTimestamp;
    }
  
    let utcDate = new Date(utcTimestamp);
    let offsetInHours = utcDate.getTimezoneOffset() / 60;
    utcDate.setHours(utcDate.getHours() - offsetInHours);
   

    let date = utcDate.toISOString();
    return date.substring(0, date.length - 5);
};

export { adjustToBrowserTime };

// const adjustToBrowserTime = (utcTimestamp) => {
//     // Regex to match the expected date/time format "YYYY-MM-DDTHH:MM:SSZ"
//     const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

//     // Check if utcTimestamp matches the expected format
//     if (!regex.test(utcTimestamp)) {
//         // If utcTimestamp is not in the expected format, return it as is
//         return utcTimestamp;
//     }
  
//     let utcDate = new Date(utcTimestamp);

//     // Adjust to local time without modifying the underlying date
//     let localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);

//     // Format the date to "YYYY-MM-DD HH:MM" (or whatever format you'd like)
//     let formattedDate = localDate.toISOString().slice(0, 16).replace('T', ' ');
  
//     return formattedDate;
// };

// export { adjustToBrowserTime };