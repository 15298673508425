import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Table, Switch } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SiteAPI } from '../../../api'
import "../../../components/Dashboard/DashboardTable.css";
import "../WellEdit/WellEdit.css"

const SiteEdit = () => {
    const [ wells, setWells ] = useState();

    const params = useParams();

    const siteApi = SiteAPI(params.sitename);
    const { getSiteInfo } = siteApi;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        siteApi.getSiteInfo.sendRequest()
        return siteApi.cancel
    }, []);

    useEffect(() => {
        if ( getSiteInfo.data === undefined ) return;
        console.log(getSiteInfo.data)
        setWells(getSiteInfo.data?.wells?.filter(w => w.latest?.Temp?.length).map(w => {
            console.log("WELL", w)

        return {
            key: w.name, 
            name: w.name,
            last: w.latest.Timestamp,
            maxTemp: Math.max(...w.latest.Temp),
            minTemp: Math.min(...w.latest.Temp).toFixed(2),
            minLoss: Math.min(...w.latest.Loss).toFixed(3),
            channel: w.latest.Chan,
            displayed: w.displayed
        }}))
    }, [getSiteInfo.data]);

    const columns = [
        {
            title: 'Displayed',
            render: ({displayed, name}) => 
                <Switch 
                    defaultChecked={displayed} 
                    onChange={(d) => siteApi.updateSite.sendRequest({
                    url: `/dts/site/${params.sitename}/${name}/info`,
                    data: {enabled: d},
                })}/>
        },
        {
            title: 'Wellname',
            dataIndex: 'name',
            render: name => <Link to={`/site/${params.sitename}/${name}/edit`}>{name}</Link>
        },
        {
            title: 'Last Record',
            dataIndex: 'last',
        },
        {
            title: 'Max Temp',
            dataIndex: 'maxTemp',
        },
        {
            title: 'Min Temp',
            dataIndex: 'minTemp',
        },
        {
            title: 'Min Loss',
            dataIndex: 'minLoss',
            render: (loss) => (
                <div style={{color: loss < -10 ? 'red' : 'black'}}>{loss}</div>
            )
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
        }
    ]

    return  (
            <>  
                <PageHeader
                    onBack={() =>  window.location = `/site/${params.sitename}`}
                    title={<>{params.sitename}</>} 
                />
                <div id="table-container">
                    <Table
                        header={<div>{params.sitename}</div>}
                        bordered
                        dataSource={wells}
                        columns={columns}
                        className="styled-table"
                    />
                </div>
            </>
    )
}

export default SiteEdit