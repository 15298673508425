import { useMemo, useCallback, useState, useEffect } from "react";
import { useResizer } from "./Hooks/Resizer";
import { SVGCanvasOverlay } from "./Canvas/SVGCanvasOverlay";
import { Spin } from 'antd';

import { depthGen } from "../../utils/depth";

import * as d3 from "d3";

import { Title } from "./Labels/Title";
import { ChartContext } from "./Canvas/ChartContext";
import { XAxis } from "./Axes/XAxis";
import { YAxis } from "./Axes/YAxis";
import { XLines } from "./Tooltip/XLines";
import { TCPoints } from "./Trace/TC";
import { Line } from "./Trace/Line";
import { Cursor } from "./Tooltip/Cursor";
import { CrossHairs } from "./Tooltip/Crosshairs";

import { shortDate } from "../../utils/date";

const defaultMargins = {
    top: 20,
    bottom: 30,
    left: 50,
    right: 50 
}

const defaultMargins2 = {
    top: 20,
    bottom: 30,
    left: 50,
    right: 50 
}

export const Chart = ({
    title,
    margins=defaultMargins,
    xScaleType="linear",
    xLim,
    yLim,
    traces,
    xLines,
    tcs,
    yUnit,
    xUnit,
    xLabel,
    view,
    setCursorData,
    setClickData,
    pinnedTraces,
    children
}) => {
    const [ width, height, ref ] = useResizer();
    const [ cursor, setCursor ] = useState();

    // Setup x Scale and x display format
    // const xScale = useCallback(() => {

    const xScale = useMemo(() => {
        var scale;

        switch (xScaleType) {
            case "time":
                scale = d3.scaleTime();
                break;
            default:
                scale = d3.scaleLinear();
                break;
        }

        if (!xLim) return scale;

        const s = scale
            .range([margins.left, width - margins.right])
            .domain(xLim)

        return s
    }, [width, margins, xLim]);

    // xScale.tickFormat() returns original tick formatter.... so here's my kludge

    const xFormat = useCallback(d => {
        if (xScaleType == "time") return shortDate(d);
        return `${Math.round(d)} ${xUnit}`;
    }, [xUnit, xScale]);

    // Setup y Scale and y display format

    const yScale = useCallback(yLim && d3.scaleLinear()
            .range([height - margins.bottom,  margins.top])
            .domain(yLim)
    , [height, margins, yLim] );

    const yFormat = useCallback(t => `${t} ${yUnit}`, [yUnit]);

    const onCursorMove = useCallback((e) => {
        if (!xScale ||  ! yScale) return;
        setCursor(e)

        // I had to stick this in there to get the data back outside the context provider
        if (traces?.length && e) {
            const depths = depthGen(traces[0]);
            const i = d3.bisect(depths, e.x);

            setCursorData && setCursorData({
                x: depths[i],
                data: traces.map(t => (
                    {
                        ts: new Date(t.timestamp), 
                        y: t.data[i]
                    }
                ))
            })
        } else {
            setCursorData && setCursorData(null)
        }
    }, [xScale, yScale, traces])


    // When pinnedTraces updates, create vertical lines based on trace.key and trace.colour
    const pinnedLines = useMemo(() => {
        if (!pinnedTraces || !xScale) return [];

        return pinnedTraces
            .filter((trace) => trace.pin) // Only consider traces that are pinned
            .map((trace) => {
                const xPos = xScale(new Date(trace.key)); // Convert trace.key (date) to x-position
                return { xPos, colour: trace.colour };
            });
    }, [pinnedTraces, xScale]);

    const onChartClick = useCallback(
        (e) => {
            if (!xScale) return;

            // Get the x position of the click relative to the chart
            const [mouseX] = d3.pointer(e);

            // Use xScale.invert to convert pixel position to date
            const dateAtClick = xScale.invert(mouseX);

            // Set both the pixel x-position and the date at click into click data, except for Zone view tab timeselector
            if (xScaleType === "time" && view !== "Zone") {
                setClickData && setClickData({ date: dateAtClick, xPos: mouseX });
            }
        },
        [xScale, setClickData]
    );

    return  <div 
                ref={ref} 
                className="chart"
                onClick={onChartClick}
                >
                <ChartContext.Provider value={{
                    height,
                    width,
                    margins,
                    title,
                    xScale,
                    yScale,
                    xFormat,
                    yFormat,
                    traces,
                    cursor,
                    onCursorMove,
                    xLines
                }}>
                    <SVGCanvasOverlay pinnedLines={pinnedLines}>
                        <Title title={title} />
                        <XAxis />
                        <YAxis />
                        <XLines />
                        {view !== "TFO" && view !== "Zone" && (
                            <CrossHairs excludeYLabel={true} xLabel={yFormat} excludeSetXOverlay={true} />
                        )}
                        { traces?.map(t => <Line key={t.key} trace={t} />) }                        
                        {/* {traces ? <Line key={traces[1].key} trace={traces[1]} />: null} */}
                        <TCPoints tcs={tcs} />
                        {/* <Cursor /> */}
                        { children }
                    </SVGCanvasOverlay>
                </ChartContext.Provider>
            </div>
}



export const ChartDepthOverTime = ({
    title,
    margins = defaultMargins2,
    xScaleType = "linear",
    xLim,
    yLim = [0, 300], // Inverted yLim for correct temperature orientation
    traces,
    xLines,
    tcs,
    yUnit,
    xUnit,
    setCursorData,
    isDataLoading, // New prop for loading state
}) => {
    const [width, height, ref] = useResizer();
    const [cursor, setCursor] = useState();

    const xScale = useMemo(() => {
        let scale;
        switch (xScaleType) {
            case "time":
                scale = d3.scaleTime();
                break;
            default:
                scale = d3.scaleLinear();
                break;
        }
        return scale.range([margins.left, width - margins.right]).domain(xLim);
    }, [width, margins, xLim, xScaleType]);

    const xFormat = useCallback((d) => {
        return xScaleType === "time" ? shortDate(d) : `${Math.round(d)} ${xUnit}`;
    }, [xUnit, xScaleType]);

    // Adjusted yScale for correct orientation and nice ticks
    const yScale = useCallback(
        yLim && d3.scaleLinear().range([270, height + 20]).domain(yLim),
        [height, margins, yLim]
    );

    const yFormat = useCallback((t) => `${t} ${yUnit}`, [yUnit]);

    const onCursorMove = useCallback(
        (e) => {
            // Implementation remains the same
        },
        [xScale, yScale, traces, setCursorData]
    );

    return (
        <div ref={ref} className="chart" style={{ position: 'relative' }}>
            {isDataLoading && (
                <div
                    style={{
                        position: 'absolute',
                        top: margins.top + 120,
                        left: margins.left,
                        width: width - margins.left - margins.right,
                        height: height,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Translucent background
                        zIndex: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin size="large" />
                </div>
            )}
            <ChartContext.Provider
                value={{
                    height: height === 0 ? 300 : height,
                    width,
                    margins,
                    title,
                    xScale,
                    yScale,
                    xFormat,
                    yFormat,
                    traces,
                    cursor,
                    onCursorMove,
                    xLines,
                }}
            >
                <SVGCanvasOverlay>
                    <Title title={title} />
                    <XAxis />
                    <YAxis />
                    <XLines />
                    <CrossHairs  xLabel={yFormat} excludeSetXOverlay={true} />
                    {traces?.map((t) => (
                        <Line key={t.key} trace={t} />
                    ))}
                    <TCPoints tcs={tcs} />
                </SVGCanvasOverlay>
            </ChartContext.Provider>
        </div>
    );
};