import { shortDate } from "../../../utils/date";
import { useState, useEffect } from "react";

import { colourWheel } from "../Trace/Colours";

import { HexColorPicker } from "react-colorful";

import { PushpinOutlined, MinusOutlined, SaveOutlined, BgColorsOutlined, EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";

import "./selected.css";
import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";
import { color } from "d3";

export const SelectedList = ({
        selected,
        updateTrace
    }) => {
        const [target, setTarget] = useState();

        const reOrder = (source) => {
            const i = selected.findIndex(t => t.key === source)
            const j = selected.findIndex(t => t.key === target.key) + (target.after ? 1 : 0)
            const offset = i < j ? 1 : 0;
            if (i === j - offset) return;

            const reordered = [...selected];
            reordered.splice(j - offset, 0, reordered.splice(i, 1)[0]);
            updateTrace(reordered)

        }

        const updateTraceItem = (key, update, prop) => {
            const idx = selected.findIndex(t => t.key === key);

            switch(update) {
                case "pinOn":
                    selected[idx].pin = true;
                    selected[idx].colour = colourWheel();
                    break;
                case "pinOff":
                    selected[idx].pin = false
                    break;
                case "colour":
                    selected[idx].colour = prop;
                    break;
                case "remove":
                    if (selected.length > 1) {
                        selected.splice(idx, 1);
                        updateTrace([...selected]);
                    }
                    break;
                case "visible":
                    selected[idx].visible = prop;
                    break;
                default:
                    console.log("default")
            }

            updateTrace(selected)
        }

        return <div className="trace-selection">
                   <div className="trace-selection-section">
                        { selected ? selected.map((t, i) => 
                        <SelectedListItem 
                            key={adjustToBrowserTime(t.key)}
                            trace={t} 
                            onDrop={reOrder} 
                            updateTrace={updateTraceItem}
                            setTarget={setTarget}
                            />
                        ) : null } 
                    </div>
         </div>
}

/*
Draggable list with drag and drop
*/
const SelectedListItem = ({trace, updateTrace, onDrop, setTarget}) => {
    const [ pickerVisible, setPickerVisible ] = useState(false);

    const onDragDropped = (e) => {
        e.preventDefault()
        e.target.style['border-top'] = '';
        e.target.style['border-bottom'] = '';
    }

    const onDragOver = (e) => {
        e.preventDefault()
        e.stopPropagation();

        if (e.target.className !== "trace-selection-line") return;

        var bounding = e.target.getBoundingClientRect()
        var offset = bounding.y + (bounding.height/2);
        if ( e.clientY - offset > 0 ) {
            e.target.style['border-bottom'] = 'solid 4px blue';
            e.target.style['border-top'] = '';
            setTarget({key: adjustToBrowserTime(trace.key), after: true})
        } else {
            e.target.style['border-top'] = 'solid 4px blue';
            e.target.style['border-bottom'] = '';
            setTarget({key: adjustToBrowserTime(trace.key), after: false})
        }

    }

    const onDragLeave = (e) => {
        e.target.style['border-top'] = '';
        e.target.style['border-bottom'] = '';
    }

    const onDragEnd = (e) => {
        onDrop(trace.key)
    }
    

    function getContrastTextColor(colour){
    
        function isHexColor(c) {
            return /^#([0-9A-F]{3}){1,2}$/i.test(c);
        }
    
        function namedToHex(named) {
            let ctx = document.createElement('canvas').getContext('2d');
            ctx.fillStyle = named;
            return ctx.fillStyle;
        }
    
        // This function calculates the luminance of a color and decides if the text should be black or medium grey
        function getTextColour(hex) {
            const r = parseInt(hex.substr(1,2),16);
            const g = parseInt(hex.substr(3,2),16);
            const b = parseInt(hex.substr(5,2),16);
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
            // Return 'black' for luminance < 0.27, else return medium grey
            return luminance < 0.27 ? '#D2D2D2':'black'; 
        }
    
    
        // Determine the correct color based on the hex or named color
        if (isHexColor(colour)) {
            return getTextColour(colour);
        } else if (!colour){
            return "black"; // Default to black if no color provided
        } else {
            return getTextColour(namedToHex(colour));
        }
    }

    return <>
        <div
            className="trace-selection-line" 
            style={{backgroundColor: trace.colour}} 
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDragDropped}
            onDragEnd={onDragEnd}
            draggable
            key={trace.key}
            >
            <span style={{color: getContrastTextColor(trace.colour)}}>{adjustToBrowserTime(trace.key)}</span>
            <div className="push-right">
                <BgColorsOutlined onClick={() => setPickerVisible(e => !e)} />
                <MinusOutlined onClick={() => updateTrace(trace.key, "remove")} />
                <PushpinOutlined onClick={() => updateTrace(trace.key, trace.pin ? "pinOff" : "pinOn")} rotate={trace.pin ? -45 : 0} />
                { trace.visible ? <EyeOutlined onClick={() => updateTrace(trace.key, "visible", false)}/> :
                <EyeInvisibleOutlined onClick={() => updateTrace(trace.key, "visible", true)}/> }
                <SaveOutlined />
            </div>
        </div>
        { pickerVisible ? <div style={{position: "absolute"}}>
            <HexColorPicker color={trace.colour} onChange={(c) => updateTrace(trace.key, "colour", c)} />
        </div>
 : null }
        </>
}
