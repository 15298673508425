import {
    useEffect,
    useState,
    useMemo,
} from "react";

import * as d3 from "d3";
import { XBrush } from "./Brush";

import { useResizer } from "../Hooks/Resizer";
import { ChartWithOverlay } from "../ChartWithOverlay";


export const DepthSelector = ({
    margins,
    onBrush,
    current,
    xLim,
    yLim,
    depthExtents,
    zoneData,
    yUnits,
}) => {

    const [depthExtentsCurrent, setDepthExtentsCurrent] = useState(xLim || [0,0]);


     // Update depthExtentsCurrent based on current changes
        useEffect(() => {
            if (current?.length) {
            let min = d3.min(current, (d) => d3.min(d.xData));
            let max = d3.max(current, (d) => d3.max(d.xData));

            // Update only if there's a change to avoid unnecessary re-renders
            setDepthExtentsCurrent((existing) => {
                if (existing[0] !== min || existing[1] !== max) {
                return [min, max];
                }
                return existing;
            });
            }
        }, [current]);

        // This useEffect is intended to react to external xLim changes.
        // Check if the deepest zone max depth is deeper than the fiber max depth and update the xLim accordingly
        useEffect(() => {
            if (xLim && (depthExtentsCurrent[0] > xLim[0] || depthExtentsCurrent[1] < xLim[1])) {
                if (xLim[1] > depthExtentsCurrent[1]){
                    setDepthExtentsCurrent([depthExtentsCurrent[0], xLim[1]]);
                }else{
                setDepthExtentsCurrent(xLim);
                }
            }
        }, [xLim]);

    return (
        <div className="depth-selector">
            <ChartWithOverlay
                margins={margins}
                title="Depth Selector"
                xLim={depthExtentsCurrent || depthExtents }
                yLim={yLim}
                traces={current}
                xUnit="mKB"
                yUnit={yUnits === "dB" ? "dB" : "℃"}
            >
            <XBrush
                brushExtents={xLim || depthExtentsCurrent || depthExtents}
                onBrush={onBrush}
            />
            </ChartWithOverlay>
            <ZonePicker
                zoneData={zoneData}
                onBrush={onBrush}
                margins={margins}
                depthExtents={depthExtents || depthExtentsCurrent}
            />
        </div>
    );

};



const ZonePicker = ({ onBrush, zoneData, margins, depthExtents }) => {

    const colours = [
        "#001219",
        "#005F73",
        "#0A9396",
        "#64A29D",
        "#C9A886",
        "#EE9B00",
        "#CA6702",
        "#BB3E03",
        "#AE2012",
        "#9B2226",
    ];

    const [width, height, ref] = useResizer();

    const [maxDepth, setMaxDepth] = useState(0);

    const xScale = useMemo(() => {
        let s = d3.scaleLinear().range([margins.left, width - margins.right]);

        if (depthExtents) {
            s.domain(depthExtents);
        }

        return s;
    }, [depthExtents, width, margins]);


  const ZoneBar = ({ z }) => {
    if (!z || !z.zones) return;

    const uniqueZones = Array.from(
        z.zones.reduce((acc, zone) => {
            const key = `${zone.b}-${zone.e}`;
            if (!acc.has(key)) {
                acc.set(key, zone);
            }
            return acc;
        }, new Map()).values()
    );

    uniqueZones.map((zone, i) => {
        // console.log("zone.e here",zone.e);
        if (zone.e > maxDepth) {
            setMaxDepth(zone.e);
        }
    });

    
    

    return (
        <div style={{ position: "relative", height: 48 }}>
            {uniqueZones.map((zone, i) =>
                
                (zone?.b || zone?.e) <= 0 || Math.abs(zone?.e - zone?.b) > 1000 ? null : (
                    <div
                        key={`zone_${i}`}
                        id={`zone_${i}`}
                        style={{
                            position: "absolute",
                            display: "inline-block",
                            left: xScale(zone.b),
                            width: xScale(zone.e) - xScale(zone.b),
                            backgroundColor: `${colours[i] + 50}`,
                            border: "1px solid black",
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            onBrush([zone.b, zone.e]);
                            /*
                            d3.select(svg)
                              .select(".brush")
                              .call(brush.move, [zone.b, zone.e].map(xScale));
                            */
                        }}
                    >
                        zone {i}
                    </div>
                )
            )}
            <span
                onClick={() => {
                    onBrush([xScale.domain()[0], maxDepth]);
                    // d3.select(svg)
                    //   .select(".brush")
                    //   .call(brush.move, [minDepthCutoff, depthRange[1]].map(xScale));

                }}
                style={{
                    position: "absolute",
                    bottom: "0px",
                    display: "inline-block",
                    background: "#9F8B4F",
                    border: "1px solid black",
                    textAlign: "center",
                    cursor: "pointer",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    color: "white",
                    left: margins.left,
                    borderRadius: "3px",
                }}
            >
                Select All
            </span>
        </div>
    );
};

    return (
        <div ref={ref} className="zone-picker">
            <ZoneBar z={zoneData} />
            <br></br>
        </div>
    );
};
