import { useState, useEffect, useCallback } from 'react';

import { TempSelector } from '../Selectors/YAxisTemp';

export const useTempSelector = (currentTraces, margins, binSize) => {
    const [tempLim, setTempLim] = useState();

    const [ traces, setTraces ] = useState();

    const onTempBrush = useCallback((t) => {
        // only update if different
        setTempLim(existing => { 
            if (!existing) return t;

            for (let i in existing) {
                if (existing[i] != t[i]) {
                    return t
                }
            }
            return existing  
        });
    }, []);


    useEffect(() => {
        if (currentTraces) {
            setTraces(currentTraces.filter(t => t.visible))
        }
    }, [currentTraces])

    return [
        tempLim,
        setTempLim,
        <TempSelector 
            margins={margins}
            traces={traces} 
            onBrush={onTempBrush} 
            binSize={binSize}
    /> 
    ];
}
