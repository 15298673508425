import { useState, useEffect, useCallback, useContext, useMemo } from "react";

import { TimeSelector, DepthSelector } from "../Selectors"
import { DepthLossChart } from "../DepthLossChart";

import { depthLimits } from "../../../utils/depth";

import './layout.css';

import { TraceSelector } from "../Selectors/TraceSelector";
import { WellContext } from "../../../api/well";
import { ChartSummary } from "../Tooltip/Summary";
import { SelectedList } from "../Selectors/SelectedList";
import { HoverDataList } from "../Selectors/HoverDataList";
import { depthGen } from "../../../utils/depth";
import CSVDownload from "../../Misc/CSVDownload";
import { colourWheel } from "../Trace/Colours";
import { autoSizeYLim } from "../Axes/AutoSizeYLim";
import ApiRequest from "../../../api/request";

import { useCursorData } from "../Hooks";

import  convertTrace from "../convert";
import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";
import { useDBWellApi } from "../../../api/DBWellApi";
import { ChartDepthOverTime } from "../Chart";


let lossExtents = [-4, 0];

const margins = {
        top: 20,
        bottom: 30,
        left: 60,
        right: 65
    }

const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "250px 1fr 150px",
      gridTemplateRows: "200px 1fr 200px",
      height: "90vh",
    },
    leftColumn: {
      gridColumn: "1 / 2",
      gridRow: "1 / 4",
      backgroundColor: "lightgrey",
    },
    middleColumnTop: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
    },
    chart: {
      gridColumn: "2 / 3",
      gridRow: "2 / 3",
      backgroundColor: "grey",
    },
    depthTrack: {
      gridColumn: "2 / 3",
      gridRow: "3 / 4",
      backgroundColor: "lightgrey",
    },
    tempTrack: {
      gridColumn: "3 / 4",
      gridRow: "2 / 3",
      backgroundColor: "lightgrey",
    },
    chartDepthOverTimeContainer: {
        gridColumn: "2 / 3",
        gridRow: "3 / 4",
        backgroundColor: "#ccc",
        display: 'none',
        paddingBottom: '300px'
    },
};

export const LossVDepthLayout = ({
    range, 
    activeTab
}) => {

    const wellAPI = useContext(WellContext);
    const { fetchInfo, fetchTrace, fetchZone, queryTimestamps, fetchDepth} = wellAPI;
    const [ currentTrace, setCurrentTrace ] = useState();
    const [ depthTraces, setDepthTraces ] = useState([]);
    const [ depthLim, setDepthLim ] = useState();
    const [depthSelectorLossLims, setdepthSelectorLossLims] = useState(lossExtents);
    const [ depthExtents, setDepthExtents ] = useState([0, 1]);
    const [ lossLim, setlossLim ] = useState(lossExtents);
    const [ selectedTraceTimestamp, setselectedTraceTimestamp ] = useState(wellAPI?.fetchTrace?.currentRequest?.timestamp);
    const fetchHighlight = ApiRequest(wellAPI.fetchTrace.args)
    const [ TimeSelectorTraces, setTimeSelectorTraces ] = useState([]);
    const [ xLines, setXLines ] = useState([]);
    const [csvData, setCSVData] = useState();
    
    const [timeSelectorLossLims, settimeSelectorLossLims] = useState(lossExtents);
    const [ depths, setDepths ] = useState([])
    const [ cursorData, setCursorData ] = useCursorData(depthTraces);
    const [isLoading, setIsLoading] = useState(false);
    const [lossQueryTimestamps, setLossQueryTimestamps] = useState([]);
    const [initialLoad, setInitialLoad] = useState(false);
    const [lossVTimeModalVisible, setLossVTimeModalVisible] = useState(true);
    const [depthDataLoading, setDepthDataLoading] = useState(false);
    const [lossVTimeXLims, setLossVTimeXLims] = useState([0, 1]);
    const [lossVTimeYLims, setLossVTimeYLims] = useState([-4, 0]);
    const [lossVTimeData, setLossVTimeData] = useState([]);
    const { getLossAtDepth } = useDBWellApi();
    
    useEffect(() => {
        
        if (queryTimestamps.data) {
            fetchZone.sendRequest({start: queryTimestamps.data[0], end: queryTimestamps.data[queryTimestamps.data.length - 1]})
            // let traceSelectorDepth = currentTrace?.start+currentTrace?.span-9; // 9 because that is the magic number to sync up with the loss near toe displayed in top right panel
            // fetchDepth.sendRequest({start: queryTimestamps.data[0], end: queryTimestamps.data[queryTimestamps.data.length - 1], depths: traceSelectorDepth})
                      
            
            return () => {
                fetchZone.cancel();
                // fetchDepth.cancel();
            }
            
            
        }

    }, [queryTimestamps.data, !currentTrace])

    useEffect(() => {
        const initializeQueryTimestamps = () => {
            if (queryTimestamps.data && !initialLoad) {
                setLossQueryTimestamps([queryTimestamps.data[0], queryTimestamps.data[queryTimestamps.data.length - 1]]);
                setInitialLoad(true);
            }
        };

        // Introduced a delay here to allow queryTimestamps to initialize, theres probably a better way and will revise
        const timeoutId = setTimeout(initializeQueryTimestamps, 500); // 500ms delay

        return () => clearTimeout(timeoutId); // Clean up the timeout on component unmount
    }, [queryTimestamps.data, initialLoad]);
    
    
    useEffect(() => {
        if (!lossQueryTimestamps) return;

        if (xLines.length < 1) {
            setLossVTimeData([]);
            setLossVTimeModalVisible(false);
        } else {
            setLossVTimeModalVisible(true);
        }
    
        const fetchLossAtDepth = async () => {
            const fetchPromises = xLines.map(async (xLine) => {
                try {
                    const response = await getLossAtDepth({
                        site: wellAPI.siteName,
                        well: wellAPI.wellName,
                        requestObject: {
                            start: lossQueryTimestamps[0],
                            end: lossQueryTimestamps[1],
                            depth: xLine.key
                        }
                    });
    
                    if (response.data) {
                        const { timestamps, losses } = response.data;
    
                        if (timestamps && losses && timestamps.length === losses.length) {
                            const xData = timestamps.map(timestamp => new Date(timestamp));
                            const yData = losses.map(loss => parseFloat(loss.toFixed(3)));
    
                            return [
                                {
                                    key: `${xLine.key}_min`,
                                    xData: xData,
                                    yData: yData,
                                    colour: xLine.colour
                                },
                                {
                                    key: `${xLine.key}_max`,
                                    xData: xData,
                                    yData: yData,
                                    colour: xLine.colour
                                }
                            ];
                        }
                    }
                } catch (error) {
                    console.error('Error fetching loss data:', error);
                }
                return null;
            });
    
            const lossDataArrays = await Promise.all(fetchPromises);
            const lossData = lossDataArrays.flat().filter(item => item !== null);
    
            // Calculate Y-axis limits with a buffer
            let yMin = Infinity;
            let yMax = -Infinity;
            
            lossData.forEach(data => {
                const minData = Math.min(...data.yData);
                const maxData = Math.max(...data.yData);
                if (minData < yMin) yMin = minData;
                if (maxData > yMax) yMax = maxData;
            });
    
            // Add a buffer to the min and max values
            const buffer = 0.3; // Adjust the buffer value as needed
            yMin -= buffer;
            yMax += buffer;
    
            setLossVTimeData(prevState => {
                const newState = [...prevState];
    
                lossData.forEach(newData => {
                    if (newData) {
                        const existingIndex = newState.findIndex(data => data.key === newData.key);
                        if (existingIndex !== -1) {
                            newState[existingIndex] = newData;
                        } else {
                            newState.push(newData);
                        }
                    }
                });
    
                return newState;
            });
    
            // Set Y-axis limits
            setLossVTimeYLims([yMin, yMax]);
        };
    
        fetchLossAtDepth();
    }, [xLines, lossQueryTimestamps]);

    // Fetch loss data at the depth of the trace
    useEffect(() => {
            const fetchLossAtDepth = async () => {
                if (lossQueryTimestamps && currentTrace ) {
                    const traceSelectorDepth = currentTrace.start + currentTrace.span - 9;
                     
                    try {
                        const response = await getLossAtDepth({
                            site: wellAPI.siteName,
                            well: wellAPI.wellName,
                            requestObject: {
                                start: lossQueryTimestamps[0],
                                end: lossQueryTimestamps[1],
                                depth: traceSelectorDepth
                            }
                        });
            
            
                        const { timestamps, losses } = response.data;
            
                        if (timestamps && losses && timestamps.length === losses.length) {
                            const xData = new Array(timestamps.length);
                            const yDataMin = new Array(timestamps.length);
                            const yDataMax = new Array(timestamps.length);
            
                            for (let i = 0; i < timestamps.length; i++) {
                                xData[i] = new Date(timestamps[i]);
                                const lossValue = parseFloat(losses[i].toFixed(3));
                                yDataMin[i] = lossValue;
                                yDataMax[i] = lossValue;
                            }
            
            
                            setTimeSelectorTraces([
                                {  
                                    key: 'well_min',
                                    xData: xData,
                                    yData: yDataMin,
                                    colour: 'red'
                                },
                                {   
                                    key: 'well_max',
                                    xData: xData,
                                    yData: yDataMax,
                                    colour: 'red'
                                }
                            ]);
                            setLossVTimeXLims([xData[0], xData[xData.length - 1]])

                            setIsLoading(false);
                        }
                    } catch (error) {
                        console.error('Error fetching loss data:', error);
                    }
                }
            };
            
            fetchLossAtDepth();
    
    },[lossQueryTimestamps])

    // Update depthLimits and depthExtents
    useEffect(() => {
        if (depthTraces[0]) {
            let newDl = depthLimits(depthTraces[depthTraces.length-1])
            // newDl[0] = 0;

            // setDepthExtents(existing => {
            //     for (let i in existing) {
            //         if (existing[i] != newDl[i]) return newDl 
            //     }
            //     return existing
            // })

            if (!depthLim) {
                setDepthLim(newDl)
            } 
            
            if (!selectedTraceTimestamp) {
                setselectedTraceTimestamp(new Date(currentTrace.timestamp))
            }
        }
    }, [currentTrace, selectedTraceTimestamp])

    useEffect(() =>{
        if (!depthTraces[0]) return;
        else if (depthExtents != [depthTraces[0].xData[0], (depthTraces[0].xData.length-1)+depthTraces[0].xData[0]]) {
            setDepthExtents([depthTraces[0].xData[0], (depthTraces[0].xData.length-1)+depthTraces[0].xData[0]])
        }
    },[depthTraces])

    useEffect(() => {
        wellAPI.fetchInfo.sendRequest()
        return wellAPI.fetchInfo.cancel
    }, [range]);

    // Load the latest trace and update currentTrace to start
    useEffect(() => {
        wellAPI.fetchLatest.sendRequest()
        return wellAPI.fetchLatest.cancel
    }, []);

    // If selected moves send a request for the new trace
    useEffect(() =>{
        fetchHighlight.sendRequest({timestamp: selectedTraceTimestamp, dtype: "loss"})
        return fetchHighlight.cancel
    }, [selectedTraceTimestamp])
    
    useEffect(() => {
        if (!depthLim && currentTrace) {
          setDepthLim([currentTrace.start, currentTrace.span + currentTrace.start])
        }
    
        if (!lossLim && currentTrace) {
          setlossLim([-4, 0])
        }
    }, [currentTrace])

    useEffect(() => {
        wellAPI.fetchLatest.data && setCurrentTrace(wellAPI.fetchLatest.data);
    }, [wellAPI.fetchLatest.data])

    useEffect(() => {
        selectedTraceTimestamp && wellAPI.fetchTrace.sendRequest({timestamp: selectedTraceTimestamp, dtype: "loss"})
    }, [selectedTraceTimestamp]);


    const getLossPoints = (rec) => {
        const zero = -rec?.xData[0];
        if (!rec) return null;
        let lossParams = {
            timestamp: rec?.key,
            connector: rec?.yData[1].toFixed(3),
            wellhead: (rec?.yData[zero + 10] - rec?.yData[zero]).toFixed(3),
            splice: (rec?.yData[zero - 20] - rec?.yData[zero]).toFixed(3),
            last: rec?.yData[rec?.yData.length - 10].toFixed(3),
            // channel: rec?.lossInfo?.Chan,
            // attenuation: Math.min(...rec?.lossInfo?.Cals.map(c => c.DiffAtt)).toFixed(4),
            // x: [depthGen(rec)],
            // y: [{label: rec?.lossInfo?.Timestamp, data: rec?.lossInfo?.Loss}]
            }

            return <>
                <div style={{fontSize:"95%"}}>
                    <div><b>Timestamp: </b> {adjustToBrowserTime(lossParams.timestamp)}</div>
                    <div><b>Connector:</b> {lossParams.connector} dB</div>
                    <div><b>Across Splice:</b> {lossParams.splice} dB</div>
                    <div><b>Across WH:</b> {lossParams.wellhead} dB</div>
                    <div><b>Near toe:</b> {lossParams.last} dB</div>
                </div>
            </>
    }
    const lossInfo = wellAPI?.fetchTrace?.data?.[0];

    const onDepthBrush = useCallback((d) => {
        // only update if different
        let dRound = [Math.round(d[0]), Math.round(d[1])]
        setDepthLim(existing => { 
            for (let i in existing) {
                if (existing[i] != dRound[i]) {
                    return dRound
                }
            }
            return existing
        })
    }, []);
    

    // set time selector loss lims
    useEffect(() => {
        if (!TimeSelectorTraces[0]?.xData) return;
        settimeSelectorLossLims([Math.min(...TimeSelectorTraces[0]?.yData), Math.max(...TimeSelectorTraces[0]?.yData)])
    }, [TimeSelectorTraces])


    // Set the tempLims to to always always have all traces fully in view within depth section
    useEffect(() => {
        if(!currentTrace) return;
        if(depthTraces.length==0) return;
        let yIndexArr = [];
        for (let i = 0; i < depthTraces.length; i++) {
            let xDataArr = depthTraces[i]["xData"];
            let yDataArr = depthTraces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(depthLim[0]));
            let endIndex = (xDataArr.indexOf(Math.round(depthLim[1]))==-1) ? ((depthTraces[0]?.xData?.length-1)) : (xDataArr.indexOf(Math.round(depthLim[1])));
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
        }
        
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
        
        if (minValue < -20) minValue = -20;

        setlossLim([minValue*1.005, maxValue*0.995])

    }, [depthLim, depthTraces]);
    
    // Set the depthSelectorTempLims to to always have all traces in view through the entire length of the string
    useEffect(() => {
        if(!currentTrace) return;
        if(depthTraces.length==0) return;
        let initialDepthLim = [currentTrace?.start, currentTrace?.span + currentTrace?.start];

        let yIndexArr = [];
        
        for (let i = 0; i < depthTraces.length; i++) {
          let xDataArr = depthTraces[i]["xData"];
          let yDataArr = depthTraces[i]["yData"];
          let startIndex = xDataArr.indexOf(Math.round(initialDepthLim[0]));
          let endIndex = xDataArr.indexOf(Math.round(initialDepthLim[1]));
          let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
          yIndexArr = yIndexArr.concat(tempYIndexArr);
        }

        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
        
        if (minValue < -20) minValue = -20;
        
        setdepthSelectorLossLims([minValue*1.005, maxValue*0.995])
    }, [depthTraces])

    useEffect(() => {    
        /*
        Populate the traces with appropriate data
        */

        setDepthTraces(ex => {
            // let ts = ex.map(t => Date.parse(t.key));
            // let ts = ex.map(t => t.key);
            let traces = [...ex.filter(t => t.pin)];
            let ts = traces.map(t => t.key);

            if (
                selectedTraceTimestamp && 
                fetchHighlight.data?.length && 
                // !ts.includes(selectedTrace.valueOf()) &&
                !ts.includes(fetchHighlight.data[0].timestamp)) {
                    let highlight = convertTrace(fetchHighlight.data[0], "red");
                    traces.push(highlight)
            }

            return traces
        })
    }, [currentTrace, selectedTraceTimestamp, fetchHighlight.data])


    //as far as I can tell, this effect works to create the same as before but less iterations and more compact code
    //Populate TimeSelectorTraces 
    // useEffect(() => {
    //     if (!wellAPI?.fetchDepth?.data?.loss) return;

    //     // Destructure timestamps and toeLoss for easier access
    //     const timestamps = wellAPI.fetchDepth.data.timestamps;
    //     const toeLoss = wellAPI.fetchDepth.data.loss[0];

        
    //     // Check if the data arrays match in length
    //     if (timestamps && toeLoss && timestamps.length === toeLoss.length) {
    //         // Pre-allocate arrays to avoid push and resizing
    //         const xData = new Array(timestamps.length);
    //         const yDataMin = new Array(timestamps.length);
    //         const yDataMax = new Array(timestamps.length);
            
    //         // Fill the arrays directly in a single loop
    //         for (let i = 0; i < timestamps.length; i++) {
    //             const timestampIndex = timestamps.length - 1 - i;
    //             xData[i] = new Date(timestamps[timestampIndex]);
    //             const lossValue = parseFloat(toeLoss[i].toFixed(3));
    //             yDataMin[i] = lossValue;
    //             yDataMax[i] = lossValue;
    //         }
    //         // Set the traces with direct references to the newly created arrays
    //         setTimeSelectorTraces([
    //             {  
    //                 key: 'well_min',
    //                 xData: xData,
    //                 yData: yDataMin,
    //                 colour: 'red'
    //             },
    //             {   
    //                 key: 'well_max',
    //                 xData: xData,
    //                 yData: yDataMax,
    //                 colour: 'red'
    //             }
    //         ]);
    //     }
    // }, [wellAPI.fetchDepth.data]);


    const timeDrag = (t) => {
        setselectedTraceTimestamp(t)
    }

    //for displaying the Depth Over Time chart below main chart     
    const handleShowLossVTimeModal = () => {
        setLossVTimeModalVisible(!lossVTimeModalVisible);
    };

    // Update data for csv download
    useEffect(() => {
        if (!depthTraces.length) return;
        const blob = {}
        blob["Depth"] = depthTraces[0].xData;
        for (let traceIdx = 0; traceIdx<depthTraces.length; traceIdx++){
            blob[(depthTraces[traceIdx].key)] = depthTraces[traceIdx].yData;
        }
        if (!blob) return;
        blob[""] = "";
        blob[" "] = ["Connector", "Across Splice", "Across WH", "Near Toe"];  
        const zero = -lossInfo?.start;
        let tempLossPointsArray = [];
        tempLossPointsArray.push(lossInfo?.data[1].toFixed(3));
        tempLossPointsArray.push((lossInfo?.data[zero - 20] - lossInfo?.data[zero]).toFixed(3))
        tempLossPointsArray.push((lossInfo?.data[zero + 10] - lossInfo?.data[zero]).toFixed(3))
        tempLossPointsArray.push(lossInfo?.data[lossInfo?.data.length - 10].toFixed(3))       

        blob["Loss(dB)"] = tempLossPointsArray;
        setCSVData(blob);
    }, [ depthTraces, wellAPI ])

    useEffect(() => {
        if (!currentTrace) {return};
        setDepths(depthGen(currentTrace))
    }, currentTrace)

    const selectedZone = useMemo(() => {
        if (!wellAPI.fetchZone.data) return;

        return wellAPI.fetchZone.data[0].reduce((p, c, i) => {
            // if (Date.parse(c) == Date.parse(selectedTraceTimestamp)){
            //     // console.log("Found the trace at index "+i)
            //     // console.log({c}, {selectedTrace})
            //     // let tcObject = {};
            //     // let tcTemps = [];

            //     // for (let tcIdx=wellAPI?.fetchTCData?.data?.data.length-1; tcIdx >= 0; tcIdx--){
            //     //     tcTemps.push(wellAPI?.fetchTCData?.data?.data[tcIdx][i]);
            //     //     tcObject["data"]=tcTemps;
            //     //     tcObject["timestamp"]=wellAPI?.fetchTCData?.data?.timestamps[i];
            //     // }

            //     // setSelectedTCs(tcObject);
            // }  

            if (selectedTraceTimestamp && (Date.parse(c) == Date.parse(selectedTraceTimestamp))) {
                return {time: c, zones: wellAPI.fetchZone.data[1].map(z => z[i])} 
            }
            return p 
    })
    }, [wellAPI.fetchZone.data, selectedTraceTimestamp, currentTrace, wellAPI.fetchTCData]);

    const handleLineClick = () => {
        // Check if selectedTrace matches any trace in depthTraces
        const updatedTraces = depthTraces.map(trace => {
            // Check if the trace matches the selectedTrace and if pin is currently false
            if (trace.key === selectedTraceTimestamp && !trace.pin) {
                return { ...trace, pin: true, colour: colourWheel() }; // Update pin and change color
            } else if (trace.key === selectedTraceTimestamp && trace.pin) {
                return { ...trace, pin: true }; // Keep pin true but don't change color
            }
            return trace; // No changes for other traces
        });
        
        setDepthTraces(updatedTraces); // Update the depthTraces state
    };


    return <div style={styles.container}>
          <div style={styles.leftColumn}>
            <SelectedList 
                selected={depthTraces} 
                updateTrace={a => setDepthTraces([...a])} 
            />
            <ChartSummary 
                data={cursorData}
                xData={xLines}
                tab = "loss"
                showModal={handleShowLossVTimeModal}
                updateXData={a => setXLines([...a])} 
            />
          </div>
          <div style={styles.middleColumnTop}>
            <TimeSelector 
                margins={margins} 
                current={TimeSelectorTraces} 
                selected={selectedTraceTimestamp} 
                setSelectedTrace={setselectedTraceTimestamp}
                timeSelectorLossLims={timeSelectorLossLims} 
                onTimeDrag={timeDrag} 
                noBrush
                yUnits="dB"
                depths={depths}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onLineClick={handleLineClick}
                setLossQueryTimestamps={setLossQueryTimestamps}
                pinnedTraces={depthTraces}
                activeTab={activeTab} //used to flag a tab change to stop playforward/playbackward.
                view="loss" //used to determine which data to display in the TimeSelector (loss view time range chart needs to be reversed)
            />
          </div>
          <div style={styles.chart}>
            <DepthLossChart 
                margins={margins}
                traces={depthTraces}
                xLim={depthLim}
                yLim={lossLim}
                title={selectedTraceTimestamp ? `${wellAPI.wellName} - ${adjustToBrowserTime(selectedTraceTimestamp)}`: "No Data"}
                setCursorData={setCursorData}
                setXLines={setXLines} 
                xLines={xLines}
            />
          </div>
          <div style={styles.topRight}>
            <CSVDownload
                type = "loss"
                viewer= "HealthStatus"
                timestamp={currentTrace?.timestamp}
                blob = {csvData}
                csvDataLoaded={true}
            />
            <div>
                {getLossPoints(depthTraces[0])}
            </div>
          </div>
          <div style={styles.depthTrack}>
          <div style={{...styles.chartDepthOverTimeContainer, display: lossVTimeModalVisible ? 'block' : 'none', gridRow: "3 / 4"}}>
                {lossVTimeModalVisible && (
                    <ChartDepthOverTime
                        margins={margins}
                        xScaleType="time"
                        title={'Loss vs Time Chart'}
                        xLim={lossVTimeXLims}
                        yLim={lossVTimeYLims}
                        xUnit=""
                        yUnit={"dB"}
                        traces={lossVTimeData}
                        useCrosshairs={true}
                        isDataLoading={depthDataLoading}
                    />
                )}
            </div>
            <DepthSelector
                key={depthSelectorLossLims}
                margins={margins}
                current={depthTraces}
                onBrush={onDepthBrush}
                depthExtents={depthExtents}
                xLim={depthLim}
                yLim={depthSelectorLossLims}
                zoneData={selectedZone}
                yUnits = "dB"
            />
          </div>
          <div style={styles.tempTrack}>
          </div>
        </div>
}
