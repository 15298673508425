import { AuthContext } from "react-oauth2-code-pkce";
import { useContext } from "react";

export const Logout = () => {
  const { logOut } = useContext(AuthContext);

  logOut();

  return null
}

export default Logout;