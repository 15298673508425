// import "antd/dist/antd.css";
import { Table, Spin, Button, Input, Space } from "antd";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import useApiClient from "../../api/useApi";
import RangeSelector from "../Charts/Selectors/RangeSelector";
import { SearchOutlined } from '@ant-design/icons';
import { UserContext } from "../../api/user";

import "./DashboardTable.css";

// Should return the table with; Site Name, Well Name, and Zone Temps for all Sites/Wells the user has access to.
function DashboardTable() {
  const [onlineTableDataState, setonlineTableDataState] = useState([]);
  const [offlineTableDataState, setofflineTableDataState] = useState([]);
  const [onlinePadArray, setonlinePadArray] = useState([]);
  const [offlinePadArray, setofflinePadArray] = useState([]);
  const [wellCounter, setwellCounter] = useState(0);
  const [range, setRange] = useState("1we");
  const [isLoading, setisLoading] = useState(true);
  const [reloadOnlineTableData, setreloadOnlineTableData] = useState(false);
  const [tableToLoad, settableToLoad] = useState("online");
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  const client = useApiClient();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const onlineOfflineButtonsRef = useRef(null);
  const rangeSelectorRef = useRef(null);

  // Used to handle table search parameters
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Used to reset table search parameters
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  
  useEffect(() => {
    const handleResize = () => {
      const onlineOfflineButtonsHeight = onlineOfflineButtonsRef.current ? onlineOfflineButtonsRef.current.offsetHeight : 0;
      const rangeSelectorHeight = rangeSelectorRef.current ? rangeSelectorRef.current.offsetHeight : 0;
      const availableHeight = window.innerHeight - onlineOfflineButtonsHeight - rangeSelectorHeight;
      setWindowHeight(availableHeight);
    };
  
    handleResize();
  
    const interval = setInterval(handleResize, 500);
  
    return () => {
      clearInterval(interval);
    };
  }, []);

  const tableHeight = Math.max(400, windowHeight * 0.6); // Change 0.8 to the desired percentage of the window height

  // Used to search columns and display line items matching search string
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            className="button-range-active-loaded"
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            className="button-range-active-loaded"
            onClick={() => {
              clearFilters &&
              handleReset(clearFilters); 
              confirm({ closeDropdown: true })}
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : '#FFFFFF',
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  let onlineZonesDataColumnWidth = 75;
  // Set's up the onlineColumns for the online table data to go into.
  const onlineColumns = [
    {    
    title: "Site",
    dataIndex: "siteURL",
    key: "site",
    width: 110,
    fixed: "left",
    ...getColumnSearchProps('site'),
    },  
    {
      title: "Well",
      dataIndex: "wellURL",
      key: "well",
      width: 100,
      fixed: "left",
      ...getColumnSearchProps('well'),
    },
  ];
  for (let i = 1; i <= 10; i++) {
    onlineColumns.push({
      title: `Zone ${i}`,
      children: [
        {
          title: "Current (\u00B0C)",
          dataIndex: `current_${i - 1}`,
          key: `current_${i - 1}`,
          width: onlineZonesDataColumnWidth,
        },
        {
          title: `(${range}) Average (\u00B0C)`,
          dataIndex: `historical_${i - 1}`,
          key: `historical_${i - 1}`,
          width: onlineZonesDataColumnWidth,
        },
      ],
    });
  }

  // Set's up the offlineColumns for the offline table data to go into.
  const offlineColumns = [
    {
      title: "Site",
      dataIndex: "siteURL",
      key: "site",
      width: 23,
      fixed: "left",
      ...getColumnSearchProps('site'),
    },
    {
      title: "Well",
      dataIndex: "wellURL",
      key: "well",
      width: 20,
      fixed: "left",
      ...getColumnSearchProps('well'),
    },
    {
      title: "Last Online",
      dataIndex: "Last_Online",
      key: "Last_Online",
      width: 100,
      fixed: "left",
    },
    {
      title: "Offline Status",
      dataIndex: "offlineCode",
      key: "offlineCode",
      width: 300,
      fixed: "left",
    }
  ];


  const user = useContext(UserContext);

  // When userAPI.data or range updates, re-populate the table data current temp and historical average temps by zone
  useEffect(() => {
    reloadOnlineTableData && user?.pads?.forEach(function (pad) {
      client.get(`/dts/site/${pad}`).then(function (response) {
        // Check if pad has any current data
        if (response.data == null || response.data.wells == null) {
          console.log("No Fiber data found for pad " + response.data.name)
          setofflinePadArray((existingofflinePadArray) => [...existingofflinePadArray, response.data.name]);
        }
        // pad has current data, start fetching and putting into table data
        else {
          setonlinePadArray((existingonlinePadArray) => [...existingonlinePadArray, response.data.name]);
          setwellCounter((currentCount) => currentCount + response.data.wells.length);
          // run through all the wells per site
          for (let wellNum = 0; wellNum < response.data.wells.length; wellNum++) {
            let newOnlineItem = {};
            let newOfflineItem = {};
            // set online table values
            newOnlineItem["key"] = response.data.name.concat(response.data.wells[wellNum].name).replace(/[^A-Z0-9]/gi, "");
            newOnlineItem["siteURL"] = (<Link className="table-row-text-size" to={`/site/${response.data.name}`}>{response.data.name}</Link>);
            newOnlineItem["site"] = response.data.name;
            newOnlineItem["wellURL"] = (<Link className="table-row-text-size" to={`/site/${response.data.name}/${response.data.wells[wellNum].name}`}>{response.data.wells[wellNum].name}</Link>);
            newOnlineItem["well"] = response.data.wells[wellNum].name;
            newOnlineItem["date"] = response.data.wells[wellNum].latest.Timestamp;
            // set offline table values
            newOfflineItem["key"] = response.data.name.concat(response.data.wells[wellNum].name).replace(/[^A-Z0-9]/gi, "");
            newOfflineItem["siteURL"] = (<Link className="table-row-text-size" to={`/site/${response.data.name}`}>{response.data.name}</Link>);
            newOfflineItem["site"] = response.data.name;
            newOfflineItem["wellURL"] = (<Link className="table-row-text-size" to={`/site/${response.data.name}/${response.data.wells[wellNum].name}`}>{response.data.wells[wellNum].name}</Link>);
            newOfflineItem["well"] = response.data.wells[wellNum].name;
            newOfflineItem["Last_Online"] = response.data.wells[wellNum].latest.Timestamp;
            newOfflineItem["offlineCode"] = "Unknown Error: Contact PetroViz administrator for diagnostic assistance"; // Default Offline Status
            // start checking zone data, if non-valid data found, move it to offline wells table
            client.get(`/dts/site/${response.data.name}/${response.data.wells[wellNum].name}/zone`,{ params: { range: range } }).then(function (zoneResp) {
              let currentTempArray = []; // used to check if temp data is valid
              if (response.data.wells[wellNum].latest.Zone === null) {
                newOfflineItem["offlineCode"] = "No zone temperature data found"
                setofflineTableDataState((existingLines) => [...existingLines, newOfflineItem]);
              } 
              else {
                // run through all the zones at this iteration of the well
                for (let zoneNum = 0; zoneNum < response.data.wells[wellNum].latest.Zone.length; zoneNum++) {
                  currentTempArray.push(checkTempPositive(response.data.wells[wellNum].latest.Zone[zoneNum].mx));
                  newOnlineItem["current_" + zoneNum] = checkTempDisparity(checkTempPositive(response.data.wells[wellNum].latest.Zone[zoneNum].mx), getHistoricalAverageTemp({ zoneResp }, zoneNum));
                  newOnlineItem["historical_" + zoneNum] = <span className="historicalAverageTemp">{getHistoricalAverageTemp({ zoneResp }, zoneNum)}</span>
                }
                // Check if all current values are the same (indicates that current data is invalid)
                if (currentTempArray.every((val, i, arr) => val === arr[0])) {
                  const brokenFiberPoint = checkForBrokenFiber(response.data.wells[wellNum].latest.Loss);
                  const depths = () => {
                      let arr = [];
                      let rec = response.data.wells[wellNum].latest;
                      for (let i = rec.Start; i <= rec.Span + rec.Start; i += rec.Step) {
                          arr.push(i)
                      }
                      return arr
                    }
                  newOfflineItem["offlineCode"] = "Fiber issue detected. Reading loss of '"+brokenFiberPoint?.value.toFixed(2)+" dB' at depth '"+depths()[brokenFiberPoint?.index]+" mKB'";
                  setofflineTableDataState((existingLines) => [...existingLines, newOfflineItem]);
                }
                // Check if current data timestamp is new enough to be considered online
                else if (isDateCurrent(response.data.wells[wellNum].latest.Timestamp)) {
                  newOfflineItem["offlineCode"] = "Fiber appears to be offline. Last good reading at: ' "+response.data.wells[wellNum].latest.Timestamp+" '";
                  setofflineTableDataState((existingLines) => [...existingLines, newOfflineItem]);
                } 
                else {
                  setonlineTableDataState((existingLines) => [...existingLines, newOnlineItem]);
                }
              };
            });
          };
        };
      });
    });
    setreloadOnlineTableData(false);
    setisLoading(false);
  }, [reloadOnlineTableData]);

  // If new range set by user, reload table data using new range
  useEffect(() => {
    setwellCounter(0);
    setonlineTableDataState([]);
    setofflineTableDataState([]);
    setonlinePadArray([]);
    setofflinePadArray([]);
    setreloadOnlineTableData(true);
    setisLoading(true);
  }, [range]);

  // Check if the api calls have gone wild, reload page if they have
  useEffect(() => {
    if (
      onlinePadArray.length + offlinePadArray.length >
      user?.pads.length
    ) {
      // window.location.reload();
    }
  }, [onlinePadArray, offlinePadArray]);

  // Checks if the table data has been loaded up and is ready to be rendered
  useEffect(() => {
    if (onlineTableDataState.length + offlineTableDataState.length === 0) {
      setisLoading(true);
    } else {
      setisLoading(false);
    }
  }, [onlineTableDataState.length + offlineTableDataState.length === wellCounter]);

  // Check if user.pads has been loaded but length is 0, if so, set isLoading to false
  useEffect(() => {
    if (user?.pads.length === 0) {
      setisLoading(false);
    }
  }, [user?.pads.length === 0]);

  // Sort the table data primarily by site, secondarily by well
  onlineTableDataState.sort((a, b) => a.key.localeCompare(b.key, undefined, {numeric:true}));
  offlineTableDataState.sort((a, b) => b.Last_Online.localeCompare(a.Last_Online, undefined, {numeric:true}));

  // Color range picker buttons based on current tableToLoad value
  function buttonStyleOnlineOffline(tableType) {
    switch(tableType === tableToLoad) {
      case true:
        return "button-OnlineOffline-active"
      case false:
        return "button-OnlineOffline-inactive"
    }
  }

  // Checks if there is a significant temperature difference between the current and historical average and colours text to highlight potential issues
  function checkTempDisparity(currentTemp, historicalTemp) {
    const acceptablePercentChange = 2.5; // the allowable percentage change between the current and historical temps before marking text red
    const tempCutoff = 50; // this is the lower bound of temperatures before starting to apply conditional text colours
    let percentChange = (Math.abs((parseFloat(currentTemp) - parseFloat(historicalTemp))/(parseFloat(historicalTemp))))*100;

    if (historicalTemp === "") { // no historical data to compare to
      return <span className="table-row-text-size">{currentTemp}</span>
    }
    else if ((percentChange >= acceptablePercentChange) && (currentTemp > tempCutoff && historicalTemp > tempCutoff)) { // ignore temps less than 50, figured they're not relevant
      return <span className="currentTempOutsideRange">{currentTemp}</span>
    }
    else {
      return <span className="table-row-text-size">{currentTemp}</span>
    }
  }

  // Checks if a temperature is positive or negative. Used for data validation as negative temperatures indicate no data typically
  function checkTempPositive(temp) {
    if (parseFloat(temp) <= 0) {
      return "";
    } 
    else {
      return parseFloat(temp).toFixed(2);
    }
  }

  function checkForBrokenFiber(lossArr) {
    for (let i = 0; i < lossArr.length; i++) {
      if (lossArr[i] < -20) {
        return { index: i, value: lossArr[i] };
      }
    }
    return null; // Return null if no values are less than -20
    }

  // Fetches the historical data average per zone
  function getHistoricalAverageTemp({ zoneResp }, zoneNum) {
    let tempSum = 0;
    let tempCount = 0;
    if (zoneResp.data.data.length === 0 || zoneResp.data.timestamps.length === 0) {
      return "";
    } 
    else {
      for (let dataPoint = 0; dataPoint < zoneResp.data.data.length; dataPoint++) {
        if (zoneResp.data.data[dataPoint][zoneNum].mx <= 0) {
          // keep out of average
        } 
        else {
          tempSum = tempSum + zoneResp.data.data[dataPoint][zoneNum].mx;
          tempCount = tempCount + 1;
        }
      }
      if (tempSum <= 0 || tempCount === 0) {
        // temp data exists, but it's invalid
        return "";
      }
      return checkTempPositive(tempSum / tempCount);
    }
  }

  // Checks the date of the latest well data and determines if it is online or offline
  function isDateCurrent(Timestamp) {
    const currentDateTime = new Date();
    const dataTimestamp = new Date(Timestamp);
    const hourCutoff = 12; // usually set to 24
    if (Date.parse(currentDateTime) - Date.parse(dataTimestamp) <= hourCutoff * 3600000) {
      return false;
    } 
    else {
      return true;
    }
  }

  // Decides which table to load based on if user selects online or offline wells
  function tableLoader() {
    if (tableToLoad === "online") {
      return (
          <div>
          <RangeSelector ref={rangeSelectorRef} isLoading = {isLoading} range={range} setRange={(r) => setRange(r)} />
          {isLoading ? (
            <div className="spinner">
              <Spin size="large"/>
            </div>
          ) : (
            <div id="table-container">
              <Table
                className="styled-table"
                pagination={false}
                columns={onlineColumns}
                dataSource={onlineTableDataState}
                bordered
                size="middle"
                scroll={{ x: "calc(650px + 50%)", y: tableHeight }}
              />
              <p>*empty cells indicate no valid data found</p>
            </div>
          )}
        </div>
      );
    }
    else if (tableToLoad === "offline") {
      return (
        <div id="table-container">
          <Table
            loading={isLoading}
            className="styled-table"
            pagination={false}
            columns={offlineColumns}
            dataSource={offlineTableDataState}
            bordered
            size="middle"
            scroll={{ x: "calc(650px + 50%)", y: tableHeight }}
          />
        </div>
      );
    }
  }

  return (
    !isLoading && user?.pads.length == 0 ? (
      <div className="no-data-container">
        <p className="no-data-text">No data available for user.</p>
      </div>
    ) : 
    <div>
        <Button ref={onlineOfflineButtonsRef} className={buttonStyleOnlineOffline("online")} onClick={() => settableToLoad("online")}>Online Fibers {"("+onlineTableDataState.length+")"}</Button>
        <Button ref={onlineOfflineButtonsRef} className={buttonStyleOnlineOffline("offline")} onClick={() => settableToLoad("offline")}>Offline Fibers {"("+offlineTableDataState.length+")"}</Button>
      <div>
        { tableLoader() }
      </div>
    </div>
  );
}
export default DashboardTable;
