import React from "react";

import HeaderDropdown from '../HeaderDropdown';
import {Layout} from "antd";
import "./index.css";

const BasicHeader = props => {
  const {user,menuItems,title} = props;

  return <Layout.Header className="container" style={{zIndex:1, height:0}}> {/*set height to 0 to remove blue bar at top of page*/}
      <span>{title}</span><span className='right-side'>{props.children}
        <HeaderDropdown user={user} menuItems={menuItems} />
      </span>
    </Layout.Header>;
};

export default BasicHeader;