// I'm going to try and remember to use this so if I want to change formatting across the board I can just call this function

export const dateFormat = (d) => {
    return d ? d.toLocaleString("en-gb") : "No Date"
}

export const shortDate = (ts) => {
    if ( !ts ) return "No Date"

    const year = ts.getFullYear()
    const day = pad2(ts.getDate())
    const month =pad2( ts.getMonth() + 1)
    const hour = pad2(ts.getHours())
    const min = pad2(ts.getMinutes())

    return `${year}/${month}/${day} ${hour}:${min}`
}

const pad2 = v => String(v).padStart(2, "0") 