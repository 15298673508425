import "./tooltip.css";

import { useState, useEffect, useRef } from "react";

import { HexColorPicker } from "react-colorful";

import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";

import { 
    MinusOutlined, 
    BgColorsOutlined, 
    DownOutlined,
    UpOutlined,
    EyeOutlined, 
    EyeInvisibleOutlined,
    CaretDownOutlined,
    FieldTimeOutlined
} from "@ant-design/icons";

/*
The xData object comes from doubleClick
ChartWithOverlay ~line 160

It then gets added in TempVDepth ~180

This could be more consistent but it works for now

Gross, I can't get to the formatter from TempVDepth so I'll probably have to do it from the chart....?
*/ 


/* Funciton to calculate the average temps for a the depth limits of the main chart */
const calculateAverages = (depthTraces, depthLim) => {
    return depthTraces.map(trace => {
      const filteredPoints = trace.xData
        .map((x, index) => ({ depth: x, temp: trace.yData[index] }))
        .filter(point => point.depth >= depthLim[0] && point.depth <= (depthLim[1] + 1));
        
    //   console.log("filteredPoints", filteredPoints)
      const averageTemp = filteredPoints.reduce((acc, curr) => acc + curr.temp, 0) / filteredPoints.length;
      const maxTempPoint = filteredPoints.reduce((max, point) => (point.temp > max.temp ? point : max), filteredPoints[0]);
      const minTempPoint = filteredPoints.reduce((min, point) => (point.temp < min.temp ? point : min), filteredPoints[0]);
  
      return {
        key: trace.key,
        colour: trace.colour,
        averageTemp,
        start: depthLim[0],
        end: depthLim[1],
        maxTemp: maxTempPoint? maxTempPoint.temp : null,
        maxTempDepth: maxTempPoint? maxTempPoint.depth : null,
        minTemp: minTempPoint? minTempPoint.temp : null,
        minTempDepth: minTempPoint? minTempPoint.depth : null,
      };
    });
  };

export const ChartAverageSummary = ({ depthTraces, depthLim }) => {
    const [averageValues, setAverageValues] = useState([]);
    const [expandedTraceKey, setExpandedTraceKey] = useState(null); // Track which trace is expanded
    const initialExpandSet = useRef(false);

    useEffect(() => {
        if (depthLim && depthTraces.length > 0) {
            const averages = calculateAverages(depthTraces, depthLim);
            setAverageValues(averages);

            // Automatically expand the first trace if there's only one and it hasn't been set before
            if (averages.length === 1) {
                if (!expandedTraceKey || expandedTraceKey !== averages[0].key) {
                    setExpandedTraceKey(averages[0].key);
                    initialExpandSet.current = true; // Mark as set
                }
            }
        }
    }, [depthLim, depthTraces]);

    const toggleTraceDetails = (key) => {
        if (expandedTraceKey === key) {
            // If the same key is clicked again, collapse it
            setExpandedTraceKey(null);
        } else {
            // Expand the clicked key
            setExpandedTraceKey(key);
        }
    };

    return depthTraces.length > 0 ? (
        <div>
            <span>Average Temperature Info</span>
            {averageValues.map((value, index) => (
                // console.log("colour", value.colour ),
                <div className="summary-section" key={value.key}>
                    <div onClick={() => toggleTraceDetails(value.key)} style={{ cursor: 'pointer' }}>
                        <span className="summary-line" style={{color: getContrastTextColourAverages(value.colour)}}>
                            Trace: {adjustToBrowserTime(value.key)} <CaretDownOutlined />
                        </span>
                    </div>
                    {expandedTraceKey === value.key && (
                        <div>
                            <span className="summary-line" style={{color: getContrastTextColourAverages(value.colour)}}>
                                Start: {parseFloat(value.start.toFixed(0))}mKB - End: {parseFloat(value.end.toFixed(0))}mKB
                            </span>
                            <span className="summary-line" style={{color: getContrastTextColourAverages(value.colour)}}>
                                Average Temp: {parseFloat(value.averageTemp.toFixed(1))} °C
                            </span>
                            <span className="summary-line" style={{color: getContrastTextColourAverages(value.colour)}}>
                                Min Temp: {parseFloat(value.minTemp.toFixed(1))} °C @ {value.minTempDepth}mKB
                            </span>
                            <span className="summary-line" style={{color: getContrastTextColourAverages(value.colour)}}>
                                Max Temp: {parseFloat(value.maxTemp.toFixed(1))} °C @ {value.maxTempDepth}mKB
                            </span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    ) : null;
};

export const ChartSummary = ({
    data,
    xData,
    updateXData,
    showModal,
    tab
}) => {
    const updateDepthItem = (key, update, prop) => {
        const idx = xData.findIndex(t => t.key === key);

        switch(update) {
            case "colour":
                xData[idx].colour = prop;
                break;
            case "remove":
                xData.splice(idx, 1);
                break;
            case "visible":
                xData[idx].visible = prop;
                break;
            default:
                console.log("default")
        }

        updateXData && updateXData(xData)
    }

    return data ?  <div className="summary">
        { data.map(d => 
            <div className="summary-section" key={d.key}>
                <div className="summary-title">
                    <span>{d.key}</span>
                </div>
                { d.data.map(p => p.visible && <span key={p.key + d.x} className="summary-line" style={{color: p.colour}}>{adjustToBrowserTime(p.key)} {p.yLabel}</span> ) }
         </div>
        )}
        { xData?.map(d =>  
            <SummarySection key={d.key} data={d} updateDepthItem={updateDepthItem} showModal={showModal} tab={tab}/>
        )}

    </div> : null
}

const SummarySection = ({ data, updateDepthItem, showModal, tab }) => {
    const [ collapsed, setCollapsed ] = useState(false);
    const [ pickerVisible, setPickerVisible ] = useState(false);

    return (
        <div className="summary-section" style={{backgroundColor: data.colour, color: getContrastTextColor(data.colour)}}>
            <div className="summary-title">
                <span>{data.xLabel}</span>
                <div 
                    className="push-right" 
                    style={{backgroundColor: data.colour, color: getContrastTextColor(data.colour), position: 'relative'}}
                >   
                    {!data.pin && (tab === "TraceView" || tab === "loss") && <FieldTimeOutlined onClick={() => showModal(data.key)}/>}
                    <BgColorsOutlined onClick={() => setPickerVisible(e => !e)} />

                    { pickerVisible && (
                        <div style={{position: "absolute", top: '20px', left: '30px', zIndex: 1000, left: '-170px', down: '40px'}}>
                            <HexColorPicker color={data.colour} onChange={(c) => updateDepthItem(data.key, "colour", c)} />
                        </div>
                    )}

                    <MinusOutlined onClick={() => updateDepthItem(data.key, "remove")}/>
                    {data.visible ?
                        <EyeOutlined onClick={() => updateDepthItem(data.key, "visible", false)}/> :
                        <EyeInvisibleOutlined onClick={() => updateDepthItem(data.key, "visible", true)}/> 
                    }
                    {!collapsed ? 
                        <DownOutlined onClick={() => setCollapsed(true)}/> : 
                        <UpOutlined onClick={() => setCollapsed(false)}/> 
                    }
                </div>
            </div>
            {!collapsed && data.data.map(p => p.visible && <SummaryItem key={p.key + data.key} data={p} />)}
        </div>
    )
}


const SummaryItem = ({ data }) => {
    const [ pickerVisible, setPickerVisible ] = useState(false);

    const onDragDropped = (e) => {
        e.preventDefault()
        e.target.style['border-top'] = '';
        e.target.style['border-bottom'] = '';
    }

    const onDragOver = (e) => {
        e.preventDefault()
        e.stopPropagation();

        if (e.target.className !== "trace-selection-line") return;

        var bounding = e.target.getBoundingClientRect()
        var offset = bounding.y + (bounding.height/2);
        if ( e.clientY - offset > 0 ) {
            e.target.style['border-bottom'] = 'solid 4px blue';
            e.target.style['border-top'] = '';
            // setTarget({key: trace.key, after: true})
        } else {
            e.target.style['border-top'] = 'solid 4px blue';
            e.target.style['border-bottom'] = '';
            // setTarget({key: trace.key, after: false})
        }

    }

    const onDragLeave = (e) => {
        e.target.style['border-top'] = '';
        e.target.style['border-bottom'] = '';
    }

    const onDragEnd = (e) => {
        // onDrop(trace.key)
    }

    return <div
        className="summary-line" 
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDragDropped}
        onDragEnd={onDragEnd}
        draggable
        key={data.key}
        >
        <span key={data.key} style={{color: data.colour}}>{adjustToBrowserTime(data.key)} {data.yLabel}</span>
        <div className="push-right">
        </div>
    </div>
}

function getContrastTextColor(colour){
    
    function isHexColor(c) {
        return /^#([0-9A-F]{3}){1,2}$/i.test(c);
    }

    function namedToHex(named) {
        let ctx = document.createElement('canvas').getContext('2d');
        ctx.fillStyle = named;
        return ctx.fillStyle;
    }

    function getTextColour(hex) {
        hex = hex.replace("#", "");
        const r = parseInt(hex.substr(0,2),16);
        const g = parseInt(hex.substr(2,2),16);
        const b = parseInt(hex.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    if (isHexColor(colour)) {
        return getTextColour(colour)
    } else if (!colour){
        return "black"
    } 
    else return getTextColour(namedToHex(colour))
}

function getContrastTextColourAverages(colour){
    
    function isHexColor(c) {
        return /^#([0-9A-F]{3}){1,2}$/i.test(c);
    }

    function namedToHex(named) {
        let ctx = document.createElement('canvas').getContext('2d');
        ctx.fillStyle = named;
        return ctx.fillStyle;
    }

    function getTextColour(hex) {
        hex = hex.replace("#", "");
        const r = parseInt(hex.substr(0,2),16);
        const g = parseInt(hex.substr(2,2),16);
        const b = parseInt(hex.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 60) ? colour : 'white';
    }

    if (isHexColor(colour)) {
        return getTextColour(colour)
    } else if (!colour){
        return "black"
    } 
    else return getTextColour(namedToHex(colour))
}