import { useEffect, useState } from "react";

/*
This component is used to render the schema bar for the well schema chart. it will look the same as the well schema the user 
created in the well schema tab.
*/

const SchemaBar = ({ x, y, width, sections, onSectionClick }) => {
        
    const minDepth = sections.length ? sections[0].minDepth : 0;
    const maxDepth = sections.length ? sections[0].maxDepth : 0;

    const [hoveredSection, setHoveredSection] = useState(null);

    // Identify unique layerName values
    const uniqueLayerNames = [...new Set(sections.map(section => section.layerName))];

    // Total depth range for calculating relative X position and width
    const totalDepthRange = maxDepth - minDepth;

    // Calculate relative X position and width based on section start and end values
    const calculateRelativeXAndWidth = (start, end) => {
        if (start > maxDepth || end < minDepth) return null;  // Check if outside bounds
    
        const relativeStart = Math.max(0, ((start - minDepth) / totalDepthRange) * width); 
        const relativeEnd = Math.min(width, ((end - minDepth) / totalDepthRange) * width); 
        
        return {
            x: relativeStart,
            width: relativeEnd - relativeStart
        };
    };

    // Function to handle click events on the section
    const handleSectionClick = (section) => {
        if (section.sectionType === "section") {
            onSectionClick([section.start, section.end]);
        }
    };

    // This function is used to determine if the color is dark or light to determine the text color
    function isColorDark(hexColor) {

        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance < 0.5;
    }

    // Sort the sections based on their width (from widest to shortest)
    const sortedSections = sections.map(section => {
        const relativeValues = calculateRelativeXAndWidth(section.start, section.end);
        return {
            ...section,
            width: relativeValues ? relativeValues.width : 0
        };
    }).sort((a, b) => b.width - a.width);

    return (
        <>
            {/* Render the default grey bar for the entire length */}
            <rect x={x} y={y} width={width} height="15" fill="lightgrey" />
    
            {/* Render unique layerNames to the left of the bar */}
            {uniqueLayerNames.map((layerName, index) => (
                <text 
                    key={index}
                    x={x - 5}  // adjust this value to position the text to the left of the bar
                    y={y + 7 + (index * 20)}  // adjust vertical position based on index
                    textAnchor="end" 
                    dominantBaseline="middle"
                    fill="black"
                >
                    {layerName}
                </text>
            ))}
    
            {/* Render sorted sections */}
            {sortedSections.map((section, index) => {
                if (section.sectionType !== "section") return null;  // Skip non-section types
    
                const relativeValues = calculateRelativeXAndWidth(section.start, section.end);
                if (!relativeValues) return null;  // Skip rendering if outside bounds
    
                const { x: relativeX, width: relativeWidth } = relativeValues;
    
                return (
                    <rect 
                        key={index} 
                        x={x + relativeX} 
                        y={y} 
                        width={relativeWidth} 
                        height="15" 
                        fill={section.color} 
                        onMouseEnter={() => setHoveredSection(section)} 
                        onMouseLeave={() => setHoveredSection(null)}
                        onClick={() => handleSectionClick(section)}
                        style={{ cursor: 'pointer' }}
                        overflow="hidden"
                    />
                );
            })}
    
            {/* Render points */}
            {sections.map((section, index) => {
                if (section.sectionType !== "point") return null;  // Skip non-point types
    
                const relativeValues = calculateRelativeXAndWidth(section.start, section.start);
                if (!relativeValues) return null;  // Skip rendering if outside bounds
    
                const lineY = y + 15;  
                const lineEnd = y;    
                return (
                    <>
                      {/* Original line for visual display */}
                      <line 
                          key={`visual-${index}`}
                          x1={x + relativeValues.x + relativeValues.width}
                          y1={lineY}
                          x2={x + relativeValues.x + relativeValues.width}
                          y2={lineEnd}
                        //   stroke={'black'}
                          stroke={section.color}
                          strokeWidth="3"
                      />
                      {/* Invisible wider line for increased hit zone - label hover */}
                      <line 
                          key={`hitzone-${index}`}
                          x1={x + relativeValues.x + relativeValues.width}
                          y1={lineY}
                          x2={x + relativeValues.x + relativeValues.width}
                          y2={lineEnd}
                          stroke={'rgba(0,0,0,0)'} 
                          strokeWidth="15" 
                          onMouseEnter={() => setHoveredSection(section)}
                          onMouseLeave={() => setHoveredSection(null)}
                      />
                    </>
                  );
            })}
            
            {/* Tooltip for hovered section */}
            {hoveredSection && (
                <text 
                    x={x + calculateRelativeXAndWidth(hoveredSection.start, hoveredSection.end).x + calculateRelativeXAndWidth(hoveredSection.start, hoveredSection.end).width / 2} 
                    y={y + 15 / 2}  
                    textAnchor="middle" 
                    dominantBaseline="middle"
                    fill={isColorDark(hoveredSection.color) && hoveredSection.sectionType === "section" ? 'white' : 'black'}
                    style={{ pointerEvents: "none" }}
                >
                    {hoveredSection.label}
                </text>
            )}
        </>
    );
    
    // Original code - leave for now in case of bugs... 
    
    // return (
    //     <>
    //         {/* Render the default grey bar for the entire length */}
    //         <rect x={x} y={y} width={width} height="15" fill="lightgrey" />

    //         {/* Render unique layerNames to the left of the bar */}
    //         {uniqueLayerNames.map((layerName, index) => (
    //             <text 
    //                 key={index}
    //                 x={x - 5}  // adjust this value to position the text to the left of the bar
    //                 y={y + 7 +(index * 20)}  // adjust vertical position based on index
    //                 textAnchor="end" 
    //                 dominantBaseline="middle"
    //                 fill="black"
    //             >
    //                 {layerName}
    //             </text>
    //         ))}

    //         {/* Render sections of type 'section' first, then 'points'*/}
    //         {sections.map((section, index) => {
    //             if (section.sectionType !== "section") return null;  // Skip non-section types

    //             const relativeValues = calculateRelativeXAndWidth(section.start, section.end);
    //             if (!relativeValues) return null;  // Skip rendering if outside bounds

    //             const { x: relativeX, width: relativeWidth } = relativeValues;

    //             return (
    //                 <rect 
    //                     key={index} 
    //                     x={x + relativeX} 
    //                     y={y} 
    //                     width={relativeWidth} 
    //                     height="15" 
    //                     fill={section.color} 
    //                     onMouseEnter={() => setHoveredSection(section)} 
    //                     onMouseLeave={() => setHoveredSection(null)}
    //                     onClick={() => handleSectionClick(section)}
    //                     style={{ cursor: 'pointer' }}
    //                     overflow="hidden"
    //                 />
    //             );
    //         })}

    //         {/* Render sections of type 'point' */}
    //         {sections.map((section, index) => {
    //             if (section.sectionType !== "point") return null;  // Skip non-point types

    //             const relativeValues = calculateRelativeXAndWidth(section.start, section.start);
    //             if (!relativeValues) return null;  // Skip rendering if outside bounds - should only render on grey bar

    //             const lineY = y + 15;  
    //             const lineEnd = y;    
    //             return (
    //                 <line 
    //                     key={index}
    //                     x1={x + relativeValues.x + relativeValues.width}
    //                     y1={lineY}
    //                     x2={x + relativeValues.x + relativeValues.width}
    //                     y2={lineEnd}
    //                     stroke={'black'}
    //                     strokeWidth="3"
    //                     onMouseEnter={() => setHoveredSection(section)}
    //                     onMouseLeave={() => setHoveredSection(null)}
    //                 />
    //             );
    //         })}

    //         {/* Tooltip */}
    //         {hoveredSection && (
    //             sections.map((section, index) => {
    //                 const relativeValues = calculateRelativeXAndWidth(section.start, section.end);
    //                 if (!relativeValues) return null; // Skip rendering if outside bounds

    //                 if (hoveredSection === section) {
    //                     let textColor;
    //                     if (section.sectionType === "point") {
    //                         textColor = 'black';
    //                     } else if (section.sectionType === "section") {
    //                         textColor = isColorDark(section.color) ? 'white' : 'black';
    //                     }

    //                     let textX = x + relativeValues.x + relativeValues.width / 2; // default position

    //                     if (section.sectionType === "point") {
    //                         const pointRelativeValues = calculateRelativeXAndWidth(section.start, section.start);
    //                         if (!pointRelativeValues) return null; // Additional check for point type

    //                         const approxTextWidth = hoveredSection.label.length * 6; // assuming 6px per character as a rough estimate

    //                         if (section.start - minDepth < 100) {
    //                             // If the Point of Interest is within 100 of the section start
    //                             textX = x + pointRelativeValues.x + approxTextWidth / 2;
    //                         } else if (maxDepth - section.end < 100) {
    //                             // If the Point of Interest is within 100 of the section end
    //                             textX = (x + pointRelativeValues.x + pointRelativeValues.width) - approxTextWidth / 2;
    //                         }
    //                     }

    //                     return (
    //                         <text 
    //                             key={index}
    //                             x={textX} 
    //                             y={y + 15 / 2}  
    //                             textAnchor="middle" 
    //                             dominantBaseline="middle"
    //                             fill={textColor}
    //                             style={{ pointerEvents: "none" }}
    //                         >
    //                             {hoveredSection.label}
    //                         </text>
    //                     );
    //                 }
    //                 return null;
    //             })
    //         )}

    //     </>
    // );
};

//container will render one schema bar for each layer set up by the user
export const SchemaBarContainer = ({ 
    totalHeight,
    schemaBarWidth,
    schemaSections,
    margins,
    onSectionClick    
    }) => {
    //container for the schema bars, displays however many are stored in DB
    return (
        <div style={{ height: totalHeight }}>
            <svg overflow="visible" height={totalHeight} >
                {schemaSections.filter(sectionWrapper => sectionWrapper.isEnabled).map((sectionWrapper, idx) => (
                    <SchemaBar 
                        key={idx}
                        x={margins.left} 
                        y={5 + idx * 20}
                        width={schemaBarWidth - margins.left - margins.right} 
                        sections={sectionWrapper.sections}
                        layerName={sectionWrapper.layerName}
                        onSectionClick={onSectionClick}
                    />
                ))}
            </svg>
        </div>
    );
};

export default SchemaBar;