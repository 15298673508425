import BasicLayout from './layout/BasicLayout/';
import Login from './pages/Login';

import { AuthContext } from "react-oauth2-code-pkce";
import { useContext, useEffect } from "react";

import { Spin } from 'antd';
import { UserAPI, UserContext } from './api/user';

import './App.css';

const App = () => {
  const { 
    token, 
    logOut, 
    error, 
    loginInProgress,
    login
  } = useContext(AuthContext)

  const userAPI = UserAPI();

  useEffect(() => {
    if (token) {
      userAPI.sendRequest()
      return userAPI.cancel
    }
  }, [token]);

  // Stops the webpage from flickering while logging in
  if (loginInProgress) return null;

  if (!token) login();

  /*
  if (error) {
    return (
      <>
        <div style={{ color: 'red' }}>
          An error occurred during authentication: {error}
        </div>
        <button onClick={() => logOut()}>Logout</button>
      </>
    )
  }

  // if (!token) return <Login />;
  */

  if (!userAPI.data) return <div className="spinner"><Spin /></div>; 

  return <UserContext.Provider  value={userAPI.data}>
    <BasicLayout />;
  </UserContext.Provider>
}

export default App;