import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { WellContext } from "../../../api/well";

import { SelectedList } from "../Selectors/SelectedList";
import { ChartSummary } from "../Tooltip/Summary";
import { DepthTempChart } from "../DepthTempChart";
import { DepthSelector, TimeSelectorTFO } from "../Selectors";
import { useTempSelector, useCursorData } from "../Hooks";
import { Modal, Input, Button, List, Space, message } from "antd";

import dayjs from "dayjs";

import { Slider, InputNumber } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import convertTrace from "../convert";
import { depthLimits } from "../../../utils/depth";
import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";
import { UserContext } from "../../../api/user";
import CSVDownload from "../../Misc/CSVDownload";

import { SchemaBarContainer } from "../SchemaBar";
import { transformAPISchemaSections } from "../../../utils/transformAPISchemaSections";
import { EditOutlined, LineChartOutlined, BgColorsOutlined} from "@ant-design/icons";
import { Tooltip } from "antd";
import { plotSection, plotPOI } from "../../../utils/plotSchemaSections";
import { useDBWellApi } from "../../../api/DBWellApi";



var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "300px 1fr 150px",
      gridTemplateRows: "250px 1fr 250px",
      height: "90vh",
    },
    leftColumn: {
      gridColumn: "1 / 2",
      gridRow: "1 / 4",
      backgroundColor: "#e6e6e6",
      textShadow: "0 0 0px rgba(255,255,255, 1)",
      fontWeight: "bold"
    },
    middleColumnTop: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
    },
    chart: {
      gridColumn: "2 / 3",
      gridRow: "2 / 3",
      backgroundColor: "grey",
    },
    schemaBarContainer: {
        gridColumn: "2 / 3",
        gridRow: "3 / 4",
        backgroundColor: "#f2f2f2",
        position: 'relative',
        zIndex: 2,
        overflow: 'visible'
    },
    depthTrack: {
      gridColumn: "2 / 3",
      gridRow: "3 / 4",
      backgroundColor: "lightgrey",
    },
    tempTrack: {
      gridColumn: "3 / 4",
      gridRow: "2 / 3", 
      backgroundColor: "lightgrey", },
};
      
const margins = {
    top: 20,
    bottom: 70,
    left: 60,
    right: 60 
}

let traceMap = new Map();
let traceMapAll = new Map();

const TFOView = ({ onEditClick, wellSchema, activeTab
}) => {
    const { fetchTrace, queryTimestamps, fetchZone, fetchMinMax } = useContext(WellContext);
    const wellAPI = useContext(WellContext);
    const user = useContext(UserContext);

    const fetch = fetchTrace.copy();
    const fetchAllTraces = fetchTrace.copy();
    const query = queryTimestamps.copy();
    const fetchMinMaxTFO = fetchMinMax.copy();

    const [ xLines, setXLines ] = useState([]);

    const [ traces, setTraces ] = useState([]);
    const [ selectedTraces, setSelectedTraces ] = useState([]);
    const [ allSelectedTraces, setAllSelectedTraces ] = useState([]);

    const [ cursorData, setCursorData ] = useCursorData(traces);

    const [ tempLim, setTempLim, TempSelector ] = useTempSelector( traces, margins );
    const [ depthLim, setDepthLim ] = useState();

    const [ qty, setQty ] = useState(10);
    const [ timeMinMaxData, setTimeMinMaxData ] = useState([]);
    const [ timeRange, setTimeRange] = useState();

    const [ selectedTimeRange, setSelectedTimeRange ] = useState();

    const [ loading, setLoading ] = useState(false);

    const [timeSelectorTempLims, settimeSelectorTempLims] = useState();

    const [depthSelectorTempLims, setdepthSelectorTempLims] = useState([]);

    const [ loadedTimeRanges, setLoadedTimeRanges ] = useState([]);

    const [dateLim, setDateLim] = useState();

    const [isLoading, setIsLoading] = useState(false);

    //Schema sections states
    const [ schemaBarWidth, setSchemaBarWidth ] = useState();

    const [ schemaSections, setSchemaSections ] = useState(wellSchema);

    const [ showXLines, setShowXLines ] = useState(true);

    const [ toggledLayers, setToggledLayers ] = useState({}); //used for icon color change


    

    useEffect(() => {
        if (traces.length === 0) return;
        let latestTrace = traces[traces.length-1]
        if (!depthLim) {
            setDepthLim([latestTrace.xData[0], latestTrace.xData[latestTrace.xData.length-1]])
        } 
    },[traces.length])


    /* Schemasections effects & functions
    ##############################################################################################################*/

    //function to provide the schema bar with the appropriate width if the browser changes size
    function handleWidthUpdate(width) {
        setSchemaBarWidth(width);
    }


    useEffect(() => {
        wellAPI.fetchDepthOfInterest.sendRequest();
    }, []);

    useEffect(() => {
        //initialise schemaSections state from DB when page is loaded
        if (wellAPI.fetchDepthOfInterest.data && depthLim) {
            setSchemaSections(transformAPISchemaSections(wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim));
        }
    }, [wellAPI.fetchDepthOfInterest.data]);

    // Update the const once the API call has been made to prevent overrriding on depthLim update
    const initialScheamaSectionsAPIcall = useRef(false);

    //updating the maxDepth and minDepth when the depthLim is updated (true flag)
    useEffect(() => {
        if(!depthLim) return;
        setSchemaSections(transformAPISchemaSections(schemaSections, depthLim, true));
    }, [ depthLim ]);

    //updating the schemaSections state from DB when page is loaded and depthLim is set
    useEffect(() => {
        if (initialScheamaSectionsAPIcall.current || !depthLim) {
            return;
        }

        if (wellAPI.fetchDepthOfInterest.data && depthLim) {
            setSchemaSections(transformAPISchemaSections(wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim));
            initialScheamaSectionsAPIcall.current = true; //set to true so that API call is not triggered on every depthLim update
        }
    }, [ depthLim ]);

    //when wellSchema prop is updated in parent component, update schemaSections state
    useEffect(() => {
        setSchemaSections(wellSchema);
    }, [ wellSchema ]);

    // used for calculating the size of the schema bar
    const enabledSectionsCount = schemaSections.filter(sectionWrapper => sectionWrapper.isEnabled).length;
    const totalHeight = enabledSectionsCount * 20 + 10; // Adjusted to account for enabled sections

    //function to zoom in on a particular section when clicked on
    const onSectionClick = (depths) => {
        setDepthLim(depths);
    }

    // Function to handle the POI line toggling
    const handleToggleLines = () => {
        setShowXLines(!showXLines); // This will toggle between true and false
    };

    //Layers are the checkboxes for the well layers - can be selected to show/hide schema bars and plot points of interest
    //Keep in here for now for simplicity - Vlad
    const Layers = ({ schemaSections, toggleLayer}) => {
        return (
            <div>
                {schemaSections.map((sectionWrapper, idx) => (
                    <div key={idx}>
                        <input 
                            type="checkbox" 
                            checked={sectionWrapper.isEnabled} 
                            onChange={() => toggleLayer(idx)}
                        />
                        <label>{sectionWrapper.layerName}</label>
                        <Tooltip title='Plot Points of Interest' >
                            <LineChartOutlined 
                                onClick={() => plotPOI(sectionWrapper.sections, idx, xLines, setXLines, setToggledLayers)} 
                                style={{ fontSize: '18px', 
                                        paddingLeft: '5px', 
                                        color: toggledLayers[idx] ? 'white' : 'black' //change color for clickd layer
                                }}
                                />
                        </Tooltip>
                        <Tooltip title='Plot Sections' >
                            <BgColorsOutlined
                                onClick={() => plotSection(sectionWrapper.sections, idx, xLines, setXLines)}
                                style={{ fontSize: '18px', paddingLeft: '5px'  }} 
                            />
                        </Tooltip>
                    </div>
                ))}
            </div>
        );
    };

    //toggleLayer is used to toggle the schema layers on/off from view
    const toggleLayer = (index) => {
        const updatedSections = [...schemaSections];
        updatedSections[index].isEnabled = !updatedSections[index].isEnabled;
        setSchemaSections(updatedSections);
    };

    //##############################################################################################################

    useEffect(() => {
        if (query.data) {

            fetchZone.sendRequest({start: query.data[0], end: query.data[query.data.length - 1]})

            return () => {
                fetchZone.cancel();
            }
        }
    }, [query.data])

    useEffect(() => {
        if (timeRange) {
            setLoading(true)
            query.sendRequest({start: timeRange[0].toISOString(), end: timeRange[1].toISOString()})
            return () => {
                setLoading(false)
                query.cancel()
            }
        }
    }, [timeRange])

    useEffect(() => {
        if (timeRange) {
            fetchMinMaxTFO.sendRequest({start: timeRange[0].toISOString(), end: timeRange[1].toISOString()})
            return fetchMinMaxTFO.cancel
        }
    }, [timeRange])

    useEffect(() => {
        if (query.data && selectedTimeRange) {
            
            const start = dayjs(selectedTimeRange[0]);
            const end = dayjs(selectedTimeRange[1]);

            const times = query.data.filter(t => dayjs(t).isBetween(start, end));

            setAllSelectedTraces(times);

            if (!times.length) return; 

            let newTraces = [];

            const interval = (times.length - 1)/(qty - 1);

            if (qty === 1) {
                newTraces.push(times[0])
            } else if (qty >= times.length) {
                newTraces = times;
            } else {
                for (let i = 0; i < qty; i++) {
                    const index = Math.round(i*interval);
                    newTraces.push(times[index])
                }
            };

            setSelectedTraces(newTraces);
        }
    }, [query.data, qty, selectedTimeRange])


    useEffect(() => {
        if (selectedTraces) {
            const traces = selectedTraces.filter(t => traceMap.has(t)).map(t => traceMap.get(t));

            if (traces.length === 0) return;

            for (let i = 0; i < traces.length; i++) {
                const alpha = (i + 1) / traces.length * 0.8 + 0.2;
    
                // Check if the trace is the last one
                if (i === traces.length - 1) {
                    // Set a darker shade of red for the last trace
                    traces[i].colour = `rgba(139, 0, 0, ${alpha})`; // Dark red color
                } else {
                    traces[i].colour = `rgba(254, ${200 - 200 * (i + 1) / traces.length}, 0, ${alpha}`;
                }
    
                traces[i].width = 1.0;
            }

            traces[traces.length - 1].width = 1.5;

            setTraces(traces)
        }
    }, [loading, selectedTraces])

    useEffect(() => {
        const newTraces = selectedTraces.filter(t => !traceMap.has(t));

        if (newTraces.length) {
            setLoading(true)
            
            fetch.sendRequest({dtype:"temp", timestamp: newTraces.filter(t => !traceMap.has(t))})
            return () => {
                setLoading(false)
                fetch.cancel()
            }
        }
    }, [loading, selectedTraces])

    useEffect(() => {
        
        if (fetch.data && Array.isArray(fetch.data)) {
            fetch.data.forEach(t => traceMap.set(t.timestamp, convertTrace(t)))
            setLoading(false);
        }
    }, [fetch.data])

    /*#############################################################################################################    
      Fetching all traces in the selected time range for CSV download */

    //States just for fetching all traces for CSV download functionality
    const [csvTraces, setCsvTraces] = useState([]);
    const [batchIndex, setBatchIndex] = useState(0);
    const [csvDataLoaded, setCsvDataLoaded] = useState(false);
    const batchSize = 200; // anything abouve ~230 will cause the server to crash or return an error?

    useEffect(() => {
        const maxTraces = 1000;
        const startIndex = Math.max(0, allSelectedTraces.length - maxTraces);
        const limitedTraces = allSelectedTraces.slice(startIndex);
        
        const fetchBatch = () => {
    
            if (batchIndex * batchSize >= limitedTraces.length) {
                
                // Once all batches have been fetched, indicate that CSV data is fully loaded
                setCsvDataLoaded(true);
                return;
            }
            
            // Calculate the start and end indices for the current batch
            const start = batchIndex * batchSize;
            const end = Math.min(start + batchSize, limitedTraces.length);
            const batchTraces = limitedTraces.slice(start, end);
            
            // If there are traces to fetch in the current batch
            if (batchTraces.length) {
                try {
                    fetchAllTraces.sendRequest({ dtype: "temp", timestamp: batchTraces });
                    // No need to set csvDataLoaded here since it's not done yet
                } catch (error) {
                    console.error('Failed to fetch batch:', error);
                }
            }
        };
        
        // Initialize or re-initialize the fetch process
        setCsvDataLoaded(false); // Ensure loading starts as false when a new sequence begins
        fetchBatch();
    }, [batchIndex, allSelectedTraces]); // Correct dependencies
    
    useEffect(() => {
        if (!fetchAllTraces.data) return;
        
        if (fetchAllTraces.data & Array.isArray(fetchAllTraces.data)) {
            fetchAllTraces.data.forEach(t => traceMapAll.set(t.timestamp, convertTrace(t)));
            // Instead of setLoading(false), data loading completion is handled by setCsvDataLoaded in the fetchBatch logic
        }
        
        setCsvTraces(prevData => [...prevData, ...fetchAllTraces.data]);
        setBatchIndex(prevIndex => prevIndex + 1);
    }, [fetchAllTraces.data]);
    
    useEffect(() => {
        // Reset returnData and batchIndex when allSelectedTraces changes significantly - basically on an all new fetch sequence
        setCsvTraces([]);
        setBatchIndex(0);
    }, [allSelectedTraces]);

    //helper function to transform the data for CsvDownload expected format  
    function transformData(dataArray) {
        return dataArray.map(item => {
            const xData = Array.from({length: item.span + 1}, (_, index) => item.start + index * item.step);
            return {
                key: item.timestamp,
                xData: xData,
                yData: item.data,
            };
        });
    }

    /*#############################################################################################################*/


    useEffect(() => {
        if (fetchMinMaxTFO.data) {
            let minmax = fetchMinMaxTFO.data;
        
            setTimeMinMaxData(
                [{
                    key: "well_min",
                    xData: minmax.map(e => e.timestamp),
                    yData: minmax.map(e => e.min),
                    colour: "blue"
                },
                {
                    key: "well_max",
                    xData: minmax.map(e => e.timestamp),
                    yData: minmax.map(e => e.max),
                    colour: "red"
                }]
            )
        }
    }, [fetchMinMaxTFO.data])

    useEffect(() => {
        if (queryTimestamps.data) {
            setDateLim([
                dayjs(queryTimestamps.data[0]),
                dayjs(queryTimestamps.data[queryTimestamps.data.length - 1]),
            ]);
        }
    }, [queryTimestamps.data]);

    // Set the timeSelectorTempLims to always always have all traces fully in view within time section
    useEffect(() => {
        if (!traces) return;
        if (traces?.length === 0) return;
        if (!dateLim) return;
        let yIndexArr = [];

        let minValue = Infinity;
        let maxValue = -Infinity;
        let data = fetchMinMaxTFO.data;

        for(let i = 0; i < data.length; i++) {
            if(data[i].min < minValue) {
                minValue = data[i].min;
            }
            if(data[i].max > maxValue) {
                maxValue = data[i].max;
            }
        }

        if (minValue < -20) minValue = -20;

        settimeSelectorTempLims([minValue * 0.995, maxValue * 1.005]);
        
    }, [queryTimestamps, traces, dateLim]);

    // Set the templim to always always have all traces fully in view within TempVDepth depth section
    useEffect(() => {
        if(!traces) return;
        if(traces.length === 0) return;
        if (!depthLim) return;
        let yIndexArr = [];
        for (let i = 0; i < traces.length; i++) {
            let xDataArr = traces[i]["xData"];
            let yDataArr = traces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(depthLim[0]));
            let endIndex = (xDataArr.indexOf(Math.round(depthLim[1])) === -1) ? ((traces[0]?.xData?.length-1)) : (xDataArr.indexOf(Math.round(depthLim[1])));
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
   
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
        
        if (minValue < -20) minValue = -20;

        setTempLim([minValue*0.995, maxValue*1.005])
        if (depthSelectorTempLims.length === 0){
            setdepthSelectorTempLims([minValue, maxValue])
        }

    }, [depthLim, traces, timeSelectorTempLims]); 

    const [displayQty, setDisplayQty] = useState(10);
    const [debounceTimeout, setDebounceTimeout] = useState(null); // Timeout reference

    const onSliderChange = useCallback((newValue) => {
        setDisplayQty(newValue); // Update display quantity immediately
    
        // Clear the previous timeout, if any
        if (debounceTimeout) clearTimeout(debounceTimeout);
    
        // Set a new timeout for the actual operation
        const newTimeout = setTimeout(() => {
          setQty(newValue); // Update the actual quantity after the debounce period
        }, 400); // Debounce delay set to 400ms
    
        // Store the new timeout in state
        setDebounceTimeout(newTimeout);
    }, [debounceTimeout]);

    const onTimeRangeUpdate = useCallback((range) => {
        setTimeRange(range)
    }, [])

    const onTimeBrush = useCallback((range) => {
        setSelectedTimeRange(range)
    }, [])



    const onDepthBrush = useCallback((depthRange) => {
        // only update if different
        setDepthLim(existing => { 
            for (let i in existing) {
                if (existing[i] !== depthRange[i]) {
                    return depthRange
                }
            }
            return existing
        })
    }, []);

    const selectedZone = useMemo(() => {
        if (!wellAPI.fetchZone.data) return;

        if (fetchZone.data && depthLim) {
            let maxDepth = Math.max(fetchZone.data[1][0][0].e, depthLim[1]); // Set maxDepth to the larger of the two values
            let minDepth = depthLim[0];
            
            setDepthLim([minDepth, maxDepth]); // Update depthLim with new maxDepth
        }



        let timestamps = wellAPI.fetchZone.data[0]
        let zoneInfo = []

        for (let z = 0; z<=wellAPI.fetchZone.data[1].length; z++) {
            if (!wellAPI.fetchZone.data[1][z]) continue;
            zoneInfo.push(wellAPI.fetchZone.data[1][z][wellAPI.fetchZone.data[1][z].length-1])
        }
        
        let zonebarData = {
            time: timestamps[timestamps.length-1],
            zones: zoneInfo
        }
        return zonebarData
    }, [wellAPI.fetchZone.data, traces]);
    
    const depthExtents = useMemo(()=>{
        const depthExtents = depthLimits(traces[traces.length-1]);
        return depthExtents
    },[traces])

    const tempExtents = useMemo(() => {
        const tempLims = timeSelectorTempLims
        ? [
              Math.round(timeSelectorTempLims[0]),
              Math.round(timeSelectorTempLims[1]),
          ]
        : null
        return tempLims
    },[timeSelectorTempLims])

    //saving TFO Events section.
    //=========================================================================
    // Functions to handle saving savedEvents


    const [ savedViews, setSavedViews ] = useState([]);
    const { getSavedViews, addNewSavedView, deleteSavedView, updateSavedView } = useDBWellApi();
    const [newLabel, setNewLabel] = useState("");
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [listModalVisible, setListModalVisible] = useState(false);
    const [updateViewModalVisible, setUpdateViewModalVisible] = useState(false);
    const [loadedEvent, setLoadedEvent] = useState(null);


    const createNewSavedViewObject = (label) => {
        // Dynamically build the traces part
        const dynamicTraces = traces.map(trace => ({
          'color': trace.colour, 
          'key': trace.key, 
          'pin': trace.pin, 
          'visible': trace.visible,
          'width': Math.ceil(trace.width),
        }));
      
        // Dynamically build the xLines part
        const dynamicXLines = xLines.map(xLine => ({
          'x': xLine.x, 
          'xLabel': xLine.xLabel, 
          'pin': xLine.pin, 
          'visible': true,
          'width': 3,
          'colour': xLine.colour, 
          'data': xLine.data.map(dataItem => ({
            'color': dataItem.colour,
            'key': dataItem.key,
            'visible': dataItem.visible,
            'width': 3,
            'y': dataItem.y,
            'yLabel': dataItem.yLabel,
          })),
        }));
      
        // Construct the new event object with dynamic parts where needed
        return {
          'chart_type': "tfo",
          'label': label,
          'email': user.email,
          'traces': dynamicTraces,
          'xlines': dynamicXLines,
          'public': true,
          'timeselector': {
            'tfoTimeRange': {
                'startTimeRange': new Date(timeRange[0].$d).toISOString(),
                'endTimeRange': new Date(timeRange[1].$d).toISOString()
            },
            'tfoBrushTimeRange': {
              'startTimeRange': new Date(selectedTimeRange[0]).toISOString(),
              'endTimeRange': new Date(selectedTimeRange[1]).toISOString()
            },
            'qty': 2
          },
          'depthselector': {
            'depthSelectorTempLim': {
              'minTempLim': Math.floor(depthSelectorTempLims[0]),
              'maxTempLim': Math.ceil(depthSelectorTempLims[1])
            },
            'depthLim': {
                'minDepthLim': Math.floor(depthLim[0]),
                'maxDepthLim': Math.ceil(depthLim[1])
            },
            'tempLim': {
              'minTempLim': Math.floor(tempLim[0]),
              'maxTempLim': Math.ceil(tempLim[1])  
            }
          }
        };
      };

      const createUpdatedViewObject = (newLabel) => {
      
        // Dynamically build the traces part
        const dynamicTraces = traces.map(trace => ({
          color: trace.colour,
          key: trace.key,
          pin: trace.pin,
          visible: trace.visible,
          width: Math.ceil(trace.width),
        }));
      
        // Dynamically build the xLines part
        const dynamicXLines = xLines.map(xLine => ({
          x: xLine.x,
          xLabel: xLine.xLabel,
          pin: xLine.pin,
          colour: xLine.colour,
          visible: true,
          width: 3,
          data: xLine.data.map(dataItem => ({
            color: dataItem.colour,
            key: dataItem.key,
            visible: dataItem.visible,
            width: 3,
            y: dataItem.y,
            yLabel: dataItem.yLabel,
          })),
        }));
      
        // Extract the timeselector and depthselector parts similarly
        const timeselector = {
          tfoTimeRange: {
            startTimeRange: new Date(timeRange[0].$d).toISOString(),
            endTimeRange: new Date(timeRange[1].$d).toISOString(),
          },
          tfoBrushTimeRange: {
            startTimeRange: new Date(selectedTimeRange[0]).toISOString(),
            endTimeRange: new Date(selectedTimeRange[1]).toISOString(),
          },
          qty: 2, 
        };
      
        const depthselector = {
          depthSelectorTempLim: {
            minTempLim: Math.floor(depthSelectorTempLims[0]),
            maxTempLim: Math.ceil(depthSelectorTempLims[1]),
          },
          depthLim: {
            minDepthLim: Math.floor(depthLim[0]),
            maxDepthLim: Math.ceil(depthLim[1]),
          },
          tempLim: {
            minTempLim: Math.floor(tempLim[0]),
            maxTempLim: Math.ceil(tempLim[1]),
          },
        };
      
        // Constructing the updated view object
        return {
          label: newLabel, // New label used here
          view_id: loadedEvent.view_id, 
          user_id: loadedEvent.user_id, // Static user_id as per example
          view_params: {
            chart_type: "tfo",
            email: user.email, 
            label: newLabel, // Reusing newLabel here for consistency
            traces: dynamicTraces,
            xlines: dynamicXLines,
            timeselector, // Direct assignment from the constructed variable
            depthselector, // Direct assignment from the constructed variable
          },
          well_id: loadedEvent.well_id,
        };
      };
      
    
      
    useEffect(() => {
        const fetchSavedViews = async () => {
            try {
                const response = await getSavedViews({ site: wellAPI.siteName, well: wellAPI.wellName });
                if (response?.data) {
                    handleTFOEventGetResponse(response?.data);
                }
            } catch (error) {
                console.error('Failed to fetch saved views:', error);
            }
        };
    
        fetchSavedViews();
    }, []);

    const handleTFOEventGetResponse = (response) => {
        if (!response || !Array.isArray(response)) return;
    
        let newEvents = response.map(resp => {
            // Convert the version date from UTC to local time
            const versionLocal = new Date(resp.version).toLocaleString();
    
            return {
                view_id: resp.view_id,
                chart_type: resp.chart_type,
                user_id: resp.user_id,
                label: resp.label,
                email: resp.view_params.email,
                version: versionLocal, // Use the locally converted version date
                traces: resp.view_params.traces,
                xlines: resp.view_params.xlines,
                timeselector: resp.view_params.timeselector,
                depthselector: resp.view_params.depthselector,
                well_id: resp.view_params.well_id,
            };
        });
    
        setSavedViews(newEvents);
    };


    const handleUpdateButtonClick = () => {
        setNewLabel(loadedEvent.label); // Initialize input with current label
        setUpdateViewModalVisible(true); // Show the modal
    };
      

      const handleUpdateSavedViews = async (newLabel) => {

        const updatedViewObj = createUpdatedViewObject(newLabel);
    
        
        try {
          const response = await updateSavedView({site: wellAPI.siteName, well: wellAPI.wellName, viewID: loadedEvent.view_id, updatedViewObject: updatedViewObj});
          message.success("Event updated successfully!");
          setUpdateViewModalVisible(false); // Close the modal
          // Update any state or UI here as needed
        } catch (error) {
          message.error("Failed to update event. Please try again later.");
        }
      };

    const handleAddNewSavedView = async (label) => {
        
        let newEvent = createNewSavedViewObject(label);
        try {
            const response = await addNewSavedView( {site: wellAPI.siteName , well: wellAPI.wellName, newViewObject: newEvent});
            if (response && response.data && response.data.viewID) {
                const updatedEvent = { ...newEvent, view_id: response.data.viewID, version: new Date(response.data.version).toLocaleString() };
                setSavedViews((prevViews) => [...(prevViews || []), updatedEvent]);
                message.success("New view saved successfully!");
            }
            
        } catch (error) {
            message.error("Failed to save new view. Please try again later.");
        }
        setSaveModalVisible(false); // Close the "Save" modal
    };


    const showList = () => {
        setListModalVisible(true);
    };

    const handleModalClose = () => {
        setListModalVisible(false);
    };

    //components for saving TFO ranges

    const TFOSaveModal = ({ isVisible, onSave, onClose }) => {
        const [eventName, setEventName] = useState('');
    
        const handleSave = () => {
            onSave(eventName.trim()); // Use `trim` to remove leading/trailing spaces before saving
            setEventName('');
        };
    
        const isSaveDisabled = eventName.trim().length === 0;
    
        return (
            <Modal
                title="Save Time Range"
                visible={isVisible}
                onOk={handleSave}
                onCancel={onClose}
                okButtonProps={{ disabled: isSaveDisabled }}
            >
                <p>Give this event (current view) a name:</p>
                <Input
                    value={eventName}
                    onChange={e => {
                        if (e.target.value.length <= 40) {
                            setEventName(e.target.value);
                        }
                    }}
                    maxLength={40}  // set the max length to 40 chars
                />
                <div style={{ textAlign: 'right', marginTop: '8px' }}>
                    {eventName.length}/40
                </div>
            </Modal>
        );
    };
    

    const UpdateViewModal = ({ isVisible, onOk, onCancel, initialLabel }) => {
        const [label, setLabel] = useState(initialLabel);
      
        useEffect(() => {
          setLabel(initialLabel); // Update label when initialLabel changes
        }, [initialLabel]);
      
        return (
          <Modal
            title="Update View Label"
            visible={isVisible}
            onOk={() => onOk(label)}
            onCancel={onCancel}
          >
            <Input value={label} onChange={(e) => setLabel(e.target.value)} />
          </Modal>
        );
      };
      
    const handleDeleteEvent = (eventToDelete) => {
        setSavedViews(prevEvents => prevEvents.filter(event => event.view_id !== eventToDelete.view_id));
        setLoadedEvent(null);
        setDepthLim(depthExtents);
        setXLines([]);
        setListModalVisible(false);
    };
    
    const TFOListModal = ({ isVisible, onClose, onDelete }) => {
        
        //setting all info for the event to be loaded
        const handleLoadClick = (event) => {
            setLoadedTimeRanges([event.timeselector.tfoTimeRange.startTimeRange, event.timeselector.tfoTimeRange.endTimeRange]);
            setQty(event.traces.length);
            setDisplayQty(event.traces.length);
            setLoadedEvent(event);
            setListModalVisible(false);
            // setTraces(event.traces);
            setXLines(event.xlines);
            setSelectedTimeRange([new Date(event.timeselector.tfoBrushTimeRange.startTimeRange),new Date(event.timeselector.tfoBrushTimeRange.endTimeRange)]);
            setdepthSelectorTempLims([event.depthselector.depthSelectorTempLim.minTempLim, event.depthselector.depthSelectorTempLim.maxTempLim]);
            setTimeout(() => {
                setDepthLim([event.depthselector.depthLim.minDepthLim, event.depthselector.depthLim.maxDepthLim]);
                setTempLim([event.depthselector.tempLim.minTempLim, event.depthselector.tempLim.maxTempLim]);
            }, 1600);
        
        };

        const handleDeleteSavedViews = async (viewID) => {
            try {
                const response = await deleteSavedView( {site: wellAPI.siteName , well: wellAPI.wellName, viewID: viewID});
                return response;
            } catch (error) {
                message.error("Failed to delete event. Please try again later.");
                // Handle error (e.g., showing error message to the user)
            }
          };

        const handleDeleteClick = (eventToDelete) => {
            //Modal for deleting a saved event
            Modal.confirm({
                title: 'Are you sure you want to delete this event?',
                content: `This will delete the event named: ${eventToDelete.label}`,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    // Call the onDelete prop passed to TFOListModal to actually delete the event
                    const viewDeleted = handleDeleteSavedViews(eventToDelete.view_id);
                    if (viewDeleted) {
                        onDelete(eventToDelete);
                        message.success("Event deleted successfully!");
                    }
                },
            });
        };

        return (
            <Modal
                title="Saved TFO Events"
                visible={isVisible}
                onCancel={onClose}
                width='70vw'
                footer={[
                    <Button key="close" onClick={onClose}>
                        Close
                    </Button>,
                ]}
            >
                <List
                    dataSource={savedViews}
                    renderItem={(event) => (
                        <List.Item style={{ alignItems: 'center' }}>
                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={() => handleLoadClick(event)}>Load</Button>
                                    <Button onClick={() => handleDeleteClick(event)} type="text" danger icon={<CloseOutlined />} />
                                </div>
                                <div style={{ fontSize: '15px', flex: 2 }}>
                                    <strong>Name:</strong> {event.label}
                                </div>
                                <div style={{ flex: 2 }}>
                                    <strong>Edited on:</strong> {event.version.toLocaleString()}
                                    <br />
                                    <strong>By:</strong> {event.email}
                                </div>
                                <div style={{ flex: 2 }}>
                                    <strong>Range:</strong> {new Date(event.timeselector.tfoTimeRange.startTimeRange).toLocaleString()} - {new Date(event.timeselector.tfoTimeRange.endTimeRange).toLocaleString()}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <strong>Number of Traces:</strong> {event.traces.length}
                                </div>
                            </Space>
                        </List.Item>
                    )}
                />
            </Modal>
        )
    }
    //=========================================================================

    return <div style={styles.container}>
          <div style={styles.leftColumn}>
             <SelectedList 
                 selected={traces} 
                 updateTrace={a => setTraces([...a])}
             />
             <ChartSummary 
                data={cursorData}
                xData={xLines}
                updateXData={a => setXLines([...a])} 
              />
          </div>
          <div style={styles.middleColumnTop}>
                {/* 
                //changed this so the TimeSelector on other tabs is not affected by the TFO TimeSelector changing.
                <TimeSelector 
                    margins={margins}
                    current={timeMinMaxData}
                    yLim={tempExtents}
                    onRangeUpdate={onTimeRangeUpdate}
                    onBrush={onTimeBrush}
                    showDatePicker={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    // selected={TFOSelectedTrace} 
                    setSelectedTraceTFO={setTFOSelectedTrace}
                    // onTimeDrag={timeDrag} 
                    selectedTimeRange={selectedTimeRange}
                    loadedTimeRanges={loadedTimeRanges}
                    view = "TFO"
                >
                    Number of Traces: 
                    <InputNumber min={1} max={30} value={displayQty} onChange={onSliderChange} />
                    <Slider style={{width: 300}} min={1} max={30} value={displayQty} onChange={onSliderChange} />
                </TimeSelector> */}
                <TimeSelectorTFO 
                    margins={margins}
                    current={timeMinMaxData}
                    yLim={tempExtents}
                    onRangeUpdate={onTimeRangeUpdate}
                    onBrush={onTimeBrush}
                    showDatePicker={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    selectedTimeRange={selectedTimeRange}
                    loadedTimeRanges={loadedTimeRanges}
                    view = "TFO"
                >
                    Number of Traces: 
                    <InputNumber min={1} max={30} value={displayQty} onChange={onSliderChange} />
                    <Slider style={{width: 300}} min={1} max={30} value={displayQty} onChange={onSliderChange} />
                </TimeSelectorTFO>
          </div>
          <div style={styles.chart}>
            <DepthTempChart 
                margins={margins}
                title={traces?.length ? adjustToBrowserTime(traces[traces.length - 1].key) : "No Data"}
                traces={traces}
                xLim={depthLim}
                yLim={tempLim}
                setCursorData={setCursorData}
                xLines={xLines}
                setXLines={setXLines}
                handleWidthUpdate={handleWidthUpdate}
            />
          </div>
            <div style={{...styles.topRight, marginTop: '40px'}}>
                <CSVDownload
                    type = "tfo"
                    viewer = "TFO"
                    timestamp={traces[traces?.length-1]?.key}
                    blob = { csvDataLoaded? transformData(csvTraces) : traces }
                    csvDataLoaded = {csvDataLoaded}
                />

                    <span style={{ fontWeight: 'bold' }}>TFO Views:</span>
                <div>
                <Button onClick={() => setSaveModalVisible(true)}>Save</Button>
                <TFOSaveModal 
                    isVisible={saveModalVisible} 
                    onSave={handleAddNewSavedView}
                    onClose={() => setSaveModalVisible(false)}
                />
                <Button onClick={showList}>List</Button>
                <TFOListModal
                    savedEvents={savedViews}
                    isVisible={listModalVisible}
                    onClose={handleModalClose}
                    onDelete={handleDeleteEvent}
                />
                
                {loadedEvent && (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Loaded View:</span> {loadedEvent.label}
                        {loadedEvent.email === user.email ? (
                            <div>
                                <Button onClick={handleUpdateButtonClick}>Update View</Button>
                            </div>
                            ) : (
                            <Tooltip title="You cannot edit this event because you are not the owner.">
                                <div>
                                    <Button disabled style={{ pointerEvents: 'none', color: 'grey', borderColor: 'grey' }}>Update View</Button>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                )}

                    <UpdateViewModal
                        isVisible={updateViewModalVisible}
                        initialLabel={loadedEvent ? loadedEvent.label : ""}
                        onOk={(newLabel) => handleUpdateSavedViews(newLabel)}
                        onCancel={() => setUpdateViewModalVisible(false)}
                    />
                </div>
            </div>
          <div style={styles.depthTrack}>
            <div style={styles.schemaBarContainer}>
                <SchemaBarContainer //holds however many schema bars are stored in DB
                    schemaSections={schemaSections} 
                    totalHeight={totalHeight}
                    margins={margins}
                    schemaBarWidth={schemaBarWidth}
                    onSectionClick={onSectionClick}
                />
            </div>
            <DepthSelector
                key={depthSelectorTempLims}
                margins={margins}
                current={traces}
                onBrush={onDepthBrush}
                depthExtents={depthExtents}
                xLim={depthLim}
                yLim={depthSelectorTempLims.length === 0 ? tempLim : depthSelectorTempLims}
                zoneData={selectedZone}
            />
          </div>
            <div style={styles.tempTrack}>
                { TempSelector }
                <span style={{ fontWeight: 'bold' }}>Well Layers: <EditOutlined onClick={onEditClick} style={{ fontSize: '18px' }}/></span>
                    <Layers schemaSections={schemaSections} toggleLayer={toggleLayer} onToggleLines={handleToggleLines}/>
            </div>
        </div>
}

export default TFOView;
