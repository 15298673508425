import { useState, useEffect, useContext } from "react";
import { WellContext } from "../../api/well";

import { Alert, Spin } from "antd";

import { TimeSelector } from "../Charts/Selectors";
import { ChartWithOverlay } from "../Charts/ChartWithOverlay";

import dayjs from "dayjs";
// import StackedChart from '../Charting/charts/StackedChart';

/*
  "points": [
    {
      "value": 165.42,
      "display": "165 °C",
      "colour": "#005F73",
      "label": "2022-05-09T15:46:40-06:00",
      "key": 0,
      "id": 0
    }
*/

const yFormat = (val, meta) => {
    console.log(val, meta);
    return meta
        ? `TC: ${val.toFixed(1)}, FO: ${meta.FOTemp.toFixed(1)}, Diff: ${(
              meta.FOTemp - meta.TCTemp
          ).toFixed(1)}`
        : `${val.toFixed(1)}`;
};

const xFormat = (val, meta) => {
    return val.toISOString().slice(0, 19);
};

export const TCOverview = ({}) => {
    const wellApi = useContext(WellContext);
    const { fetchTCData, fetchInfo, queryTimestamps } = wellApi;

    const [yLim, setYLim] = useState([0, 300]);

    const [tcData, setTCData] = useState([]);
    const [timestamps, setTimestamps] = useState([]);

    const [tcs, setTcs] = useState([]);

    const [range, setRange] = useState([dayjs().add("-1", "week"), dayjs()]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (fetchInfo.data) {
            const { tcs } = fetchInfo.data;
            const displayedTcs = tcs.filter((tc) => tc.displayed);
            setTcs(displayedTcs);
        }
    }, [fetchInfo.data]);

    useEffect(() => {
        if (!queryTimestamps.data) return;
        fetchTCData.sendRequest({
            start: queryTimestamps.data[0],
            end: queryTimestamps.data[queryTimestamps.data.length - 1],
        });
        return fetchTCData.cancel;
    }, [queryTimestamps.data]);

    useEffect(() => {
        if (fetchTCData.data) {
            const { timestamps, data } = fetchTCData.data;

            var min, max;

            const filteredData = data.reduce((arr, tc, i) => {
                const tcMeta = fetchInfo.data.tcs[i];

                /*
                        key: t.key, 
                        xVal: t.xData[i],
                        yVal: t.yData[i],
                        x: xFormat(t.xData[i]),
                        y: yFormat(t.yData[i]),
                        visible: t.visible,
                        colour: t.colour
                */

                if (tcMeta.displayed) {
                    arr.push([
                        {
                            colour: "red",
                            key: "FO_trace_" + tcMeta.name,
                            xData: timestamps,
                            yData: tc.map((p) => p.FOTemp),
                            label: `FO ${tcMeta.name} @ ${tcMeta.loc} mKB`,
                            visible: true,
                        },
                        {
                            colour: "blue",
                            key: "TC_trace_" + tcMeta.name,
                            xData: timestamps,
                            yData: tc.map((p) => p.TCTemp),
                            label: `TC ${tcMeta.name} @ ${tcMeta.loc} mKB`,
                            visible: true,
                        },
                    ]);

                    let tcMin = Math.min(...tc.map((p) => p.TCTemp));
                    let foMin = Math.min(...tc.map((p) => p.FOTemp));

                    if ((tcMin > 0 && tcMin < min) || min == undefined)
                        min = tcMin;
                    if ((foMin > 0 && foMin < min) || min == undefined)
                        min = foMin;
                    if (
                        Math.max(...tc.map((p) => p.FOTemp)) > max ||
                        max == undefined
                    )
                        max = Math.max(...tc.map((p) => p.FOTemp));
                    if (Math.max(...tc.map((p) => p.TCTemp)) > max)
                        max = Math.max(...tc.map((p) => p.TCTemp));
                }

                return arr;
            }, []);

            setTCData(filteredData);
            setTimestamps(timestamps);
            setYLim([Math.floor(min) - 1, Math.ceil(max) + 1]);
            setIsLoading(false);
        }
    }, [fetchTCData.data, fetchInfo.data]);

    const autoSizeY = (tcDataObj) => {
        if (!tcDataObj) return;
        if (tcDataObj?.length == 0) return;
        let yIndexArr = [];
        for (let i = 0; i < tcDataObj.length; i++) {
            if (tcDataObj[i].yData.every((t) => t < -20)) continue;
            yIndexArr = [...yIndexArr, ...tcDataObj[i].yData];
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);

        if (minValue < -20) minValue = -20;
        if (maxValue > 350) maxValue = 350;
        // console.log(`Minimum value: ${minValue}`);
        // console.log(`Maximum value: ${maxValue}`);

        return [minValue, maxValue];
    };

    return (
        <>
            {fetchTCData.error ? (
                <Alert type="error" message={fetchTCData.error} />
            ) : null}
            <div style={{ display: "flex", justifyContent: "center" }}>
                {fetchTCData.loading ? null : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            backgroundColor: "#f2f2f2",
                            marginRight: "auto",
                            padding: "0 8px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "2px",
                            }}
                        >
                            <div
                                style={{
                                    width: "20px",
                                    height: "2px",
                                    backgroundColor: "blue",
                                    marginRight: "5px",
                                }}
                            />
                            <p style={{ margin: 0 }}>Thermocouple</p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                            }}
                        >
                            <div
                                style={{
                                    width: "20px",
                                    height: "2px",
                                    backgroundColor: "red",
                                    marginRight: "5px",
                                }}
                            />
                            <p style={{ margin: 0 }}>Fiber</p>
                        </div>
                    </div>
                )}
                <TimeSelector
                    range={range}
                    setRange={setRange}
                    showDatePicker={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <div style={{ marginLeft: "auto", opacity: 0, width: "120px" }}>
                    Spacer
                </div>
            </div>
            {fetchTCData.loading ? (
                <Spin />
            ) : (
                tcs.map((tc, i) => {
                    if (tc.loc <= 0) return;
                    return (
                        <div key={`tcchart_${i}`} style={{ height: 400 }}>
                            <ChartWithOverlay
                                title={`${tc.name} - ${tc.loc}mKB`}
                                data={tcData}
                                timestamps={timestamps}
                                yLabel="Temperature (°C)"
                                xLabel="Time"
                                traces={tcData[i]}
                                xLim={[
                                    timestamps[0],
                                    timestamps[timestamps.length - 1],
                                ]}
                                yLim={autoSizeY(tcData[i])}
                                xScaleType="time"
                                yFormatter={(t) => `${t} °C`}
                                useCrosshairs={true}
                            />
                        </div>
                    );
                })
            )}
        </>
    );
};

/*
    title,
    margins=defaultMargins,
    xScaleType="linear",
    xLim,
    yLim,
    traces,
    xLines,
    setXLines,
    setCursorData,
    tcs,
    xUnit,
    yUnit,
    xLabel,
    onDoubleClick,
    xFormatter,
    yFormatter,
*/
