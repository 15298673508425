import { useEffect, useState, useMemo, useContext, useLayoutEffect } from "react";

import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";

import * as d3 from "d3";

import { YBrush } from "./Brush"
import { Selector } from "./Selector"

import { XAxis } from "../Axes/XAxis";
import { YAxis } from "../Axes/YAxis";

const defaultMargins = {
    top: 10,
    right: 10,
    bottom: 10,
    left: 45 
}

const yFormat = (d) => d3.format(".2f")(d);

export const TempSelector = ({
    margins=defaultMargins,
    onBrush,
    traces,
    chartType,
    unit=" °C"
}) => {
    //yLim is the extent of the brush, tempLim is the extent of the data
    const [ tempLim, setTempLim ] = useState();
    const [ yLim, setYLim ] = useState();

    let binSize = tempLim ? (tempLim[1] - tempLim[0])/100 : 1;

    if (binSize < 1) binSize = 1;

    // I only have this in here because I wasn't sure if it was causing redraws
    const tempMargins = useMemo(() => ({...margins, right: 5}), [margins]);

    // Add some limits if there aren't any alrady
    useEffect(() => {
        if (traces?.length && !tempLim) {
            const extent = d3.extent(traces.map(t => t.yData).flat());
            setTempLim([Math.floor(extent[0]), Math.ceil(extent[1]) + binSize])
            setYLim([Math.floor(extent[0]), Math.ceil(extent[1]) + binSize])
        }

        if (traces?.length) {
            const extent = d3.extent(traces.map(t => t.yData).flat());
            setTempLim(e => {{
                if (e[0] > extent[0] || e[1] <  extent[1]) return [extent[0], extent[1] + binSize];
                return e;
            }})
            setYLim([Math.floor(extent[0]), Math.ceil(extent[1]) + binSize])
        }


    // }, [traces, tempLim, yLim])
    }, [traces, tempLim])

    const bins = useMemo(() => {
        if (!traces?.length || !yLim) return [];

        let thresholds = [];

        for (let i = yLim[0]; i <= yLim[1]; i += binSize) {
            thresholds.push(i)
        }

        const hist = d3.bin()
            .value(d => Math.round(d))
            .domain(tempLim)
            .thresholds(thresholds);

        return traces.map(t => ({colour: t.colour, bins: hist(t.yData), key: t.key}))
    }, [traces, yLim]);

    const binExtent = useMemo(() => [0, Math.max(...bins.map(b => b.bins.map(b => b.length)).flat())], [bins])

    return  <Selector 
                margins={tempMargins}
                xLim={binExtent} 
                yLim={tempLim}
                yFormat={yFormat}
                >
                {/* <XAxis /> */}
                <YAxis />
                <VerticalBinChart 
                    data={bins} chartType={chartType} binSize={binSize}/>
                { yLim  && <YBrush brushExtents={yLim} onBrush={onBrush} /> }
            </Selector>
}

const VerticalBinChart = ({data, chartType, binSize}) => {
    const {svg } = useContext(CanvasContext);
    const { margins, width, height, xScale, yScale} = useContext(ChartContext);

    useLayoutEffect(() => {
        if ( height == 0 | width == 0) return;

        d3.select(svg).selectAll(".chart").remove();

        const chart = d3.select(svg).append("g").attr("class", "chart");

        if (!data || !yScale) return;

        const colourScale = d3.scaleLinear().range(["blue", "red"]).domain(yScale.domain());

        const h = yScale(0) - yScale(binSize);

        data.forEach(t => {
            chart.append(chartType == "loss" ? null : "g")
                .attr("class", t.key)
                .selectAll("rect")
                .data(t.bins)
                .enter()
                .append("rect")
                .attr("x", 1)
                // .attr("transform", d => `translate(${margins.left}, ${yScale(d.x0) - height/data.length + 100})`) 
                .attr("transform", d => `translate(${margins.left}, ${yScale(d.x0) - h})`) 
                .attr("height", h)
                .attr("width", d => {
                    return xScale(d.length) - margins.left 
                })
                .style("fill", t.colour);
                // .style("fill", d => colourScale(d.x0));
        })
        
        chart.lower()
    }, [data, width, height, margins])
}